import styled from "styled-components";
import { ResponsiveStream } from "@nivo/stream";

export const Graph = styled(ResponsiveStream).attrs(({ theme }) => ({
  enableLabel: false,
  theme: {
    axis: {
      ticks: {
        text: {
          fontSize: 0,
        },
      },
    },
  },
  keys: ["finished", "active"],
  padding: 0.2,
  valueScale: { type: "symlog" },
  margin: { top: 40, bottom: 40, left: 30, right: 30 },
  axisTop: null,
  axisRight: null,
  axisLeft: null,
  axisBottom: {
    tickSize: 0,
    tickPadding: 20,
    tickRotation: 0,
    legendPosition: "middle",
    legendOffset: 0,
  },
  labelTextColor: theme.colors.text,
  enableGridY: false,
  enableGridX: true,
  isInteractive: false,
  animate: true,
  motionStiffness: 90,
  motionDamping: 15,
  offsetType: "none",
  dotSize: 8,
  dotBorderWidth: 2,
}))``;

export const GraphContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  max-height: 210px;
  overflow: hidden;
`;
