import styled from "styled-components";

interface ContainerProps {
  width?: string;
  height?: string;
  zIndex?: number;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: ${({ width }) => width ?? "700px"};
  width: 100%;
  height: 100%;
  max-height: ${({ height }) => height ?? "700px"};
  overflow: auto;
  z-index: ${({ zIndex }) => zIndex ?? 700};
  outline: 0;
`;

interface BackdropProps {
  zIndex?: number;
}

export const Backdrop = styled.div<BackdropProps>`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(23, 25, 28, 0.9);
  z-index: ${({ zIndex }) => (zIndex ? zIndex - 10 : 500)};
  overflow: auto;
`;

export const StyledModal = styled.div`
  z-index: 100;
  background: ${({ theme }) => theme.colors.cardBackground};
  position: relative;
  margin: auto;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  width: 50%;
  height: 50%;
`;
