import useFetch from "src/hooks/useFetch";
import usePost from "src/hooks/usePost";
import usePut from "src/hooks/usePut";
import {
  IAnswerPost,
  IFiltersRegisterRequest,
  IGenerateLink,
  IPost,
  IPut,
  IQuestion,
  IQuiz,
  IQuizItem,
  IResponse,
  IResponseParam,
  SessionContextType,
  TDefaultResponse,
} from "src/types";
import { EncodeQueryDataParams } from "src/utils/EncodeQueryDataParams";
import { ProfessionalInfo } from "src/utils/formValidation/schemas/professionalInfo";

interface IRequestQuiz {
  quiz: IQuiz;
}

interface IRequestReplyQuiz {
  answers: IAnswerPost[];
  professional: ProfessionalInfo;
  time: number;
  acceptedTerms: boolean;
  dataStorage: boolean;
}

interface IResponseQuestions {
  data: {
    questions: IQuestion[];
    quiz: IQuiz;
  };
}

interface IResponseQuizItem {
  data: {
    quizzes: IQuizItem[];
  };
}

interface IResponseFetchQuiz {
  data: IQuiz;
}

export const GenerateQuiz = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
): IPost<TDefaultResponse<IResponseParam<IRequestQuiz>>, IGenerateLink> => {
  const { isLoading, refetch, error, isError, isSuccess } = usePost<
    TDefaultResponse<IResponseParam<IRequestQuiz>>,
    IGenerateLink
  >({
    key: "generate-quiz",
    url: "/quiz",
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, isError, isSuccess };
};

export const FetchQuizByURL = (url: string): IResponse<IResponseQuestions> => {
  const { isLoading, refetch, error, data } = useFetch<IResponseQuestions>({
    key: "fetch-quiz",
    url: `/quiz/generate/${url}`,
  });

  return { isLoading, refetch, error, data };
};

export const FetchQuizById = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  id: number,
): IResponse<IResponseFetchQuiz> => {
  const { isLoading, refetch, error, data } = useFetch<IResponseFetchQuiz>({
    key: `fetch-quiz-by-id-${token}`,
    url: `/quiz/find/${id}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
    enabled: false,
  });

  return { isLoading, refetch, error, data };
};

export const FetchQuizzes = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  filters: IFiltersRegisterRequest,
): IResponse<IResponseQuizItem> => {
  const params = EncodeQueryDataParams(filters);

  const { isLoading, refetch, error, data } = useFetch<IResponseQuizItem>({
    key: `fetch-quizzes/${token}`,
    url: `/quiz/${params}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, data };
};

export const ReplyQuiz = (
  url: string,
): IPost<TDefaultResponse<IResponseParam<IRequestQuiz>>, IRequestReplyQuiz> => {
  const { isLoading, refetch, error, isError, isSuccess } = usePost<
    TDefaultResponse<IResponseParam<IRequestQuiz>>,
    IRequestReplyQuiz
  >({
    key: "post-quiz",
    url: `/quiz/reply/${url}`,
  });

  return { isLoading, refetch, error, isError, isSuccess };
};

export const ResendMailResult = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  quizId: number,
): IPut<TDefaultResponse<IQuiz>, number> => {
  const { isLoading, refetch, isError } = usePut<TDefaultResponse<IQuiz>, number>({
    key: "resend-result",
    url: `/quiz/resendResult/${quizId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, isError };
};

export const ResendMailLink = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  quizId: number,
): IPut<TDefaultResponse<IQuiz>, number> => {
  const { isLoading, refetch, isError } = usePut<TDefaultResponse<IQuiz>, number>({
    key: "resend-link",
    url: `/quiz/resendLink/${quizId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, isError };
};

export const FinalizeQuiz = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  quizId: number,
): IPut<TDefaultResponse<IQuiz>, number> => {
  const { isLoading, refetch, isError } = usePut<TDefaultResponse<IQuiz>, number>({
    key: "resend-result",
    url: `/quiz/finalize/${quizId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, isError };
};

export const DisableQuiz = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  quizId: number,
): IPut<TDefaultResponse<IQuiz>, number> => {
  const { isLoading, refetch, isError } = usePut<TDefaultResponse<IQuiz>, number>({
    key: "resend-result",
    url: `/quiz/disable/${quizId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, isError };
};
