import { BsList } from "react-icons/bs";
import { FaChartLine, FaClock } from "react-icons/fa";
import { Column, Container } from "src/styles";
import styled from "styled-components";

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleContainer = styled(Column)`
  margin-left: 16px;
  align-items: flex-start;
`;

export const Title = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: 30px;
`;

export const Subtitle = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const ContainerDashboard = styled(Container)`
  background-color: ${({ theme }) => theme.colors.mainBackground};
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 16px;
  overflow: hidden;
`;

export const Grid = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1.5fr 1.5fr;
  grid-template-areas: "graf1 indicators" "graf2 table" "graf3 table";
`;

const GridContainer = styled.div`
  min-width: 621px;
  max-width: 750px;
  margin: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export const ChartCompletedContainer = styled(GridContainer)`
  grid-area: graf1;
  grid-template-rows: 1fr 1fr;
`;

export const ChartActivesContainer = styled(GridContainer)`
  grid-area: graf2;
  grid-template-rows: 1fr 1fr;
`;

export const ChartAllContainer = styled(GridContainer)`
  grid-area: graf3;
  grid-template-rows: 1fr 1fr;
`;

export const IndicatorsContainer = styled(GridContainer)`
  grid-area: indicators;
  grid-template-rows: 1fr 1fr;
`;

export const TableContainer = styled(GridContainer)`
  grid-area: table;
  min-width: 530px;
`;

export const TitleCard = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.text};
`;

export const TitleContent = styled(Column)`
  align-items: flex-start;
`;

export const IndicatorContent = styled(FlexCenter)``;

export const IndicatorCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  width: 244.67px;
  height: 209.67px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 8px;
  margin: 16px 16px 0 0;
`;

interface IIndicatorTitle {
  color: string;
}

export const IndicatorCardTitle = styled.h3<IIndicatorTitle>`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ color }) => color};
`;

export const IndicatorCardValue = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  line-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.secondary};
`;

interface ICardIcon {
  backgroundColor: string;
}

export const CardIcon = styled.div<ICardIcon>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-right: 20px;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const ClockIcon = styled(FaClock)`
  color: ${({ theme }) => theme.colors.white};
`;

export const ListIcon = styled(BsList)`
  color: ${({ theme }) => theme.colors.white};
`;

export const ChartIcon = styled(FaChartLine)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Line = styled.div`
  width: 100;
  margin: 16px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.textLight};
`;
