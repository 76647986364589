import { FaRegCopy } from "react-icons/fa";
import { Button, Input } from "src/styles";
import styled from "styled-components";
import { GiConfirmed } from "react-icons/gi";

export const ContainerRegisterCreate = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  padding: 10px;
  overflow: auto;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;

export const ButtonDiv = styled.div`
  margin-right: 5px;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  height: 42px;
  padding: 16px;
`;

export const ActionContainer = styled(InfoRow)`
  justify-content: flex-start;
  padding: 0;
`;

interface IActionButton {
  isActive: boolean;
}

export const ActionButton = styled(Button)<IActionButton>`
  padding: 8px;
  border-radius: 8px;
  width: 190px;
  height: 31px;
  margin-left: 10px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.textLight : theme.colors.secondary};
`;

export const CloseButton = styled.button`
  order: 2;
  width: 9px;
  height: 14px;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  padding-left: 20px;
  padding-right: 20px;
  color: ${({ theme }) => theme.colors.textSecondary};
  border: none;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const FormContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-top: 5px;
  width: 100%;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
`;

export const DateTimeRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;
`;

export const LinkContent = styled.div`
  width: 75%;
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  border-radius: 5px;
  height: 40px;
`;

export const LinkText = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: 15px;
`;

export const CopyIcon = styled(FaRegCopy)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export const DateContent = styled(LinkContent)`
  width: 25%;
  margin-left: 20px;
`;

export const InputDate = styled(Input).attrs({
  type: "date",
})`
  padding: 10;
  margin: 0;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  color: ${({ theme }) => theme.colors.textSecondary};
  width: 275px;
  height: 21px;
`;

export const Subtitle = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckBoxLabel = styled(Subtitle)`
  margin-left: 10px;
`;

export const Line = styled.div`
  width: 100%;
  height: 0px;
  border: 1px solid #e7ddff;
  margin: 5px 0;
`;

export const CancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textSecondary};
  padding: 8px;
  border-radius: 8px;
`;

export const SaveButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 8px;
  border-radius: 8px;
`;

export const FactorTitle = styled.h1`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 14px;
`;

export const FactorLine = styled.div`
  background-color: #e7ddff;
  height: 8px;
  display: flex;
  align-items: center;
  width: 779px;
  padding: 0 4px;
  border-radius: 5px;
`;

interface IFactorValue {
  width: string;
}

export const FactorValue = styled.div<IFactorValue>`
  background-color: ${({ theme }) => theme.colors.primaryAlpha};
  height: 4px;
  width: ${({ width }) => width};
`;

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  border: 3px solid ${({ theme }) => theme.colors.mainBackground};
  border-radius: 10px;
`;

export const FactorTitleBold = styled(FactorTitle)`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 14px;
`;

export const FactorContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  margin-top: 15px;
  border: 3px solid ${({ theme }) => theme.colors.mainBackground};
  border-radius: 10px;
`;

export const FactorContent = styled.div`
  width: 30%;
  background-color: ${({ theme }) => theme.colors.textLight};
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin: 5px;
`;

export const FactorRow = styled(Row)`
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const QuizInfo = styled.div`
  display: flex;
  align-items: center;
  order: 1;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin: 0 10px;
  order: 2;
`;

export const FinishedIcon = styled(GiConfirmed)``;

export const TimeLabel = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const TimeTitle = styled.label`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
`;

export const TimeContainer = styled.div`
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 5px;
  width: 295px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const SpinnerTitle = styled(FactorTitleBold)`
  margin-bottom: 10px;
`;
