import React from "react";
import { ChartStream } from "src/types";
import { Graph, GraphContainer } from "./styles";

interface Props {
  data: ChartStream[];
  colors: string[];
}

const StreamGraph: React.FC<Props> = ({ data, colors }) => {
  return (
    <GraphContainer>
      {data.length > 0 && <Graph colors={colors} data={data} />}
    </GraphContainer>
  );
};

export default StreamGraph;
