import React from "react";
import { Backdrop, Container, StyledModal } from "./styles";
import ReactDOM from "react-dom";

interface Props {
  children: JSX.Element;
  onClose?: () => void;
  show: boolean;
  height?: string;
  width?: string;
  zIndex?: number;
}

const ModalCustom: React.FC<Props> = ({
  children,
  onClose,
  show,
  height,
  width,
  zIndex,
}) => {
  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const key = event.key || event.keyCode;
      if ((key === 27 || key === "Escape") && show) {
        onClose?.();
      }
    };

    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [onClose, show]);

  const modal = (
    <>
      <Backdrop onClick={onClose} zIndex={zIndex} />
      <Container
        aria-modal
        tabIndex={-1}
        role="dialog"
        width={width}
        height={height}
        zIndex={zIndex}
      >
        <StyledModal>{children}</StyledModal>
      </Container>
    </>
  );

  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default ModalCustom;
