import * as Yup from "yup";
import CpfValidator from "src/utils/CPFValidator";

const cpfValidator = new CpfValidator();

export type ProfessionalInfo = {
  name: string;
  email: string;
  cpf: string;
  educationSituation: string;
  educationLevel: string;
  gender: string;
  profession: string;
  birthday: string;
  acceptedTerms: boolean;
  dataStorage: boolean;
};

const professionalInfo = Yup.object().shape({
  name: Yup.string()
    .required("O campo é obrigatório")
    .min(3, "O nome precisa ter pelo menos 3 caracteres"),
  email: Yup.string().required("O campo é obrigatório").email("O e-mail é inválido"),
  cpf: Yup.string()
    .required("O campo é obrigatório")
    .test("is-cpf", "CPF inválido", (cpf) => {
      if (!cpf) return false;
      const newCPF = cpfValidator.format(cpf);

      return cpfValidator.isValid(newCPF);
    }),
  educationSituation: Yup.string().required("O campo é obrigatório"),
  educationLevel: Yup.string().required("O campo é obrigatório"),
  gender: Yup.string().required("O campo é obrigatório"),
  profession: Yup.string().required("O campo é obrigatório"),
  birthday: Yup.string()
    .required("O campo é obrigatório")
    .test("is-underage", "O profissional precisa ter 15 anos ou mais.", (birthday) => {
      if (!birthday) return false;

      const dt = new Date();

      const date = new Date(birthday);
      const now = new Date(dt.getFullYear() - 15, dt.getMonth(), dt.getDate());

      return now >= date;
    }),
  acceptedTerms: Yup.boolean().required(),
  dataStorage: Yup.boolean().required(),
});

export default professionalInfo;
