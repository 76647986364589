import styled from "styled-components";

export const Input = styled.input`
  border: none;
  border-radius: 8px;
  outline: none;
  margin: 10px 0;
  padding: 6px;
  border: 1px solid ${({ theme }) => theme.colors.cardBackground};
  background-color: ${({ theme }) => theme.colors.cardBackground};
  :focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  :hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  ::disabled {
    color: ${({ theme }) => theme.colors.disabled};
    border: 1px solid ${({ theme }) => theme.colors.disabled};
  }
`;

export const TextArea = styled.textarea`
  border: none;
  border-radius: 8px;
  outline: none;
  margin: 10px 0;
  padding: 6px;
  border: 1px solid ${({ theme }) => theme.colors.cardBackground};
  background-color: ${({ theme }) => theme.colors.cardBackground};
  :focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  :hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  ::disabled {
    color: ${({ theme }) => theme.colors.disabled};
    border: 1px solid ${({ theme }) => theme.colors.disabled};
  }
`

export const Container = styled.div`
  min-width: 100%;
  min-height: 100%;
  max-width: 100vw;
  max-height: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const ContainerFull = styled(Container)`
  min-width: 98vw;
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
  padding: 0;
`;

export const Button = styled.button`
  height: auto;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.small};
  border-radius: 20px;
  border: none;
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.text};
  :hover {
    background-color: ${({ theme }) => theme.colors.textLight};
  }
  :disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: default;
  }
`;

export const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
