import React from "react";
import {
  Title,
  ContainerDashboard,
  Grid,
  ChartCompletedContainer,
  IndicatorsContainer,
  ChartActivesContainer,
  TableContainer,
  ChartAllContainer,
  Subtitle,
  TitleCard,
  TitleContainer,
  IndicatorCard,
  IndicatorContent,
  IndicatorCardTitle,
  IndicatorCardValue,
  FlexCenter,
  ClockIcon,
  CardIcon,
  TitleContent,
  ListIcon,
  ChartIcon,
  Line,
} from "./styles";

import BarChart from "src/components/bar-chart";
import { useTheme } from "styled-components";
import StreamGraph from "src/components/stream-chart";
import ListQuizDashboard from "src/components/list-quiz";
import { GetDashboard } from "src/services/dashboard";
import { useSession } from "src/hooks/useSession";
import { IDashboard } from "src/types";
import Spinner from "src/components/spinner";

const Home: React.FC = () => {
  const { colors } = useTheme();
  const { session, refreshSession } = useSession();
  const [dashboard, setDashboard] = React.useState<IDashboard>({
    registersActive: 0,
    registersError: 0,
    registersFinished: 0,
    lastUpdateRegisters: [],
    chartActive: [],
    chartFinished: [],
    chartStream: [],
  });

  const { data, isLoading } = GetDashboard(session.token, refreshSession);

  React.useEffect(() => {
    const dash = data?.data?.data;

    if (dash) {
      setDashboard(dash);
    }
  }, [data?.data?.data]);

  return (
    <ContainerDashboard>
      <TitleContainer>
        <Title>Dashboard</Title>
        <Subtitle>
          Atualizado{" "}
          {new Date().toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </Subtitle>
      </TitleContainer>
      <Grid>
        <ChartCompletedContainer>
          <FlexCenter>
            <CardIcon backgroundColor={colors.blue}>
              <ClockIcon size={18} />
            </CardIcon>
            <TitleContent>
              <TitleCard>Registro concluídos</TitleCard>
              <Subtitle>Total de registro concluídos</Subtitle>
            </TitleContent>
          </FlexCenter>
          <BarChart colors={[colors.blue]} data={dashboard.chartFinished} />
          <Subtitle>Dados do últimos 30 dias</Subtitle>
        </ChartCompletedContainer>
        <ChartActivesContainer>
          <FlexCenter>
            <CardIcon backgroundColor={colors.textLight}>
              <ListIcon size={18} />
            </CardIcon>
            <TitleContent>
              <TitleCard>Registro ativos</TitleCard>
              <Subtitle>Total de registros ativos</Subtitle>
            </TitleContent>
          </FlexCenter>
          <BarChart data={dashboard.chartActive} colors={[colors.textLight]} />
          <Subtitle>Dados do últimos 30 dias</Subtitle>
        </ChartActivesContainer>
        <ChartAllContainer>
          <FlexCenter>
            <CardIcon backgroundColor={colors.text}>
              <ChartIcon size={18} />
            </CardIcon>
            <TitleContent>
              <TitleCard>Registros ativos / concluídos</TitleCard>
              <Subtitle>Relação entre registro ativos e concluídos</Subtitle>
            </TitleContent>
          </FlexCenter>
          <StreamGraph
            data={dashboard.chartStream}
            colors={[colors.primary, colors.textLight]}
          />
          <Subtitle>Dados do últimos 30 dias</Subtitle>
        </ChartAllContainer>
        <IndicatorsContainer>
          <TitleCard>Dados consolidados</TitleCard>
          <Subtitle>Valores referentes à operação mensal</Subtitle>
          <IndicatorContent>
            <IndicatorCard>
              <IndicatorCardTitle color={colors.textLight}>
                Registros ativos
              </IndicatorCardTitle>
              <IndicatorCardValue>
                {isLoading ? <Spinner size="40px" /> : <>{dashboard.registersActive}</>}
              </IndicatorCardValue>
            </IndicatorCard>
            <IndicatorCard>
              <IndicatorCardTitle color={colors.blueLight}>
                Registros concluídos
              </IndicatorCardTitle>
              <IndicatorCardValue>
                {isLoading ? <Spinner size="40px" /> : <>{dashboard.registersFinished}</>}
              </IndicatorCardValue>
            </IndicatorCard>
            <IndicatorCard style={{ marginRight: 0 }}>
              <IndicatorCardTitle color={colors.textError}>
                Registros com erro
              </IndicatorCardTitle>
              <IndicatorCardValue>
                {isLoading ? <Spinner size="40px" /> : <>{dashboard.registersError}</>}
              </IndicatorCardValue>
            </IndicatorCard>
          </IndicatorContent>
        </IndicatorsContainer>
        <TableContainer>
          <TitleCard>Últimos registros atualizados</TitleCard>
          <Subtitle>Lista dos registros com novas atualizações</Subtitle>
          <Line />
          <ListQuizDashboard data={dashboard.lastUpdateRegisters} isLoading={isLoading} />
        </TableContainer>
      </Grid>
    </ContainerDashboard>
  );
};

export default Home;
