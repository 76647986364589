import { uniqueId } from "lodash";
import React from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import {
  SelectContainer,
  SelectContent,
  SelectError,
  SelectIcon,
  SelectLabel,
  SelectOption,
  SelectValue,
} from "./styles";

export interface ISelectOption {
  readonly value?: string;
  readonly label?: string;
  readonly component?: JSX.Element;
}

interface IProps {
  width?: string;
  options: ISelectOption[];
  label?: string;
  onChange?: (value?: string) => void;
  error?: boolean;
  helperText?: string;
  defaultValue?: string;
  multiple?: boolean;
  icon?: JSX.Element;
  ignoreValue?: string;
}

const Select: React.FC<IProps> = ({
  options,
  width,
  label,
  onChange,
  error,
  helperText,
  defaultValue,
  multiple = false,
  icon = <AiOutlineArrowDown size={10} />,
  ignoreValue = "Todos",
}) => {
  const [value, setValue] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <SelectContainer width={width}>
      <SelectLabel>{label}</SelectLabel>
      <SelectContent>
        <SelectValue
          value={value}
          onChange={(event) => {
            if (event.target.value !== ignoreValue) {
              onChange?.(event.target.value);
            } else {
              onChange?.(undefined);
            }
          }}
          multiple={multiple}
          id="custom-select"
        >
          {options.map((option) => (
            <SelectOption value={option.value} key={uniqueId(option.value)}>
              {option.component ? <>{option.component}</> : <>{option.label}</>}
            </SelectOption>
          ))}
        </SelectValue>
        <SelectIcon htmlFor="custom-select">{icon}</SelectIcon>
      </SelectContent>
      {error && <SelectError>{helperText}</SelectError>}
    </SelectContainer>
  );
};

export default Select;
