import React from "react";
import {
  TextAreaContainer,
  TextAreaContent,
  TextAreaLabel,
  TextAreaValue,
  TextAreaError,
} from "./styles";

interface IProps {
  placeholder?: string;
  onChange?: (value: string) => void;
  width?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  value?: string;
  disabled?: boolean;
  rows?: number;
}

const TextArea: React.FC<IProps> = ({
  onChange: onChangeValue,
  placeholder,
  width,
  label,
  error,
  helperText,
  value,
  disabled = false,
  rows,
}) => {
  return (
    <TextAreaContainer width={width}>
        <>
            <TextAreaLabel>{label}</TextAreaLabel>
            <TextAreaContent>
                <TextAreaValue
                    placeholder={placeholder}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        onChangeValue?.(event.target.value);
                    }}
                    value={value}
                    disabled={disabled}
                    rows={rows}
                />
            </TextAreaContent>
        </>
      {error && <TextAreaError>{helperText}</TextAreaError>}
    </TextAreaContainer>
  );
};

export default TextArea;
