import * as Yup from "yup";

export type NewPassword = {
  password: string;
  confirmPassword: string;
};

const newPassword = Yup.object().shape({
  password: Yup.string()
    .required("O campo é obrigatório")
    .min(6, "A senha precisa ter no minimo 6 digitos"),
  confirmPassword: Yup.string().when("password", (value) => {
    if (value && value.length > 0) {
      return Yup.string()
        .required("O campo é obrigatório")
        .test("is-confirm", "As senhas não conferem", (confirmPassword) => {
          return value === confirmPassword;
        });
    }

    return Yup.string().nullable();
  }),
});

export default newPassword;
