import React from "react";
import Button from "src/components/button";
import {
  ConfirmationContainer,
  Title,
  ActionContainer,
  TitleContainer,
  Icon,
  Message,
} from "./styles";

interface Props {
  title: string;
  message: string;
  icon?: JSX.Element;
  onCancel: () => void;
  onConfirm: () => void;
  buttonCancelIcon?: JSX.Element;
  buttonConfirmIcon?: JSX.Element;
  bgConfirm?: string;
  bgConfirmHover?: string;
  bgCancel?: string;
  bgCancelHover?: string;
  buttonCancelLabel?: string;
  buttonConfirmLabel?: string;
  isLoading?: boolean;
}

const Confirmation: React.FC<Props> = ({
  title,
  message,
  icon,
  onCancel,
  onConfirm,
  buttonCancelIcon,
  buttonConfirmIcon,
  bgConfirm,
  bgConfirmHover,
  bgCancel,
  bgCancelHover,
  buttonCancelLabel = "Cancelar",
  buttonConfirmLabel = "Confirmar",
  isLoading = false,
}) => {
  return (
    <ConfirmationContainer>
      <TitleContainer>
        {icon && <Icon>{icon}</Icon>}
        <Title>{title}</Title>
      </TitleContainer>
      <Message>{message}</Message>
      <ActionContainer>
        <Button
          onClick={onCancel}
          label={buttonCancelLabel}
          icon={buttonCancelIcon}
          bgColor={bgCancel}
          bgHover={bgCancelHover}
          isLoading={isLoading}
        />
        <Button
          onClick={onConfirm}
          label={buttonConfirmLabel}
          icon={buttonConfirmIcon}
          bgColor={bgConfirm}
          bgHover={bgConfirmHover}
          isLoading={isLoading}
        />
      </ActionContainer>
    </ConfirmationContainer>
  );
};

export default Confirmation;
