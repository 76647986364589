type StorageKey = "session";

class LocalStorage {
  setItem = (key: StorageKey, value: unknown): void => {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
  };

  getItem = <T,>(key: StorageKey): T | null => {
    const jsonValue = localStorage.getItem(key);

    if (jsonValue) {
      return JSON.parse(jsonValue);
    }

    return null;
  };

  clear = (key: StorageKey): void => {
    localStorage.removeItem(key);
  };
}

export default new LocalStorage();
