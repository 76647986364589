import * as Yup from "yup";

import professionalInfo, { ProfessionalInfo } from "./schemas/professionalInfo";
import user, { User } from "./schemas/user";
import group, { Group } from "./schemas/group";
import userGroup, { UserGroup } from "./schemas/userGroup";
import quiz, { Quiz } from "./schemas/quiz";
import userEdit, { UserEdit } from "./schemas/userEdit";
import groupEdit, { GroupEdit } from "./schemas/groupEdit";
import score, { Score } from "./schemas/score";
import scoreEdit, { ScoreEdit } from "./schemas/scoreEdit";
import newPassword, { NewPassword } from "./schemas/newPassword";

const listSchemas = {
  professionalInfo,
  user,
  group,
  userGroup,
  quiz,
  userEdit,
  groupEdit,
  score,
  scoreEdit,
  newPassword,
};

export type FormName = keyof typeof listSchemas;

export type FormValues =
  | ProfessionalInfo
  | User
  | Group
  | UserGroup
  | Quiz
  | UserEdit
  | GroupEdit
  | Score
  | ScoreEdit
  | NewPassword;

const testSchema = async (
  schema: FormName,
  value: Partial<FormValues>,
): Promise<true | Yup.ValidationError[]> => {
  try {
    const formSchema = listSchemas[schema];

    await formSchema.validate(value, {
      abortEarly: false,
    });

    return true;
  } catch (err) {
    const error = err as Yup.ValidationError;
    return error.inner;
  }
};

export default testSchema;
