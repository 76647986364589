import { Column } from "src/styles";
import styled from "styled-components";

export const ListContent = styled(Column)`
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
  width: 100%;
  height: 49px;
  position: relative;
`;

export const InfoDateContent = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 6px;
  gap: 8px;
  border-radius: 50px;
  min-width: 140px;
  margin-left: 8px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;

export const InfoLabel = styled.label`
  line-height: 14px;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
`;

export const ClientContainer = styled(Column)`
  align-items: flex-start;
  padding: 0px 8px;
  gap: 4px;
  min-width: 395px;
  margin-left: 8px;
`;

export const ClientName = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: 15px;
`;

export const ClientMail = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.userCardBackground};
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
`;

export const LabelDate = styled(InfoLabel)`
  position: absolute;
  right: 8px;
`;
