import { AuthParams, IPost, TDefaultResponse, IResponseParam, TToken } from "src/types";

import usePost from "src/hooks/usePost";

interface Refresh {
  refresh_token: string;
}

export const FetchToken = (): IPost<
  TDefaultResponse<IResponseParam<TToken>>,
  AuthParams
> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePost<
    TDefaultResponse<IResponseParam<TToken>>,
    AuthParams
  >({
    key: "session",
    url: "/authenticate",
    retryCount: 0,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const RefreshToken = (
  refreshToken: string,
): IPost<TDefaultResponse<IResponseParam<TToken>>, Refresh> => {
  const { isLoading, isSuccess, error, refetch } = usePost<
    TDefaultResponse<IResponseParam<TToken>>,
    Refresh
  >({
    key: "sessionRefresh",
    url: "/refreshToken/",
    headers: { Authorization: refreshToken },
  });

  return { isLoading, isSuccess, error, refetch };
};
