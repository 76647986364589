export default class CpfValidator {
  isValid(cpf: string): boolean {
    let sum = 0;
    let over = 0;

    if (cpf.length > 11) return false;

    if (cpf.length < 11) return false;

    if (cpf === "00000000000") return false;

    for (let i = 1; i <= 9; i++) {
      const digit = parseInt(cpf.substring(i - 1, i), 10);
      if (Number.isNaN(digit)) {
        return false;
      }

      sum += digit * (11 - i);
    }

    over = (sum * 10) % 11;

    if (over === 10 || over === 11) {
      over = 0;
    }

    let idDigit = parseInt(cpf.substring(9, 10), 10);
    if (Number.isNaN(idDigit) || over !== idDigit) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      const digit = parseInt(cpf.substring(i - 1, i), 10);
      if (Number.isNaN(digit)) {
        return false;
      }

      sum += digit * (12 - i);
    }

    over = (sum * 10) % 11;

    if (over === 10 || over === 11) {
      over = 0;
    }

    idDigit = parseInt(cpf.substring(10, 11), 10);

    if (Number.isNaN(idDigit) || over !== idDigit) {
      return false;
    }

    return true;
  }

  format(cpf: string) {
    const format = cpf.replace(/[^A-Za-z0-9]/g, "");
    return format;
  }
}
