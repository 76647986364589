import { Navigate, Route, Routes, BrowserRouter, useLocation } from "react-router-dom";

import React from "react";
import { useSession } from "src/hooks/useSession";
import Login from "src/pages/login";
import Registers from "src/pages/registers";
import Users from "src/pages/userRegistration";
import Groups from "src/pages/groupRegistration";
import Scores from "src/pages/scoreConfiguration";
import Home from "src/pages/home";
import Main from "src/components/main";
import Quiz from "src/pages/quiz";
import { ContainerFull } from "src/styles";
import NotFound from "src/pages/notFound";

type Props = {
  children: JSX.Element;
};

function PrivateRoute({ children }: Props): JSX.Element {
  const { isLogged } = useSession();
  const location = useLocation();

  return isLogged ? (
    children
  ) : (
    <Navigate to="/login" state={{ pathname: location.pathname }} />
  );
}

function PublicRoute({ children }: Props): JSX.Element {
  const { isLogged } = useSession();
  const { state } = useLocation();

  const path =
    state.pathname && typeof state.pathname === "string" ? state.pathname : "/";

  return !isLogged ? children : <Navigate to={path} />;
}

function Root(): JSX.Element {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Main>
              <Home />
            </Main>
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <Main>
              <Users />
            </Main>
          </PrivateRoute>
        }
      />
      <Route
        path="/groups"
        element={
          <PrivateRoute>
            <Main>
              <Groups />
            </Main>
          </PrivateRoute>
        }
      />
      <Route
        path="/registers"
        element={
          <PrivateRoute>
            <Main>
              <Registers />
            </Main>
          </PrivateRoute>
        }
      />
      <Route
        path="/scoreconfiguration"
        element={
          <PrivateRoute>
            <Main>
              <Scores />
            </Main>
          </PrivateRoute>
        }
      />
      <Route
        path="/quiz/:quizUrl"
        loader={({ params }) => {
          params.quizUrl;
        }}
        action={({ params }) => {
          params.quizUrl;
        }}
        element={
          <ContainerFull>
            <Quiz />
          </ContainerFull>
        }
      />
      <Route
        path="*"
        element={
          <ContainerFull>
            <NotFound />
          </ContainerFull>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <ContainerFull>
              <Login />
            </ContainerFull>
          </PublicRoute>
        }
      />
    </Routes>
  );
}

function Router(): JSX.Element {
  return (
    <BrowserRouter basename="/">
      <Root />
    </BrowserRouter>
  );
}

export default Router;
