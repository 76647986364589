import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Lexend', sans-serif;
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  body {
    -webkit-font-smoothing: antialiased !important;
    background-color: ${(props) => props.theme.colors.mainBackground};
  }
  body html #root {
    height: 100%;
  }

  #root{
    min-width: 800px;
    margin: 0;
    @media (max-width: 670px) {
     min-width: auto;
    }
  }
`;

export default GlobalStyle;
