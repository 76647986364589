import React from "react";
import { useParams } from "react-router-dom";
import Modal from "src/components/modal";
import { FetchQuizByURL, ReplyQuiz } from "src/services/quiz";
import { IAnswerPost, IQuestion } from "src/types";
import {
  ContainerQuiz,
  Header,
  Logo,
  Content,
  InfoContainer,
  NillLogo,
  TitleInfo,
  ParagraphInfo,
  LabelInfo,
  Footer,
  Line,
  ProgressInfo,
  ProgressTitle,
  Progress,
  CurrentProgress,
  ProgressButton,
  EndContainer,
  ButtonArea,
  ProfileInfoFormContainer,
  PreviousButton,
  AlertContainer,
  AlertTitle,
  AlertRow,
  AlertMessage,
  ProgressContent,
  ProgressPercentage,
} from "./styles";
import useFormValidation from "src/utils/formValidation/useFormValidation";
import { ProfessionalInfo } from "src/utils/formValidation/schemas/professionalInfo";
import Questions from "src/components/questions-table";
import Confirmation from "src/components/confirmation";
import { FcInfo } from "react-icons/fc";
import ProfileInfoForm from "src/components/profile-info-form";
import useAppDimension from "src/hooks/useAppDimension";
import { useSnackbar } from "src/hooks/useSnackbar";
import { GiCancel, GiConfirmed } from "react-icons/gi";
import SuccessQuiz from "src/components/success-quiz";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import Button from "src/components/button";
import InvalidLink from "src/components/invalid-link";

type TFormType = "profileInfo" | "quiz" | "success";

const Quiz: React.FC = () => {
  const { isMobile, isTablet } = useAppDimension();
  const { openSnackBar } = useSnackbar();
  const params = useParams();
  const [mobile, setMobile] = React.useState(false);
  const [started, setStarted] = React.useState(false);
  const [questions, setQuestions] = React.useState<IQuestion[]>([]);
  const [answers, setAnswers] = React.useState<IAnswerPost[]>([]);
  const [formType, setFormType] = React.useState<TFormType>("profileInfo");
  const [professional, setProfessional] = React.useState<ProfessionalInfo>({
    name: "",
    email: "",
    cpf: "",
    birthday: "",
    gender: "",
    educationSituation: "",
    educationLevel: "",
    profession: "",
    acceptedTerms: false,
    dataStorage: false,
  });

  // States to control
  const [startDate, setStartDate] = React.useState<Date | null>();
  const [offset, setOffset] = React.useState(0);
  const [indexOf, setIndexOf] = React.useState(20);
  const [currentQuestions, setCurrentQuestions] = React.useState<IQuestion[]>(
    []
  );
  const [modal, setModal] = React.useState({
    show: false,
    message: "",
  });
  const [showModal, setShowModal] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [progress, setProgress] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [confirmSubmit, setConfirmSubmit] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState(
    Math.round(questions.length / indexOf)
  );
  const [count, setCount] = React.useState(90);

  const { validateError, handleErrorMessage } =
    useFormValidation<ProfessionalInfo>("professionalInfo");

  const {
    data,
    isLoading,
    error: errorGetQuestions,
    isFetching,
  } = FetchQuizByURL(params.quizUrl ?? "");
  const { refetch: replyQuiz } = ReplyQuiz(params.quizUrl ?? "");

  const calcProgress = React.useCallback(
    (ans: IAnswerPost[]) => {
      if (ans.length > 0) {
        const max = questions.length;
        const current = ans.length;

        const pgrs = (current / max) * 100;

        return pgrs;
      }
      return 0;
    },
    [questions.length]
  );

  const closeModal = () => {
    setModal({ message: "", show: false });
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("professional");
  };

  const onSubmit = React.useCallback(async () => {
    try {
      if (questions.length !== answers.length) {
        openSnackBar("Existem questões sem respostas", "error");
        return;
      }

      if (formType == "success") {
        openSnackBar(
          "O formulário já foi enviado e não pode ser submetido novamente!",
          "error"
        );
        return;
      }

      const date = new Date();
      const start = startDate ?? new Date();
      const diff = date.getTime() - start.getTime();
      const time = Math.round(((diff % 86400000) % 3600000) / 60000);
      setConfirmSubmit(false);
      setFormType("success");
      await replyQuiz({
        answers,
        professional,
        time,
        acceptedTerms: professional.acceptedTerms,
        dataStorage: professional.dataStorage,
      });
      clearLocalStorage();
    } catch (err) {
      openSnackBar("Erro ao enviar questionário", "error");
    }
  }, [
    answers,
    openSnackBar,
    professional,
    questions.length,
    replyQuiz,
    startDate,
  ]);

  const onChangeProfileInfo = (key: string, value: string | boolean) => {
    setProfessional((prev) => ({ ...prev, [key]: value }));
  };

  const startQuiz = () => {
    setStartDate(new Date());
    setFormType("quiz");
    setStarted(true);
    setShowModal(false);
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 400);
  };

  const confirmStartQuiz = async () => {
    localStorage.setItem("professional", JSON.stringify(professional));

    const result = await validateError(professional);

    if (result === true) {
      setShowModal(true);
    }
  };

  const next = React.useCallback(() => {
    const valid = currentQuestions.every((value) => {
      const idx = answers.findIndex((e) => e.questionId === value.id);

      return idx >= 0;
    });

    if (valid) {
      window.scrollTo({ top: 0 });
      if (page < totalPage - 1 || !isMobile) {
        setPage(page + 1);
        setOffset(offset + indexOf);
      }
    } else {
      const questionsIds: number[] = [];
      currentQuestions.forEach((value) => {
        const idx = answers.findIndex((e) => e.questionId === value.id);

        if (idx < 0) {
          questionsIds.push(value.displayOrder);
        }
      });

      openSnackBar(
        `Responda todas as questões antes de prosseguir \n questões sem resposta: ${questionsIds.map(
          (e) => e
        )}`,
        "error"
      );
    }
  }, [
    answers,
    currentQuestions,
    indexOf,
    isMobile,
    offset,
    openSnackBar,
    page,
    totalPage,
  ]);

  const previous = React.useCallback(() => {
    if (offset - indexOf >= 0) {
      setPage(page - 1);
      setOffset(offset - indexOf);
    }
  }, [indexOf, offset, page]);

  const loadAnswersFromLocalStorage = () => {
    const savedAnswers = localStorage.getItem("answers");
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    }
  };

  const saveAnswersToLocalStorage = (answers: IAnswerPost[]) => {
    localStorage.setItem("answers", JSON.stringify(answers));
  };

  const onChangeAnswer = React.useCallback(
    (questionId: number, answerId: number) => {
      const updatedAnswers = [...answers];
      const answerIndex = updatedAnswers.findIndex(
        (answer) => answer.questionId === questionId
      );

      if (answerIndex >= 0) {
        updatedAnswers[answerIndex] = { questionId, answerId };
      } else {
        updatedAnswers.push({ questionId, answerId });
      }

      setAnswers(updatedAnswers);
      saveAnswersToLocalStorage(updatedAnswers);

      const progressVal = calcProgress(updatedAnswers);
      setProgress(progressVal);

      // if (progressVal === 100) {
      //   setConfirmSubmit(true);
      // } else if (mobile) {
      //   next();
      // }
    },
    [answers, calcProgress, mobile, next]
  );

  const showRecoveredDataMessage = () => {
    setModal({
      message: "Foram recuperados dados da última sessão.",
      show: true,
    });
  };

  React.useEffect(() => {
    const storedProfessional = localStorage.getItem("professional");
    if (storedProfessional) {
      setProfessional(JSON.parse(storedProfessional));
      showRecoveredDataMessage();
    }
  }, []);

  React.useEffect(() => {
    loadAnswersFromLocalStorage();
  }, []);

  React.useEffect(() => {
    if (started) {
      setInterval(() => {
        setCount((prev) => prev - 1);
      }, 60000);
    }
  }, [started]);

  React.useEffect(() => {
    if (count === 30) {
      setModal({
        message: "Restam 30 minutos para finalizar o questionário.",
        show: true,
      });
    }

    if (count === 0) {
      window.location.reload();
    }
  }, [count]);

  React.useEffect(() => {
    if (!started) {
      setOffset(0);
      setPage(0);
      if (isMobile) {
        setTotalPage(Math.round(questions.length / 1));
        setIndexOf(1);
        setMobile(true);
        return;
      }

      if (isTablet) {
        setTotalPage(Math.round(questions.length / 10));
        setIndexOf(10);
        return;
      }

      setIndexOf(20);
      setTotalPage(Math.round(questions.length / 20));
    }
  }, [isMobile, isTablet, questions.length, started]);

  React.useEffect(() => {
    const newQuestions =
      data?.data?.data.questions.slice(offset, offset + indexOf) ?? [];
    setCurrentQuestions(newQuestions);
  }, [indexOf, offset, data]);

  React.useEffect(() => {
    if (!started) {
      if (!errorGetQuestions) {
        setQuestions(data?.data?.data.questions ?? []);
      } else {
        setError("Link inválido");
      }
    }
  }, [data, errorGetQuestions, started]);

  React.useEffect(() => {
    if (answers.length > 0) {
      const max = questions.length;
      const current = answers.length;

      const pgrs = (current / max) * 100;

      setProgress(pgrs);
    }
  }, [answers, questions.length]);

  React.useEffect(() => {
    if (data?.data?.data.quiz.sendLinkEmail) {
      setProfessional((prev) => ({
        ...prev,
        email: data.data.data.quiz.sendLinkEmail,
      }));
    }
  }, [data]);

  React.useEffect(() => {
    const storedProfessional = localStorage.getItem("professional");
    if (storedProfessional) {
      setProfessional(JSON.parse(storedProfessional));
    }
  }, []);

  React.useEffect(() => {
    loadAnswersFromLocalStorage();
  }, []);

  const ProgressComponent = React.useMemo(
    () => <CurrentProgress progress={`${progress}%`} />,
    [progress]
  );

  return (
    <ContainerQuiz>
      <Header>
        <Logo />
      </Header>
      <Content isMobile={isMobile}>
        {!isFetching && !error && data?.data?.data.quiz.status == "active" && (
          <>
            {formType === "profileInfo" && (
              <>
                <InfoContainer>
                  <NillLogo />
                  <TitleInfo>Avaliação Comportamental</TitleInfo>
                  <ParagraphInfo>
                    Esta escala apresenta uma série de afirmações que descrevem
                    as maneiras preferenciais de um indivíduo agir e pensar.
                  </ParagraphInfo>
                  <ParagraphInfo>
                    Assinale o seu grau de concordância ou discordância em
                    relação a cada uma das situações apresentadas, indicando
                    assim o seu modo de agir.
                  </ParagraphInfo>
                  <ParagraphInfo>
                    Não existem respostas certas ou erradas.
                  </ParagraphInfo>
                  <ParagraphInfo>
                    Você deve indicar o que você gosta, pensa ou sente, na
                    maioria das ocasiões.
                  </ParagraphInfo>
                  <LabelInfo>Não deixe respostas em branco.</LabelInfo>
                  <Line />
                </InfoContainer>
                <ProfileInfoFormContainer>
                  <ProfileInfoForm
                    onChangeProfileInfo={onChangeProfileInfo}
                    handleErrorMessage={handleErrorMessage}
                    professional={professional}
                  />
                </ProfileInfoFormContainer>
              </>
            )}
            {formType === "quiz" && (
              <>
                {!isLoading && (
                  <Questions
                    answers={answers}
                    onChangeAnswer={onChangeAnswer}
                    questions={currentQuestions}
                  />
                )}
              </>
            )}
            {formType === "success" && <SuccessQuiz />}
          </>
        )}
      </Content>

      <Footer>
        {(!error || error.length === 0) &&
          data?.data?.data.quiz.status == "active" && (
            <>
              {started ? (
                <>
                  {formType !== "success" ? (
                    <EndContainer>
                      <ButtonArea>
                        {page >= totalPage && !mobile && (
                          <ProgressButton
                            onClick={() => setConfirmSubmit(true)}
                          >
                            Finalizar
                          </ProgressButton>
                        )}
                        {page >= totalPage - 1 && mobile && (
                          <ProgressButton
                            onClick={() => setConfirmSubmit(true)}
                          >
                            Finalizar
                          </ProgressButton>
                        )}

                        {page <= totalPage - 1 && (
                          <ProgressButton onClick={next}>
                            Próxima
                          </ProgressButton>
                        )}
                      </ButtonArea>

                      <ProgressInfo>
                        <ProgressTitle>
                          <>Iniciado</>
                        </ProgressTitle>

                        <ProgressContent>
                          <ProgressPercentage>
                            {progress.toFixed(0)}%
                          </ProgressPercentage>
                          <Progress> {ProgressComponent}</Progress>
                        </ProgressContent>
                      </ProgressInfo>
                    </EndContainer>
                  ) : (
                    <div></div>
                  )}
                </>
              ) : (
                <ProgressButton
                  onClick={confirmStartQuiz}
                  disabled={
                    !professional.acceptedTerms || !professional.dataStorage
                  }
                >
                  Iniciar Formulário
                </ProgressButton>
              )}
            </>
          )}
      </Footer>

      {error ||
        (data?.data?.data.quiz.status != "active" && !isLoading && (
          <Content isMobile={isMobile}>
            <InvalidLink />
          </Content>
        ))}

      <Modal show={showModal} width="400px" height="211px">
        <Confirmation
          message="Após iniciar o questionário você terá 1h para finalizar"
          title="Iniciar questionário?"
          icon={<FcInfo size={40} />}
          onCancel={() => setShowModal(false)}
          onConfirm={startQuiz}
          buttonCancelIcon={<GiCancel size={10} />}
          buttonConfirmIcon={<GiConfirmed size={10} />}
        />
      </Modal>
      <Modal show={confirmSubmit} width="400px" height="211px">
        <Confirmation
          message="Confirmar envio do questionário?"
          title="Enviar questionário?"
          icon={<FcInfo size={40} />}
          onCancel={() => setConfirmSubmit(false)}
          onConfirm={onSubmit}
        />
      </Modal>
      <Modal
        onClose={closeModal}
        show={modal.show}
        width="300px"
        height="200px"
      >
        <AlertContainer>
          <AlertRow>
            <FaExclamationTriangle size={20} />
            <AlertTitle>Atenção</AlertTitle>
          </AlertRow>
          <AlertMessage>{modal.message}</AlertMessage>
          <Button
            label="OK"
            onClick={closeModal}
            icon={<FaCheck size={15} />}
          />
        </AlertContainer>
      </Modal>
    </ContainerQuiz>
  );
};

export default Quiz;
