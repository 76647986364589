import * as Yup from "yup";

export type User = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  isAdmin: boolean;
};

const user = Yup.object().shape({
  name: Yup.string().required("O campo é obrigatório"),
  email: Yup.string().required("O campo é obrigatório").email("O e-mail é inválido"),
  password: Yup.string()
    .required("O campo é obrigatório")
    .min(6, "A senha precisa ter no minimo 6 digitos"),
  confirmPassword: Yup.string().when("password", (value) => {
    if (value && value.length > 0) {
      return Yup.string()
        .required("O campo é obrigatório")
        .test("is-confirm", "As senhas não conferem", (confirmPassword) => {
          return value === confirmPassword;
        });
    }

    return Yup.string().nullable();
  }),
  isAdmin: Yup.boolean().nullable()
});

export default user;
