import * as Yup from "yup";

export type TypeReturn = "noReturn" | "professionalReturn" | "otherReturn";

export type Quiz = {
  professionalName: string;
  sendLinkEmail: string;
  sendResultEmail?: string;
  expirationDate: string;
  typeReturn: TypeReturn;
};

const quiz = Yup.object().shape({
  professionalName: Yup.string()
    .required("O nome do profissional é obrigatório")
    .min(3, "O nome precisar ter pelo menos 3 caracteres"),
  sendLinkEmail: Yup.string()
    .required("O email do profissional é obrigatório")
    .email("O e-mail é inválido"),
  expirationDate: Yup.string()
    .required("O campo é obrigatório")
    .test("is-underage", "Data de expiração é inválida", (birthday) => {
      if (!birthday) return false;

      const date = new Date(birthday);
      const now = new Date();

      return now <= date;
    }),
  sendResultEmail: Yup.string().when("typeReturn", (value: TypeReturn) => {
    if (value === "otherReturn") {
      return Yup.string().required("O campo é obrigatório").email("E-mail inválido");
    }
    return Yup.string().nullable();
  }),
});

export default quiz;
