import styled from "styled-components";
import logoMenu from "src/assets/img/logowhite-dashboard.png";
import { FaUser } from "react-icons/fa";
import { BsList } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";

export const SideContainer = styled.div`
  height: 100%;
  width: 260px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
`;

export const LogoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 48px 8px;
  width: 228px;
  height: 208px;
`;

export const Logo = styled.div`
  width: 160px;
  height: 105px;
  background: url(${logoMenu});
`;

interface IMenuFooter {
  activeMenu: boolean;
}

export const MenuFooter = styled.div<IMenuFooter>`
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 90%;
  background-color: ${({ theme, activeMenu }) =>
    activeMenu ? theme.colors.userCardBackground : undefined};
  transition-delay: 2ms;
  color: ${({ theme }) => theme.colors.textFooter};
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding: 10px;
  margin: 0 10px;
  border-radius: 10px;
`;

export const UserContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const CircleUser = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  background: #f6f7fa;
  border-radius: 100px;
  width: 38px;
  height: 38px;
  margin-right: 15px;
`;

export const UserIcon = styled(FaUser)`
  color: ${({ theme }) => theme.colors.textLight};
`;

export const UsernameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const UserLabel = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const EmailLabel = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.colors.textLight};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const IconLines = styled(BsList)`
  position: absolute;
  right: 15px;
  color: #ec9f00;
  cursor: pointer;
  :hover {
    background-color: rgba(183, 126, 7, 0.5);
  }
  border-radius: 3px;
`;

export const MenuButtons = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-bottom: 4px;
  cursor: pointer;
  &:hover {
    background-color: rgba(183, 126, 7, 0.5);
  }

  border-radius: 6px;
`;

export const UserLogoutIcon = styled(FiLogOut)`
  margin-left: 5px;
`;

export const Line = styled.div`
  margin-bottom: 10px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.textLight};
  width: 100%;
`;

export const MenuLabel = styled.label`
  cursor: pointer;
`;
