import React from "react";
import { Card, Content, Error, ItemsError, LinkDescription, LinkIcon } from "./styles";

const InvalidLink: React.FC = () => {
  return (
    <Card>
      <LinkIcon />
      <Content>
        <LinkDescription>
          Este link é inválido e não pode ser utilizado. <br /> Por favor verifique se:
          <ItemsError>
            <Error>O Link expirou</Error>
            <Error>O Link já foi utilizado</Error>
          </ItemsError>
        </LinkDescription>
      </Content>
    </Card>
  );
};

export default InvalidLink;
