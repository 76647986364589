import styled from "styled-components";
import { Button, Column, Container } from "src/styles";
import { BsList, BsFileEarmarkTextFill } from "react-icons/bs";

export const ContainerRegisters = styled(Container)`
  background-color: ${({ theme }) => theme.colors.mainBackground};
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 16px;
  overflow: auto;
  justify-content: flex-start;
`;

export const TitleContainer = styled(Column)`
  margin-left: 16px;
  align-items: flex-start;
`;

export const Title = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.large};
  line-height: 30px;
`;

export const FactorTitle = styled.h1`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 14px;
`;

export const FactorTitleBold = styled(FactorTitle)`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 14px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const SpinnerTitle = styled(FactorTitleBold)`
  margin-bottom: 10px;
`;

export const Subtitle = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ReportGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ReportGroupIcon = styled(BsFileEarmarkTextFill)`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  right: 10px;
  cursor: pointer
`;

export const CreateButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  margin-right: 2px;
  margin-left: 2px;
  padding: 8px 35px 8px 18px;
`;

export const CreateButtonContainer = styled(FlexSpaceBetween)`
  justify-content: center;
  width: auto;
  position: relative;
`;

export const CreateButtonIcon = styled(BsList)`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  right: 10px;
  cursor: pointer;
`;

export const TableContainer = styled(Column)`
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: flex-start;
  height: 100%;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: start;
  padding: 16px;
`;

export const FilterItem = styled(FiltersContainer)`
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;

export const FilterLabel = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const ListIcon = styled(BsList)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(23, 25, 28, 0.9);
  z-index: 500;
  overflow: auto;
`;
