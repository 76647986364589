import { Skeleton, Box } from "@mui/material";
import React from "react";
import { IGroup, StatusQuiz } from "src/types";
import Checkbox from "src/components/checkbox";
import {
  ActionsContainer,
  ArrowNext,
  ArrowPrevious,
  CheckboxContainer,
  ClientContainer,
  ClientName,
  DeleteButton,
  Label,
  LabelButton,
  PaginationAction,
  PaginationContainer,
  Row,
  RowCheck,
  StatusContainer,
  TableContainer,
  TableContent,
  TrashIcon,
} from "./styles";
import StatusQuizCard from "src/components/status-quiz-card";
import { useSession } from "src/hooks/useSession";
import { useSnackbar } from "src/hooks/useSnackbar";
import { DeleteGroup } from "src/services/groups";
import ModalCustom from "src/components/modal";
import Confirmation from "src/components/confirmation";
import { FaExclamationTriangle } from "react-icons/fa";

export interface IRowItem {
  id: number;
  name: string;
  status: StatusQuiz;
}

interface Props {
  rows: IGroup[];
  isLoading: boolean;
  perPage?: number;
  totalPages?: number;
  totalRows?: number;
  page?: number;
  nextPage?: () => void;
  previousPage?: () => void;
  selectedRows: number[];
  selectRow: (groupId: number, checked: boolean) => void;
  viewDetails: (quiz: IRowItem) => void;
}

const GroupTable: React.FC<Props> = ({
  isLoading,
  rows,
  perPage = 10,
  totalPages,
  page = 1,
  totalRows = rows.length,
  nextPage,
  previousPage,
  selectedRows,
  selectRow,
  viewDetails,
}) => {
  const { session, refreshSession } = useSession();
  const { openSnackBar } = useSnackbar();
  const [pagination, setPagination] = React.useState({
    totalRows,
    totalPages:
      totalPages ?? Math.abs(rows.length / perPage) < 1
        ? 1
        : Math.abs(rows.length / perPage),
    page,
    perPage,
  });
  const [groupId, setGroupId] = React.useState(0);
  const [data, setData] = React.useState<IGroup[]>([]);

  const { refetch: deleteGroup } = DeleteGroup(session.token, refreshSession, groupId);

  const next = () => {
    if (nextPage) {
      nextPage();
      return;
    }

    setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const previous = () => {
    if (previousPage) {
      previousPage();
      return;
    }

    setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
  };

  const onConfirmDelete = React.useCallback(async () => {
    try {
      await deleteGroup();
      openSnackBar("Grupo deletado com sucesso.", "success");
      setGroupId(0);
    } catch {
      openSnackBar("Erro ao deletar o grupo", "error");
    }
  }, [deleteGroup, openSnackBar]);

  const onDeleteGroup = React.useCallback(
    (group: IGroup) => {
      setGroupId(group.id);
    }, []
  );

  React.useEffect(() => {
    const total = totalPages ?? Math.ceil(rows.length / perPage);
    const totalR = totalRows ?? rows.length;

    setPagination((prev) => ({ ...prev, totalPages: total, totalRows: totalR }));
  }, [perPage, rows.length, totalPages, totalRows]);

  React.useEffect(() => {
    let offset = pagination.page - 1;

    if (offset !== 0) {
      offset *= pagination.perPage;
    }

    setData(rows.slice(pagination.page - 1, pagination.page - 1 + pagination.perPage));
  }, [pagination.page, pagination.perPage, rows]);

  return (
    <>
      <ModalCustom
        show={groupId > 0}
        onClose={() => setGroupId(0)}
        width="400px"
        height="200px"
      >
        <Confirmation
          onConfirm={onConfirmDelete}
          onCancel={() => setGroupId(0)}
          message="Deseja confirmar a exclusão?"
          title="Atenção"
          icon={<FaExclamationTriangle size={30} />}
        />
      </ModalCustom>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
        </Box>
      ) : (
        <TableContainer>
          <TableContent>
            {data.map((row) => (
              <Row key={row.name}>
                <RowCheck>
                  <CheckboxContainer>
                    <Checkbox
                      checked={selectedRows.includes(row.id)}
                      onChange={(checked) => {
                        selectRow(row.id, checked);
                      }}
                    />
                  </CheckboxContainer>
                </RowCheck>
                <Row onClick={() => viewDetails(row)}>
                  <ClientContainer>
                    <ClientName>{row.name}</ClientName>
                  </ClientContainer>
                  <StatusContainer>
                    <StatusQuizCard status={row.status} />
                  </StatusContainer>
                </Row>
                <ActionsContainer>
                  <DeleteButton
                    onClick={() => {
                      setGroupId(row.id);
                      onDeleteGroup(row);
                    }}
                  >
                    <TrashIcon />
                  </DeleteButton>
                </ActionsContainer>
              </Row>
            ))}
          </TableContent>
          <PaginationContainer>
            <Label>Total de páginas: {pagination.totalPages}</Label>
            <PaginationAction onClick={previous} disabled={pagination.page === 1}>
              <ArrowPrevious />
              <LabelButton>Anterior</LabelButton>
            </PaginationAction>
            <PaginationAction
              onClick={next}
              disabled={pagination.page === pagination.totalPages}
            >
              <LabelButton>Próximo</LabelButton>
              <ArrowNext />
            </PaginationAction>
            <Label>
              Total de registros: {data.length} de {pagination.totalRows}
            </Label>
          </PaginationContainer>
        </TableContainer>
      )}
    </>
  );
};

export default GroupTable;
