import styled from "styled-components";
import { Container, Button } from "src/styles";

import background from "src/assets/img/login-illustration.svg";
import nillLogo from "src/assets/img/nill_logo_white.svg";
import { FaKey, FaLock } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";

import { CiUser } from "react-icons/ci";

export const ContainerLogin = styled(Container)``;

export const InfoContent = styled.div`
  width: 55%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: 30px;
  @media (max-width: 640px) {
    width: 0%;
    display: none;
  }
`;

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.white};
`;

export const TitleInfo = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  color: ${({ theme }) => theme.colors.primary};
  margin: 5px 0;
  background-image: url(${nillLogo});
  width: 180px;
  height: 62px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const ImageInfo = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  height: 70%;
  width: 50%;
  position: absolute;
  bottom: 0;
  background-size: 100%;
`;

export const FormContainer = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 75px;
  gap: 30px;
  @media (max-width: 640px) {
    width: 100%;
  }
`;

export const FormContent = styled.form`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 420px;
`;

export const LogoContent = styled.img`
  width: 50%;
  height: auto;
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  color: ${({ theme }) => theme.colors.text};
  padding: 15px 0;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Subtitle = styled(Description)`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const Line = styled.div`
  margin: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  width: 100%;
`;

export const LoginButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-top: 6px;
`;

export const ForgotPassword = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  padding: 5px 0;
  cursor: pointer;
`;

export const UserIcon = styled(CiUser)``;

export const IconKey = styled(FaKey)`
  padding-left: 5px;
  color: ${({ theme }) => theme.colors.primary};
  size: 3px;
`;

export const LockIcon = styled(FaLock)``;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  padding: 5px;
`;

export const ForgotPasswordContainer = styled.div`
  width: auto;
  padding: 2px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.cardBackground};
  }
`;

export const CancelEmailContainer = styled(ForgotPasswordContainer)`
  color: rgb(132, 130, 143) !important;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.colors.textError} !important;
    background-color: rgba(220, 73, 58, 0.3);
  }
`;

export const CancelEmail = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  padding: 8px 0;
  cursor: pointer;
`;

export const IconCancel = styled(MdOutlineCancel)`
  size: 3px;
  padding-left: 3px;
  :hover {
    color: ${({ theme }) => theme.colors.textError};
  }
`;

export const IconMail = styled(HiOutlineMail)``;

export const ForgotModalContainer = styled.div`
  padding: 25px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  height: 220px;
  width: 350px;
  z-index: 100;
`;
