import useFetch from "src/hooks/useFetch";
import { IDashboard, IResponse, SessionContextType } from "src/types";

interface IResponseDashboard {
  data: IDashboard;
}

export const GetDashboard = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
): IResponse<IResponseDashboard> => {
  const { data, error, isLoading, isFetching, refetch } = useFetch<IResponseDashboard>({
    key: `get-dashboard-${token}`,
    url: "/dashboard",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onFailure: refreshSession,
  });

  return { data, error, isLoading, isFetching, refetch };
};
