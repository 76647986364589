import React from "react";
import { IQuizItem } from "src/types";
import { FormatBR } from "src/utils/DateFormat";
import StatusQuizCard from "../status-quiz-card";
import {
  ClientContainer,
  ClientMail,
  ClientName,
  InfoDateContent,
  InfoLabel,
  LabelDate,
  ListContent,
  Row,
} from "./styles";

interface Props {
  data: IQuizItem[];
  isLoading: boolean;
}

const ListQuizDashboard: React.FC<Props> = ({ data, isLoading }) => {
  return (
    <ListContent>
      {isLoading ? (
        <>Loading</>
      ) : (
        <>
          {data.map((quiz) => (
            <Row key={quiz.id}>
              <StatusQuizCard status={quiz.status} />
              {quiz.replyDate && (
                <LabelDate>{FormatBR(quiz.replyDate.toString(), true)}</LabelDate>
              )}
              <ClientContainer>
                <ClientName>{quiz.professionalName}</ClientName>
                <ClientMail>{quiz.professionalEmail}</ClientMail>
              </ClientContainer>
              <InfoDateContent>
                <InfoLabel>{quiz.id}</InfoLabel>
                <InfoLabel>{FormatBR(quiz.createdAt.toString(), true)}</InfoLabel>
              </InfoDateContent>
            </Row>
          ))}
        </>
      )}
    </ListContent>
  );
};

export default ListQuizDashboard;
