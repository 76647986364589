import { FaUser, FaListUl } from "react-icons/fa";
import { Button } from "src/styles";
import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Header = styled.div`
  height: 50px;
  padding: 10px;
  border-bottom: 1px solid #e7ddff;
  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  
`;

export const HeaderTitle = styled.h1`
  font-weight: 700;
  font-size: 16px;
  color: #000f08;
`;

export const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: center;
`;

export const UserIcon = styled(FaUser)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const ListIcon = styled(FaListUl)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  overflow: scroll;
  overflow-x: hidden;
  width: 100%;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* gray/400 */

  color: #84828f;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
`;

export const CancelButton = styled(Button)`
:hover {
  background-color: ${({ theme }) => theme.colors.textLight};
  color: white;
}
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textSecondary};
  padding: 11px;
  border-radius: 8px;
`;

export const SaveButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 11px;
  border-radius: 8px;
`;
