import styled from "styled-components";
import { Input as _Input } from "src/styles";
import InputMask from "the-mask-input";

interface IInputContainer {
  width?: string;
}

export const InputContainer = styled.div<IInputContainer>`
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  width: ${({ width }) => width ?? "auto"};
  margin: 10px 0;
  margin-right: 5px;
`;

export const IconContainer = styled.div`
  position: absolute;
  left: 15px;
`;

export const InputContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const InputValue = styled(_Input)`
  padding-left: 30px;
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.small};
  width: 100%;
  border-radius: 8px;
  outline: none;
  ::placeholder {
    color: ${({ theme }) => theme.colors.textLight};
    font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  }
  :focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  :hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.disabled};
    border: 1px solid ${({ theme }) => theme.colors.disabled};
  }
`;

export const InputLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  padding-left: 5px;
  padding: 2px;
`;

export const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const InputCheck = styled(InputValue)`
  width: 20px;
`;

export const InputError = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  color: ${({ theme }) => theme.colors.textError};
`;

export const InputMaskCustom = styled(InputMask)`
  padding: 6px;
  padding-left: 30px;
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.small};
  width: 100%;
  border-radius: 8px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.cardBackground};
  border: none;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  ::placeholder {
    color: ${({ theme }) => theme.colors.textLight};
    font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  }
  :focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  :hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.disabled};
    border: 1px solid ${({ theme }) => theme.colors.disabled};
  }
`;
