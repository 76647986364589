export const FormatBR = (date: string, includeHours = false) => {
  const options: Intl.DateTimeFormatOptions = includeHours
    ? {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }
    : {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };

  return new Date(date).toLocaleDateString("pt-BR", options);
};

export const GetAge = (date: string) => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age.toString();
};

export const MaxDateBirthday = (date: Date) => {
  const dt = date.toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const parts = dt.split("/");

  const format = `${parts[2]}-${parts[1]}-${parts[0]}`;

  return format;
};
