import React from "react";
import Sidebar from "../sidebar";
import { FlexStart, MainContainer, MainContent } from "./styles";

type Props = {
  children: JSX.Element;
};

const Main: React.FC<Props> = ({ children }) => (
  <MainContainer>
    <FlexStart>
      <Sidebar>
        <MainContent>{children}</MainContent>
      </Sidebar>
    </FlexStart>
  </MainContainer>
);

export default Main;
