import styled from "styled-components";

export const ConfirmationContainer = styled.div`
  padding: 25px;
  border-radius: 10px;
  background: #e0e0e0;
  box-shadow: 11px 11px 22px #aaaaaa, -11px -11px 22px #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  min-height: 200px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;

export const Icon = styled.div`
  padding-right: 10px;
`;

export const Message = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.text};
  margin-top: 25px;
  text-align: center;
  width: 100%;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 25px;
`;
