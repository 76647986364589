import * as Yup from "yup";

export type ScoreEdit = {
  id: number;
  factor: string;
  subfactor: string;
  value: number;

  veryHighScore: string;
  highScore: string;
  mediumScore: string;
  lowScore: string;
  veryLowScore: string;
};

const scoreEdit = Yup.object().shape({
  factor: Yup.string(),
  subfactor: Yup.string(),
  value: Yup.number(),
  highScore: Yup.string().required("O campo é obrigatório"),
  highScoreEng: Yup.string().required("O campo é obrigatório"),
  mediumScore: Yup.string().required("O campo é obrigatório"),
  mediumScoreEng: Yup.string().required("O campo é obrigatório"),
  lowScore: Yup.string().required("O campo é obrigatório"),
  lowScoreEng: Yup.string().required("O campo é obrigatório"),
});

export default scoreEdit;
