import styled from "styled-components";

export const FormContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: auto;
`;

export const FormTitle = styled.h1`
  font-weight: 700;
  font-size: 18px;
`;

export const FormDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const FormDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ErrorLabel = styled.label`
  padding: 0 5px;
  color: ${({ theme }) => theme.colors.textError};
`;

export const CheckboxDescription = styled.p`
  font-weight: 400;
  font-size: 11px;
  color: ${({ theme }) => theme.colors.textSecondary};
`;
