import styled from "styled-components";
import { BsList } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { IoMdAlert } from "react-icons/io";
import { FaClock } from "react-icons/fa";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiUnlink } from "react-icons/bi";

interface ICardStatus {
  backgroundColor: string;
}

export const CardStatus = styled.div<ICardStatus>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px;
  gap: 6px;
  width: 94px;
  height: 22px;
  background-color ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

interface ContentCardColor {
  color: string;
}

export const CardStatusTitle = styled.label<ContentCardColor>`
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  color: ${({ color }) => color};
`;

export const ListIcon = styled(BsList)<ContentCardColor>`
  color: ${({ color }) => color};
`;

export const ErrorIcon = styled(MdCancel)<ContentCardColor>`
  color: ${({ color }) => color};
`;

export const OkIcon = styled(AiOutlineCheckCircle)<ContentCardColor>`
  color: ${({ color }) => color};
`;

export const ExpiredIcon = styled(IoMdAlert)<ContentCardColor>`
  color: ${({ color }) => color};
`;

export const ClockIcon = styled(FaClock)<ContentCardColor>`
  color: ${({ color }) => color};
`;

export const DisableIcon = styled(BiUnlink)<ContentCardColor>`
  color: ${({ color }) => color};
`;
