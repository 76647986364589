import React from "react";
import {
  SideContainer,
  LogoContent,
  Logo,
  UserContainer,
  UserIcon,
  CircleUser,
  UsernameContainer,
  IconLines,
  UserLabel,
  EmailLabel,
  MenuFooter,
  UserLogoutIcon,
  MenuButtons,
  Line,
  MenuLabel,
} from "./styles";
import Menu from "../menu";
import { useSession } from "src/hooks/useSession";
import { Collapse } from "@mui/material";
import ModalCustom from "src/components/modal";
import UserForm from "src/components/user-form";

interface IProps {
  children: React.ReactNode;
}

function Sidebar({ children }: IProps): JSX.Element {
  const { logout, session } = useSession();
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const formatText = (value: string) => {
    if (value.length > 18) {
      return value.substring(0, 18).concat("...");
    }

    return value;
  };

  return (
    <>
      <SideContainer>
        <LogoContent>
          <Logo />
        </LogoContent>
        <Menu />
        <MenuFooter activeMenu={open}>
          <Collapse in={open} orientation="vertical">
            <>
              <MenuButtons onClick={logout}>
                <MenuLabel>Sair</MenuLabel> <UserLogoutIcon />
              </MenuButtons>
              <MenuButtons onClick={() => setOpenModal(true)}>
                <MenuLabel>Dados Cadastrais</MenuLabel>
              </MenuButtons>
            </>
          </Collapse>
          <Line />
          <UserContainer>
            <CircleUser>
              <UserIcon size={20} />
            </CircleUser>
            <UsernameContainer>
              <UserLabel title={session.user.name}>
                {formatText(session.user.name)}
              </UserLabel>
              <EmailLabel title={session.user.email}>
                {formatText(session.user.email)}
              </EmailLabel>
            </UsernameContainer>
            <IconLines
              onClick={() => {
                setOpen(!open);
              }}
              size={18}
            />
          </UserContainer>
        </MenuFooter>
      </SideContainer>
      {children}
      <ModalCustom
        show={openModal}
        onClose={() => setOpenModal(false)}
        width="800px"
        height="430px"
      >
        <UserForm
          onClose={() => setOpenModal(false)}
          userEdit={{
            email: session.user.email,
            name: session.user.name,
            id: session.user.id,
            isAdmin: session.user.isAdmin,
          }}
        />
      </ModalCustom>
    </>
  );
}

export default Sidebar;
