import React from "react";
import { useLocation } from "react-router-dom";
import {
  ItemMenu,
  ListMenu,
  ItemMenuContainer,
  ItemMenuLabel,
  IconHome,
  IconList,
  IconUsers,
  IconEdit,
  IconLink,
  MenuSeparator,
} from "./styles";

export type ItemsMenu = {
  to: string;
  icon: JSX.Element;
  label: string;
  external?: boolean;
  url?: string;
};

function Menu(): JSX.Element {
  const currentPath = useLocation();
  const menus: ItemsMenu[] = [
    {
      to: "/",
      icon: <IconHome size={16} />,
      label: "Dashboard",
    },
    {
      to: "/registers",
      icon: <IconList size={16} />,
      label: "Registros",
    },
    {
      to: "/users",
      icon: <IconUsers size={16} />,
      label: "Usuários",
    },
    {
      to: "/groups",
      icon: <IconUsers size={16} />,
      label: "Grupos",
    },
    {
      to: "/scoreconfiguration",
      icon: <IconEdit size={16} />,
      label: "Faixas de Escala",
    },
  ];

  const externalMenus: ItemsMenu[] = [
    {
      to: "#",
      icon: <IconLink size={16} />,
      label: "Niil IA",
      external: true,
      url: "https://www.niilia.com.br/",
    },
    {
      to: "#",
      icon: <IconLink size={16} />,
      label: "Niil Key",
      external: true,
      url: "https://www.niilkey.com.br/",
    },
    {
      to: "#",
      icon: <IconLink size={16} />,
      label: "Niil Recruiter",
      external: true,
      url: "https://www.niilrecruiter.com.br/",
    },
  ];

  const isCurrentPath = (path: string) => path === currentPath.pathname;

  return (
    <ListMenu>
      {menus.map((item) => (
        <ItemMenu key={item.to}>
          <ItemMenuContainer to={item.to} $isCurrent={isCurrentPath(item.to)}>
            {item.icon}
            <ItemMenuLabel>{item.label}</ItemMenuLabel>
          </ItemMenuContainer>
        </ItemMenu>
      ))}
      <MenuSeparator /> {/* Separador inserido aqui */}
      {externalMenus.map((item) => (
        <ItemMenu key={item.label}>
          <a
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "200px",
              padding: "10px 0",
              borderRadius: "8px",
              color: "white",
              backgroundColor: "inherit",
            }}
          >
            {item.icon}
            <ItemMenuLabel>{item.label}</ItemMenuLabel>
          </a>
        </ItemMenu>
      ))}
    </ListMenu>
  );
}

export default Menu;
