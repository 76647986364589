import styled from "styled-components";
import { Button, Column, Container, TextArea } from "src/styles";
import { BsList, BsFileEarmarkTextFill } from "react-icons/bs";
import { FaUsersCog } from "react-icons/fa";

export const FactorTitle = styled.h1`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 14px;
`;

export const FactorTitleBold = styled(FactorTitle)`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 14px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const SpinnerTitle = styled(FactorTitleBold)`
  margin-bottom: 10px;
`;

export const FormContent = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-top: 5px;
  width: 100%;
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin-top: 5px;
`

export const InputTextArea = styled(TextArea)`
  justify-content: start;
`;

export const ContainerScores = styled(Container)`
  background-color: ${({ theme }) => theme.colors.mainBackground};
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 16px;
  overflow: auto;
  justify-content: flex-start;
`;

export const SaveButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 8px;
  border-radius: 8px;
`;

export const TitleContainer = styled(Column)`
  margin-left: 16px;
  align-items: flex-start;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const Subtitle = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ReportScoreContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ReportScoreIcon = styled(BsFileEarmarkTextFill)`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  right: 10px;
  cursor: pointer
`;

export const CreateButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  margin-right: 2px;
  margin-left: 2px;
  margin-bottom: 5px;
  padding: 8px 35px 8px 18px;
`;

export const CreateButtonContainer = styled(FlexSpaceBetween)`
  justify-content: center;
  width: auto;
  position: relative;
`;

export const CreateButtonIcon = styled(BsList)`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  right: 10px;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

export const GroupButtonIcon = styled(FaUsersCog)`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  right: 10px;
  cursor: pointer;
`;

export const TableContainer = styled(Column)`
  width: 100%;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: flex-start;
  height: 100%;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: start;
  padding: 16px;
`;

export const FilterItem = styled(FiltersContainer)`
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;

export const FilterLabel = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const ListIcon = styled(BsList)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(23, 25, 28, 0.9);
  z-index: 500;
  overflow: auto;
`;
