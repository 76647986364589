import React from "react";

import validateForm, { ErrorInterface } from ".";
import { FormName, FormValues } from "./testSchema";

export type DefaultForm = { [key: string]: unknown };

const useFormValidation = <Form = DefaultForm>(formName: FormName) => {
  const [errorItems, setErrorItems] = React.useState<ErrorInterface>();

  const validateError = async (formParams: Form) => {
    const newParams = formParams as Partial<FormValues>;
    const errors = await validateForm(newParams, formName);

    if (errors) {
      setErrorItems(errors);
      return false;
    }

    return true;
  };

  const handleErrorMessage = React.useCallback(
    (item: keyof Form, helperText?: string) => {
      if (errorItems) {
        const error = errorItems.errors.find((err) => err.item === item);

        if (error) {
          return {
            error: true,
            helperText: error.message,
          };
        }
      }
      return { helperText };
    },
    [errorItems],
  );

  const clearErrors = () => {
    setErrorItems(undefined);
  };

  return { handleErrorMessage, clearErrors, validateError, errorItems };
};

export default useFormValidation;
