import {
  IPost,
  IPut,
  IPatch,
  IPutResponse,
  IPatchResponse,
  IResponse,
  IResponseParam,
  SessionContextType,
  TDefaultResponse,
  TScore,
  TScoreEng,
  IDelete,
} from "src/types";

import usePut from "src/hooks/usePut";
import usePatch from "src/hooks/usePatch";
import usePost from "src/hooks/usePost";
import useFetch from "src/hooks/useFetch";
import useDelete from "src/hooks/useDelete";

interface IScoreRequest {
  score: TScore;
}

export const FetchScore = (
  token: string,
  refreshSession: SessionContextType["refreshSession"]
): IResponse<TScore> => {
  const { isLoading, refetch, error, data } = useFetch<TScore>({
    key: `fetch-score-${token}`,
    url: "/scoreConfiguration",
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, data };
};

export const FetchScoreEng = (
  token: string,
  refreshSession: SessionContextType["refreshSession"]
): IResponse<TScoreEng> => {
  const { isLoading, refetch, error, data } = useFetch<TScoreEng>({
    key: `fetch-score-configuration-eng-${token}`,
    url: "/scoreConfigurationEng",
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, data };
};

export const PostScore = (
  token: string,
  refreshSession: SessionContextType["refreshSession"]
): IPost<TDefaultResponse<IResponseParam<IScoreRequest>>, TScore> => {
  const { isLoading, refetch, isError, error, isSuccess } = usePost<
    TDefaultResponse<IResponseParam<IScoreRequest>>,
    TScore
  >({
    key: "create-score",
    url: "/scoreConfiguration",
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, isError, error, isSuccess };
};

export const PostScoreEng = (
  token: string,
  refreshSession: SessionContextType["refreshSession"]
): IPost<TDefaultResponse<IResponseParam<IScoreRequest>>, TScore> => {
  const { isLoading, refetch, isError, error, isSuccess } = usePost<
    TDefaultResponse<IResponseParam<IScoreRequest>>,
    TScore
  >({
    key: "create-score",
    url: "/scoreConfiguration",
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, isError, error, isSuccess };
};

export const PutScore = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  scoreId: number
): IPut<TDefaultResponse<IPutResponse>, TScore> => {
  const { isLoading, refetch, error, isError, isSuccess } = usePut<
    TDefaultResponse<IPutResponse>,
    TScore
  >({
    key: "put-score",
    url: `/scoreConfiguration/${scoreId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, isError, isSuccess };
};

export const PatchScore = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  scoreId: number
): IPatch<TDefaultResponse<IPatchResponse>, TScore> => {
  const { isLoading, refetch, error, isError, isSuccess } = usePatch<
    TDefaultResponse<IPatchResponse>,
    TScore
  >({
    key: "patch-score",
    url: `/scoreConfiguration/${scoreId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, isError, isSuccess };
};

export const PatchScoreEng = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  scoreId: number
): IPatch<TDefaultResponse<IPatchResponse>, TScore> => {
  const { isLoading, refetch, error, isError, isSuccess } = usePatch<
    TDefaultResponse<IPatchResponse>,
    TScore
  >({
    key: "patch-score",
    url: `/scoreConfigurationEng/${scoreId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, isError, isSuccess };
};

export const DeleteScore = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  scoreId: number
): IDelete<TDefaultResponse> => {
  const { isLoading, refetch, error, isError, isSuccess } =
    useDelete<TDefaultResponse>({
      key: "deleteScore",
      url: `/scoreConfiguration/${scoreId}`,
      headers: { Authorization: `Bearer ${token}` },
      onFailure: refreshSession,
    });

  return { isLoading, refetch, error, isError, isSuccess };
};
