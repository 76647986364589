export const EncodeQueryDataParams = (obj: object) => {
  const queryParams = new URLSearchParams();
  const keys = Object.keys(obj);
  const values = Object.values(obj);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = values[i];

    if (value !== undefined) {
      queryParams.set(key, value);
    }
  }

  return `?${queryParams.toString()}`;
};
