import React from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "src/hooks/useSession";

import {
  ContainerLogin,
  FormContainer,
  FormContent,
  InfoContent,
  LoginButton,
  Title,
  LogoContent,
  Description,
  Line,
  Paragraph,
  TitleInfo,
  ImageInfo,
  ForgotPassword,
  IconKey,
  FlexCenter,
  CancelEmail,
  IconCancel,
  CancelEmailContainer,
  ForgotPasswordContainer,
  IconMail,
  UserIcon,
  LockIcon,
  Subtitle,
  ForgotModalContainer,
} from "./styles";
import Input from "src/components/input";
import { useTheme } from "styled-components";
import { PutForgotPassword, PutPassword } from "src/services/users";
import ModalCustom from "src/components/modal";
import Button from "src/components/button";
import { NewPassword } from "src/utils/formValidation/schemas/newPassword";
import useFormValidation from "src/utils/formValidation/useFormValidation";
import { useSnackbar } from "src/hooks/useSnackbar";

import lwplogo from "src/assets/img/lwp.2471dd28.png";

type TFormType = "login" | "forgot";

const Login: React.FC = () => {
  const { openSnackBar } = useSnackbar();
  const { colors } = useTheme();
  const [user, setUser] = React.useState({
    login: "",
    password: "",
  });
  const [error, setError] = React.useState<string>("");

  const [formType, setFormType] = React.useState<TFormType>("login");
  const [email, setEmail] = React.useState("");
  const [modalForgot, setModalForgot] = React.useState({
    message: "",
    show: false,
  });
  const [modalNewPassword, setModalNewPassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState<NewPassword>({
    password: "",
    confirmPassword: "",
  });
  const [code, setCode] = React.useState("");
  const [userId, setUserId] = React.useState(0);

  const { validateError, handleErrorMessage } =
    useFormValidation<NewPassword>("newPassword");

  const { refetch: forgotPassword } = PutForgotPassword();
  const { refetch: updatePassword, isLoading: updatingPassword } =
    PutPassword(userId);

  const onChange = (key: string, value: string) => {
    setUser((prev) => ({ ...prev, [key]: value }));
  };

  const onChangeForgotMail = (value: string) => {
    setEmail(value);
  };

  const { login, isLoading } = useSession();
  const navigate = useNavigate();

  const onSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      try {
        const response = await login(user.login, user.password);
        if (response.isForgotPassword) {
          setUserId(response.user.id);
          setModalNewPassword(true);
        } else {
          navigate("/");
        }
      } catch (e: any) {
        if (e?.response?.status === 401) {
          setError("Usuário e/ou senha incorretos");
        } else if (e?.response?.status === 500) {
          setError(
            "Ocorreu um erro com o servidor.\nEntre em contato com o administrador."
          );
        } else if (e?.response?.status === 404) {
          setError("Usuário não encontrado.");
        } else {
          setError(
            "Ocorreu um erro desconhecido.\nEntre em contato com o administrador."
          );
        }
        throw new Error();
      }
    },
    [login, navigate, user.login, user.password]
  );

  const onForgotPassword = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      try {
        if (email.length > 0) {
          await forgotPassword({ email });
          setModalForgot({
            show: true,
            message: "Insira o código enviado para o e-mail fornecido",
          });
        }
      } catch {
        setModalForgot({
          show: true,
          message: "Erro ao realizar a requisição",
        });
      }
    },
    [email, forgotPassword]
  );

  const onChangeNewPassword = React.useCallback(
    (key: string, value: string) => {
      setNewPassword((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const onSubmitNewPassword = React.useCallback(async () => {
    const isValid = await validateError(newPassword);

    if (isValid) {
      try {
        await updatePassword(newPassword);
        setModalNewPassword(false);
        setFormType("login");
        setNewPassword({ confirmPassword: "", password: "" });
      } catch {
        openSnackBar("Erro ao atualizar a senha", "error");
      }
    }
  }, [newPassword, openSnackBar, updatePassword, validateError]);

  const onLoginCode = React.useCallback(async () => {
    try {
      setModalForgot({ show: false, message: "" });

      const response = await login(email, code);
      setUserId(response.user.id);
      setModalNewPassword(true);
      setCode("");
      setEmail("");
    } catch (e: any) {
      setCode("");

      if (e?.response?.status === 401) {
        openSnackBar("Código inválido", "error");
        return;
      }

      openSnackBar("Erro ao enviar o código", "error");
    }
  }, [code, email, login, openSnackBar]);

  const changeForm = () => {
    setEmail("");
    setUser({ login: "", password: "" });
    setFormType(formType === "login" ? "forgot" : "login");
  };

  return (
    <ContainerLogin>
      <FormContainer>
        <LogoContent src={lwplogo} />
        {formType === "login" ? (
          <FormContent onSubmit={onSubmit}>
            <Subtitle> Sistema de gerenciamento de registros</Subtitle>
            <Title>Login</Title>
            <Line />
            <Input
              label="Usuário"
              width="100%"
              onChange={(e) => {
                onChange("login", e);
              }}
              value={user.login}
              placeholder="digite seu login"
              icon={<UserIcon size={10} color={colors.textLight} />}
            />
            <Input
              label="Senha"
              width="100%"
              onChange={(e) => {
                onChange("password", e);
              }}
              value={user.password}
              icon={<LockIcon size={10} color={colors.textLight} />}
              placeholder="digite sua senha"
              type="password"
            />
            <Input type="checkbox" width="100%" label="Me manter conectado" />
            <LoginButton type="submit" disabled={isLoading}>
              Entrar
            </LoginButton>
            <FlexCenter>
              <ForgotPasswordContainer onClick={changeForm}>
                <ForgotPassword>Esqueceu sua senha?</ForgotPassword>
                <IconKey />
              </ForgotPasswordContainer>
            </FlexCenter>
            <p>{error}</p>
          </FormContent>
        ) : (
          <FormContent onSubmit={onForgotPassword}>
            <Subtitle> Sistema de gerenciamento de registros</Subtitle>
            <Title>Recuperar senha</Title>
            <Line />
            <Description>
              Insira seu e-mail para receber o link de alteração de senha.
            </Description>
            <Input
              label="E-mail"
              width="100%"
              onChange={(e) => {
                onChangeForgotMail(e);
              }}
              value={email}
              placeholder="digite seu e-mail"
              icon={<IconMail size={10} color={colors.textLight} />}
            />
            <LoginButton type="submit" disabled={email.length === 0}>
              Enviar
            </LoginButton>
            <FlexCenter>
              <CancelEmailContainer onClick={changeForm}>
                <CancelEmail> Cancelar </CancelEmail>
                <IconCancel />
              </CancelEmailContainer>
            </FlexCenter>
          </FormContent>
        )}
      </FormContainer>
      <InfoContent>
        <Paragraph> Bem-vindo ao</Paragraph>
        <TitleInfo />
        <Paragraph> Sistema de gerenciamento de registros</Paragraph>
        <ImageInfo> </ImageInfo>
      </InfoContent>
      <ModalCustom show={modalNewPassword} width="350px" height="220px">
        <ForgotModalContainer>
          <Input
            label="Nova senha"
            placeholder="Nova senha"
            value={newPassword.password}
            type="password"
            onChange={(e) => onChangeNewPassword("password", e)}
            {...handleErrorMessage("password")}
          />
          <Input
            label="Confirmar nova senha"
            placeholder="Confirmar nova senha"
            value={newPassword.confirmPassword}
            type="password"
            onChange={(e) => onChangeNewPassword("confirmPassword", e)}
            {...handleErrorMessage("confirmPassword")}
          />
          <Button
            label="Confirmar"
            onClick={onSubmitNewPassword}
            isLoading={updatingPassword}
          />
        </ForgotModalContainer>
      </ModalCustom>
      <ModalCustom show={modalForgot.show} width="350px" height="220px">
        <ForgotModalContainer>
          <Subtitle>{modalForgot.message}</Subtitle>
          <Input
            label="Insira o código"
            placeholder="Insira o código"
            value={code}
            type="text"
            onChange={(e) => setCode(e)}
          />
          <Button label="OK" disabled={code.length < 5} onClick={onLoginCode} />
        </ForgotModalContainer>
      </ModalCustom>
    </ContainerLogin>
  );
};

export default Login;
