import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SessionProvider } from "src/hooks/useSession";
import { SnackbarProvider } from "src/hooks/useSnackbar";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../styles/global";
import theme from "../styles/themes";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

type Props = {
  children: React.ReactNode;
};

const AppProvider = ({ children }: Props): JSX.Element => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <SessionProvider>
            <SnackbarProvider>
              <GlobalStyle />
              {children}
            </SnackbarProvider>
          </SessionProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default AppProvider;
