import React, { useEffect, useState, useCallback } from "react";
import { useSnackbar } from "src/hooks/useSnackbar";
import { AiOutlineSearch } from "react-icons/ai";
import Checkbox from "src/components/checkbox";
import Input from "src/components/input";
import Modal from "src/components/modal";
import OrderSelect from "src/components/order-select";
import AgroupForm from "src/components/agroup-form";
import RegisterCreate from "src/components/register-create";
import RegisterTable from "src/components/register-table";
import Select, { ISelectOption } from "src/components/select";
import { useSession } from "src/hooks/useSession";
import { FetchQuizzes } from "src/services/quiz";
import { IFiltersRegisterRequest, IQuizItem, StatusQuiz } from "src/types";
import { FormatBR } from "src/utils/DateFormat";
import {
  ContainerRegisters,
  CreateButton,
  CreateButtonContainer,
  CreateButtonIcon,
  FilterItem,
  FilterLabel,
  FiltersContainer,
  FlexSpaceBetween,
  Subtitle,
  TableContainer,
  Title,
  TitleContainer,
  ListIcon,
  ButtonsContainer,
  GroupButtonIcon,
} from "./styles";

const status: StatusQuiz[] = [
  "active",
  "errorNotSentLink",
  "errorNotSentResult",
  "pending",
  "finished",
  "expired",
  "disable",
  "finishedEng",
  "generatingEng",
];

const dictionaryStatus = {
  active: "Ativo",
  errorNotSentLink: "Erro ao enviar link",
  errorNotSentResult: "Erro ao enviar resultado",
  pending: "Pendente",
  finished: "Finalizado",
  expired: "Expirado",
  disable: "Desabilitado",
  generating: "Gerando",
  finishedEng: "Finalizado",
  generatingEng: "Gerando",
};

const Registers: React.FC = () => {
  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
  const { openSnackBar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [openG, setOpenG] = React.useState(false);
  const { refreshSession, session } = useSession();
  const [quizzes, setQuizzes] = React.useState<IQuizItem[]>([]);
  const [viewQuiz, setViewQuiz] = React.useState<IQuizItem | undefined>(
    undefined
  );
  const [options, setOptions] = React.useState<ISelectOption[]>([
    { label: "Todos", value: undefined },
  ]);
  const [loadingPdf, setLoadingPdf] = React.useState(false);
  // const [filename, setFilename] = React.useState("");

  const [filters, setFilters] = React.useState<IFiltersRegisterRequest>({
    order: "desc",
    date: undefined,
    orderField: undefined,
    search: undefined,
    status: undefined,
  });

  const selectRow = (quizId: number, checked: boolean) => {
    const count = selectedRows.filter((e) => e === quizId).length;
    if (checked && count === 0) {
      setSelectedRows((prev) => [...prev, quizId]);
    } else if (!checked && count > 0) {
      setSelectedRows((prev) => prev.filter((e) => e !== quizId));
    }
  };

  const {
    data,
    isLoading,
    refetch: fetchQuizzes,
  } = FetchQuizzes(session.token, refreshSession, filters);

  const selectAll = (checked: boolean) => {
    if (checked) {
      setSelectedRows(quizzes.map((x) => x.id));
    } else {
      setSelectedRows([]);
    }
  };

  const viewDetails = (quiz: IQuizItem) => {
    setViewQuiz(quiz);
    setOpen(true);
  };

  const getQuizzes = useCallback(async () => {
    if (fetchQuizzes) {
      const dataResponse = await fetchQuizzes();
      const quizzesResponse = dataResponse.data?.data?.data.quizzes;

      if (quizzesResponse) {
        setQuizzes(quizzesResponse);
      }
    }
  }, [fetchQuizzes]);

  useEffect(() => {
    getQuizzes();
  }, [filters, getQuizzes]);

  useEffect(() => {
    const interval = setInterval(() => {
      getQuizzes();
    }, 30000);

    return () => clearInterval(interval);
  }, [getQuizzes]);

  React.useEffect(() => {
    getQuizzes();
  }, [filters, getQuizzes]);

  React.useEffect(() => {
    const optionsValue: ISelectOption[] = [
      { label: "Todos", value: undefined },
    ];

    status.forEach((option) => {
      optionsValue.push({
        label: dictionaryStatus[option],
        value: option.toString(),
      });
    });

    setOptions(optionsValue);
    if (data?.data?.data?.quizzes) {
      setQuizzes(data.data.data.quizzes);
    }
  }, [data]);

  const onClose = () => {
    setOpen(false);
    setViewQuiz(undefined);
    getQuizzes();
  };

  const onCloseG = () => {
    setOpenG(false);
  };

  const onChangeFilter = (key: string, value?: string) => [
    setFilters((prev) => ({ ...prev, [key]: value })),
  ];

  const onChangeOrder = (order: "asc" | "desc", value?: string) => {
    setFilters((prev) => ({ ...prev, order, orderField: value }));
  };

  return (
    <ContainerRegisters>
      <FlexSpaceBetween>
        <TitleContainer>
          <Title>Registros</Title>
          <Subtitle>
            Atualizado {FormatBR(new Date().toISOString(), true)}
          </Subtitle>
        </TitleContainer>
        <CreateButtonContainer>
          <CreateButton onClick={() => setOpen(true)}>
            Novo registro
          </CreateButton>
          <CreateButtonIcon />
        </CreateButtonContainer>
      </FlexSpaceBetween>
      <TableContainer>
        <FiltersContainer>
          <FilterItem>
            <Checkbox onChange={selectAll} />
            <FilterLabel>{selectedRows.length} Selecionados</FilterLabel>
            <ListIcon />
          </FilterItem>
          <FilterItem>
            <OrderSelect
              label="Ordenação"
              options={[
                { label: "Nenhum", value: undefined },
                { label: "Id", value: "id" },
                { label: "Nome", value: "professionalName" },
                { label: "E-mail", value: "sendLinkEmail" },
              ]}
              defaultValue={filters.orderField}
              onChange={onChangeOrder}
            />
          </FilterItem>
          <FilterItem>
            <Select
              label="Filtro por status"
              options={options}
              defaultValue={filters.status}
              onChange={(e) => onChangeFilter("status", e)}
            />
          </FilterItem>
          <FilterItem>
            <Input
              icon={<AiOutlineSearch size={10} />}
              label="Pesquisar Registros"
              placeholder="Pesquisar registros"
              type="search"
              width="100%"
              onChange={(e) =>
                onChangeFilter("search", e.length === 0 ? undefined : e)
              }
            />
          </FilterItem>
          <FilterItem>
            <Input
              label="Filtro por data"
              placeholder="Filtro por data"
              width="180px"
              type="date"
              onChange={(e) =>
                onChangeFilter("date", e.length === 0 ? undefined : e)
              }
            />
          </FilterItem>
          <FilterItem>
            <ButtonsContainer>
              {selectedRows.length > 0 && (
                <CreateButtonContainer>
                  <CreateButton onClick={() => setOpenG(true)}>
                    Agrupar
                  </CreateButton>
                  <GroupButtonIcon />
                </CreateButtonContainer>
              )}
            </ButtonsContainer>
          </FilterItem>
        </FiltersContainer>
        <RegisterTable
          rows={quizzes}
          isLoading={isLoading}
          selectRow={selectRow}
          selectedRows={selectedRows}
          viewDetails={viewDetails}
          filters={filters}
        />
      </TableContainer>
      <Modal show={open} onClose={onClose} width="1100px" height="720px">
        <RegisterCreate onClose={onClose} quiz={viewQuiz} />
      </Modal>
      <Modal show={openG} onClose={onCloseG} width="800px" height="430px">
        <AgroupForm onClose={onCloseG} selectedRows={selectedRows} />
      </Modal>
    </ContainerRegisters>
  );
};

export default Registers;
