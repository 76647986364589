import { FaLink } from "react-icons/fa";
import styled from "styled-components";

export const Card = styled.div`
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(235, 175, 177);
`;

export const LinkIcon = styled(FaLink).attrs({
  size: 40,
})`
  color: ${({ theme }) => theme.colors.textError};
`;

export const Content = styled(Card)`
  flex-direction: column;
  align-items: flex-start;
`;

export const LinkDescription = styled.p`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.textError};
`;

export const ItemsError = styled.ul`
  margin-left: 20px;
`;

export const Error = styled.li`
  color: ${({ theme }) => theme.colors.textError};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;
