import styled from "styled-components";
import { TextArea as _textarea } from "src/styles";

interface ITextAreaContainer {
  width?: string;
}

export const TextAreaContainer = styled.div<ITextAreaContainer>`
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  width: ${({ width }) => width ?? "auto"};
  margin: 10px 0;
`;

export const TextAreaContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const TextAreaValue = styled(_textarea)`
  padding-left: 30px;
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.small};
  width: 100%;
  border-radius: 8px;
  outline: none;
  ::placeholder {
    color: ${({ theme }) => theme.colors.textLight};
    font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  }
  :focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  :hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.disabled};
    border: 1px solid ${({ theme }) => theme.colors.disabled};
  }
`;

export const TextAreaLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  padding-left: 5px;
  padding: 2px;
`;

export const TextAreaError = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  color: ${({ theme }) => theme.colors.textError};
`;