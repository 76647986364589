import { uniqueId } from "lodash";
import React from "react";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import styled, { useTheme } from "styled-components";
import { ISelectOption } from "../select";

const OrderColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.cardBackground};
  :focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  :hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.textLight};
    font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  }
`;

const IconContainer = styled.div`
  width: 15px;
  height: 15px;
  margin-left: 7px;
  margin-right: 7px;
  cursor: pointer;
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  padding-left: 5px;
  padding: 2px;
`;

const Select = styled.select`
  padding: 0;
  margin: 0;
  min-width: 100px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 6px;
  border-radius: 8px;
  outline: none;
  border: none;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
`;

const Option = styled.option`
  padding: 6px;
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
`;

interface Props {
  options: ISelectOption[];
  defaultValue?: string;
  ignoreValue?: string;
  label?: string;
  onChange: (order: "asc" | "desc", value?: string) => void;
}

const OrderSelect: React.FC<Props> = ({
  options,
  defaultValue,
  onChange,
  label,
  ignoreValue = "Nenhum",
}) => {
  const { colors } = useTheme();
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [value, setValue] = React.useState<string | undefined>(undefined);

  const onChangeValue = (val: string) => {
    setValue(val);

    onChange(order, val !== ignoreValue ? val : undefined);
  };

  const onChangeOrder = () => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrder(newOrder);

    onChange(newOrder, value !== ignoreValue ? value : undefined);
  };

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <OrderColumn>
      <Label>{label}</Label>
      <OrderContainer>
        <Select value={value} onChange={(e) => onChangeValue(e.target.value)}>
          {options.map((option) => (
            <Option key={uniqueId("option")} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        <IconContainer onClick={onChangeOrder}>
          {order === "asc" ? (
            <BiSortDown size={15} color={colors.primary} />
          ) : (
            <BiSortUp size={15} color={colors.primary} />
          )}
        </IconContainer>
      </OrderContainer>
    </OrderColumn>
  );
};

export default OrderSelect;
