import React from "react";
import Checkbox from "src/components/checkbox";
import Modal from "src/components/modal";
import UserForm from "src/components/user-form";
import UserTable from "src/components/user-table";
import { useSession } from "src/hooks/useSession";
import { FetchUser } from "src/services/users";
import { IUser } from "src/types";
import { FormatBR } from "src/utils/DateFormat";
import {
  ContainerRegisters,
  CreateButton,
  CreateButtonContainer,
  CreateButtonIcon,
  FilterItem,
  FilterLabel,
  FiltersContainer,
  FlexSpaceBetween,
  Subtitle,
  TableContainer,
  Title,
  TitleContainer,
  ListIcon,
} from "./styles";

const User: React.FC = () => {
  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
  const [open, setOpen] = React.useState(false);
  const { refreshSession, session } = useSession();
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [viewUser, setViewUser] = React.useState<IUser | undefined>(undefined);

  const selectRow = (userId: number, checked: boolean) => {
    const count = selectedRows.filter((e) => e === userId).length;
    if (checked && count === 0) {
      setSelectedRows((prev) => [...prev, userId]);
    } else if (!checked && count > 0) {
      setSelectedRows((prev) => prev.filter((e) => e !== userId));
    }
  };

  const { data, isLoading } = FetchUser(session.token, refreshSession);

  const selectAll = (checked: boolean) => {
    if (checked) {
      setSelectedRows(users.map((x) => x.id));
    } else {
      setSelectedRows([]);
    }
  };

  const viewDetails = (user: IUser) => {
    setViewUser(user);
    setOpen(true);
  };

  React.useEffect(() => {
    if (data?.data?.data?.users) {
      setUsers(data.data.data.users);
    }
  }, [data]);

  const onClose = () => {
    setOpen(false);
    setViewUser(undefined);
  };

  return (
    <ContainerRegisters>
      <FlexSpaceBetween>
        <TitleContainer>
          <Title>Usuários</Title>
          <Subtitle>Atualizado {FormatBR(new Date().toISOString(), true)}</Subtitle>
        </TitleContainer>
        {session.user.isAdmin && (
          <CreateButtonContainer>
            <CreateButton onClick={() => setOpen(true)}>Novo registro</CreateButton>
            <CreateButtonIcon />
          </CreateButtonContainer>
        )}
      </FlexSpaceBetween>
      <TableContainer>
        <FiltersContainer>
          <FilterItem>
            <div>
              <Checkbox onChange={selectAll} />
              <FilterLabel>{selectedRows.length} Selecionados</FilterLabel>
              <ListIcon />
            </div>
          </FilterItem>
        </FiltersContainer>
        <UserTable
          rows={users}
          isLoading={isLoading}
          selectRow={selectRow}
          selectedRows={selectedRows}
          viewDetails={viewDetails}
        />
      </TableContainer>
      <Modal show={open} onClose={onClose} width="800px" height="430px">
        <UserForm userEdit={viewUser} onClose={onClose} />
      </Modal>
    </ContainerRegisters>
  );
};

export default User;
