import React from "react";
import { IFiltersRegisterRequest, IQuizItem } from "src/types";
import { Box, Skeleton } from "@mui/material";
import {
  ActionsContainer,
  ArrowNext,
  ArrowPrevious,
  CheckboxContainer,
  ClientContainer,
  ClientMail,
  ClientName,
  DeleteButton,
  InfoDateContent,
  InfoLabel,
  Label,
  LabelButton,
  LabelDate,
  LinkButton,
  LinkIcon,
  PaginationAction,
  PaginationContainer,
  Row,
  RowCheck,
  StatusContainer,
  TableContainer,
  TableContent,
  TrashIcon,
} from "./styles";
import StatusQuizCard from "src/components/status-quiz-card";
import { FormatBR } from "src/utils/DateFormat";
import Checkbox from "src/components/checkbox";
import { useSnackbar } from "src/hooks/useSnackbar";
import { DisableQuiz } from "src/services/quiz";
import { useSession } from "src/hooks/useSession";
import ModalCustom from "../modal";
import Confirmation from "../confirmation";

interface Props {
  rows: IQuizItem[];
  isLoading: boolean;
  perPage?: number;
  totalPages?: number;
  totalRows?: number;
  page?: number;
  nextPage?: () => void;
  previousPage?: () => void;
  selectedRows: number[];
  selectRow: (quizId: number, checked: boolean) => void;
  viewDetails: (quiz: IQuizItem) => void;
  filters: IFiltersRegisterRequest;
}

const RegisterTable: React.FC<Props> = ({
  isLoading,
  rows,
  perPage = 10,
  totalPages,
  page = 1,
  totalRows = rows.length,
  nextPage,
  previousPage,
  selectedRows,
  selectRow,
  viewDetails,
  filters,
}) => {
  const { session, refreshSession } = useSession();
  const { openSnackBar } = useSnackbar();
  const [pagination, setPagination] = React.useState({
    totalRows,
    totalPages:
      totalPages ?? Math.abs(rows.length / perPage) < 1
        ? 1
        : Math.abs(rows.length / perPage),
    page,
    perPage,
  });

  const [data, setData] = React.useState<IQuizItem[]>([]);
  const [disableId, setDisableId] = React.useState(0);

  const { refetch: disableQuiz, isLoading: isLoadingDisable } = DisableQuiz(
    session.token,
    refreshSession,
    disableId,
  );

  React.useEffect(() => {
    const total = totalPages ?? Math.ceil(rows.length / perPage);
    const totalR = totalRows ?? rows.length;

    setPagination((prev) => ({ ...prev, totalPages: total, totalRows: totalR }));
  }, [perPage, rows.length, totalPages, totalRows]);

  React.useEffect(() => {
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, [filters]);

  React.useEffect(() => {
    let offset = pagination.page - 1;

    if (offset !== 0) {
      offset *= pagination.perPage;
    }

    setData(rows.slice(offset, offset + pagination.perPage));
  }, [pagination.page, pagination.perPage, rows]);

  const next = () => {
    if (nextPage) {
      nextPage();
      return;
    }

    setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const previous = () => {
    if (previousPage) {
      previousPage();
      return;
    }

    setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
  };

  const copyLink = (url: string) => {
    const domain = process.env.REACT_APP_URL_WEB;
    navigator.clipboard.writeText(`${domain}/quiz/${url}`);
    openSnackBar("Link copiado com sucesso", "info");
  };

  const onDisable = React.useCallback(async () => {
    setDisableId(0);
    try {
      await disableQuiz(disableId);
    } catch {
      openSnackBar("Erro ao desabilitar o quiz", "error");
    }
  }, [disableId, disableQuiz, openSnackBar]);

  const onConfirmDisable = (id: number) => {
    setDisableId(id);
  };

  return (
    <>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
        </Box>
      ) : (
        <TableContainer>
          <TableContent>
            {data.map((row) => (
              <Row key={row.id}>
                <RowCheck>
                  <CheckboxContainer>
                    <Checkbox
                      checked={selectedRows.includes(row.id)}
                      onChange={(checked) => {
                        selectRow(row.id, checked);
                      }}
                    />
                  </CheckboxContainer>
                </RowCheck>
                <Row
                  onClick={() => {
                    viewDetails(row);
                  }}
                >
                  <StatusContainer>
                    <StatusQuizCard status={row.status} />
                  </StatusContainer>
                  <InfoDateContent>
                    <InfoLabel>{row.id}</InfoLabel>
                    <InfoLabel>{FormatBR(row.createdAt, true)}</InfoLabel>
                  </InfoDateContent>
                  <ClientContainer>
                    <ClientName>{row.professionalName}</ClientName>
                    <ClientMail>{row.professionalEmail}</ClientMail>
                  </ClientContainer>
                </Row>
                <ActionsContainer>
                  {row.replyDate && (
                    <LabelDate>{FormatBR(row.replyDate.toString(), true)}</LabelDate>
                  )}
                  <LinkButton
                    onClick={() => {
                      copyLink(row.url);
                    }}
                  >
                    <LinkIcon />
                  </LinkButton>
                  <DeleteButton onClick={() => onConfirmDisable(row.id)}>
                    <TrashIcon />
                  </DeleteButton>
                </ActionsContainer>
              </Row>
            ))}
          </TableContent>
          <PaginationContainer>
            <Label>Total de páginas: {pagination.totalPages}</Label>
            <PaginationAction onClick={previous} disabled={pagination.page === 1}>
              <ArrowPrevious />
              <LabelButton>Anterior</LabelButton>
            </PaginationAction>
            <PaginationAction
              onClick={next}
              disabled={pagination.page === pagination.totalPages}
            >
              <LabelButton>Próximo</LabelButton>
              <ArrowNext />
            </PaginationAction>
            <Label>
              Total de registros: {data.length} de {pagination.totalRows}
            </Label>
          </PaginationContainer>
          <ModalCustom
            show={disableId > 0}
            onClose={() => setDisableId(0)}
            width="400px"
            height="200px"
          >
            <Confirmation
              message="Deseja desabilitar esse questionário"
              title="Desabilitar questionário?"
              onCancel={() => setDisableId(0)}
              onConfirm={onDisable}
              isLoading={isLoadingDisable}
            />
          </ModalCustom>
        </TableContainer>
      )}
    </>
  );
};

export default RegisterTable;
