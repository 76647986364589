import React from "react";

const useAppDimension = () => {
  const [width, setWidth] = React.useState<number>(window.innerWidth);

  const handleWindowSizeChange = React.useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  return { isMobile: width <= 641, isTablet: width > 641 && width < 961 };
};

export default useAppDimension;
