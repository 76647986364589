import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaUsers } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { MdOutlineViewList } from "react-icons/md";
import { FiLink } from "react-icons/fi";

export const IconHome = styled(AiFillHome)`
  position: absolute;
  left: 50px;
`;

export const IconList = styled(MdOutlineViewList)`
  position: absolute;
  left: 50px;
`;

export const IconUsers = styled(FaUsers)`
  position: absolute;
  left: 50px;
`;

export const IconEdit = styled(FaEdit)`
  position: absolute;
  left: 50px;
`;

export const IconLink = styled(FiLink)`
  position: absolute;
  left: 50px;
`;

export const ListMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

export const ItemMenu = styled.div`
  font-size: 10px;
  border-radius: 60px;
`;

interface IItemMenuContainer {
  $isCurrent: boolean;
}

export const ItemMenuContainer = styled(Link)<IItemMenuContainer>`
  background-color: ${(props) =>
    props.$isCurrent ? props.theme.colors.primary : props.theme.colors.text};
  color: ${(props) => props.theme.colors.white};
  min-height: 40px;
  padding: 10px 0;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 30px;
  border-radius: 8px;
  text-decoration: none;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryAlpha};
  }
`;

interface IItemMenuLabel {
  fontSize?: number;
}

export const ItemMenuLabel = styled.label<IItemMenuLabel>`
  font-size: ${({ fontSize }) => fontSize ?? "12px"};
  cursor: pointer;
  color: white;
`;

export const MenuSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #ffffff;
  margin: 8px 0;
`;
