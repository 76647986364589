import { IDelete, SessionContextType, THeaders } from "src/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "src/services/api";

interface ICommonDelete {
  key: string;
  headers?: { [key: string]: string };
  onFailure?: SessionContextType["refreshSession"];
}

type TDelete<T, F> = ICommonDelete &
  (
    | {
        url: string;
        customMutationFn?: never;
        onHeadersChanged?: never;
      }
    | {
        url?: never;
        customMutationFn(data: F): Promise<T>;
        onHeadersChanged(headers: THeaders): void;
      }
  );

export function deleteMutationFn<T>(url: string, headers?: THeaders): Promise<T> {
  return api.delete(url, { headers });
}

export default function useDelete<T, F = void>({
  key,
  url,
  headers: headersParam,
  onFailure,
  customMutationFn,
  onHeadersChanged,
}: TDelete<T, F>): IDelete<T, F> {
  const queryClient = useQueryClient();
  let headers = headersParam;
  const {
    isLoading,
    isError,
    error,
    isSuccess,
    mutateAsync: refetch,
  } = useMutation<T, Error, F>(
    [key],
    customMutationFn ?? (() => deleteMutationFn(url, headers)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      retry: (failureCount, { message }) => {
        if (message?.includes("401")) {
          if (failureCount === 0) {
            onFailure?.()?.then((token) => {
              if (token) {
                headers = {
                  ...headersParam,
                  Authorization: `Bearer ${token}`,
                };
                onHeadersChanged?.(headers);

                queryClient.invalidateQueries([key]);
              }
            });
          }

          return failureCount < 3;
        }

        return false;
      },
    },
  );

  return { isLoading, isError, error, isSuccess, refetch };
}
