import React from "react";
import styled from "styled-components";
import background from "src/assets/img/success-quiz.svg";

const Container = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: flex-start;
`;

const Content = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 48px;
  color: ${({ theme }) => theme.colors.primary};
  margin: 26px 0;
`;

const Text = styled.label`
  font-weight: 400;
  font-size: 14px;
  color: #000f08;
  margin: 26px 0;
`;

const Obs = styled.label`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #602a84;
`;

export const ImageInfo = styled.div`
  margin: 26px 0;

  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center;
  bottom: 0;
  background-size: contain;
  width: 100%;
  height: 430.72px;
`;
const SuccessQuiz: React.FC = () => {
  return (
    <Container>
      <Content>
        <Title>Avaliação enviada!</Title>
        <Text>Obrigado por finalizar a avaliação comportamental Niil Assessment.</Text>
        <Obs>
          O resultado será encaminhado para seu e-mail ou para o e-mail cadastrado na
          plataforma.
        </Obs>
        <ImageInfo />
      </Content>
    </Container>
  );
};

export default SuccessQuiz;
