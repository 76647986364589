import React from "react";
import { IAnswerPost, IQuestion } from "src/types";
import {
  QuestionContainer,
  Question,
  QuestionText,
  AnswerContainer,
  AnswerContent,
  AnswerOption,
  AnswerText,
  Row,
  IdLabel,
  Line,
} from "./styles";

interface IProps {
  questions: IQuestion[];
  onChangeAnswer: (quetionId: number, answerId: number) => void;
  answers: IAnswerPost[];
}

const Questions: React.FC<IProps> = ({ questions, onChangeAnswer, answers }) => {
  return (
    <QuestionContainer>
      {questions.length > 0 && (
        <>
          {questions.map((question) => (
            <Question key={question.text}>
              <Row>
                <IdLabel>{question.displayOrder}</IdLabel>
                <QuestionText>{question.text}</QuestionText>
              </Row>
              <AnswerContainer>
                {question.answers.map((answer) => (
                  <AnswerContent key={answer.text}>
                    <AnswerOption
                      onChange={(event) => {
                        if (event.target.value) {
                          onChangeAnswer(question.id, answer.id);
                        }
                      }}
                      value={answer.id}
                      name={question.text}
                      defaultChecked={
                        answers.findIndex(
                          (e) => e.questionId === question.id && e.answerId === answer.id,
                        ) >= 0
                      }
                    />
                    <AnswerText>{answer.text}</AnswerText>
                  </AnswerContent>
                ))}
              </AnswerContainer>
              <Line />
            </Question>
          ))}
        </>
      )}
    </QuestionContainer>
  );
};

export default Questions;
