import React from "react";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import { AlertColor } from "@mui/material/Alert";
import SnackbarCustom from "src/components/snackbar-custom";

type Snacktype = AlertColor;

type SnackContextType = {
  openSnackBar: (msg: string, t: Snacktype, pos?: SnackbarOrigin) => void;
};

type ProviderProps = {
  children: React.ReactNode;
};

export interface SnackProps {
  isActive: boolean;
  message: string;
  type: Snacktype | undefined;
  position: SnackbarOrigin;
}

const SnackContext = React.createContext({} as SnackContextType);

const SnackbarProvider = ({ children }: ProviderProps) => {
  const [props, setProps] = React.useState<SnackProps>({
    isActive: false,
    message: "",
    position: {
      horizontal: "right",
      vertical: "top",
    },
    type: undefined,
  });

  React.useEffect(() => {
    if (props.isActive === true) {
      setTimeout(() => {
        setProps((prev) => ({ ...prev, isActive: false }));
      }, 3000);
    }
  }, [props.isActive]);

  const openSnackBar = React.useCallback(
    (
      msg: string,
      t: Snacktype,
      pos: SnackbarOrigin = { horizontal: "right", vertical: "top" },
    ) => {
      setProps({
        isActive: true,
        message: msg,
        position: pos,
        type: t,
      });
    },
    [],
  );

  return (
    <SnackContext.Provider
      value={{
        openSnackBar,
      }}
    >
      {children}
      <SnackbarCustom {...props} />
    </SnackContext.Provider>
  );
};

const useSnackbar = (): SnackContextType => {
  const context = React.useContext(SnackContext);

  if (!context) {
    throw new Error("useSnackbar must be wrapped within a SnackbarContextType");
  }

  return context;
};

export { SnackbarProvider, useSnackbar };
