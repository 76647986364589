import styled from "styled-components";

export const MainContainer = styled.div`
  min-width: 100%;
  min-height: 100%;
`;

export const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const MainContent = styled.div`
  position: absolute;
  right: 0;
  width: calc(100% - 260px);
  height: calc(100%);
`;
