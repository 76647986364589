import React from "react";
import {
  InputContainer,
  InputContent,
  IconContainer,
  InputLabel,
  InputValue,
  CheckContainer,
  InputCheck,
  InputError,
  InputMaskCustom,
} from "./styles";

type Masks =
  | "cellphone"
  | "cellTelephone"
  | "cep"
  | "cnpj"
  | "color"
  | "cpf"
  | "cpfCnpj"
  | "creditCard"
  | "currency"
  | "date"
  | "isoDate"
  | "pis"
  | "telephone";

interface IProps {
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  icon?: JSX.Element;
  onChange?: (value: string) => void;
  width?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  value?: string | number;
  checked?: boolean;
  disabled?: boolean;
  mask?: Masks | undefined;
  max?: string;
  padding?: string;
}

const Input: React.FC<IProps> = ({
  icon,
  onChange: onChangeValue,
  placeholder,
  type = "text",
  width,
  label,
  error,
  helperText,
  value,
  checked = false,
  disabled = false,
  mask,
  max,
}) => {
  return (
    <InputContainer width={width}>
      {type !== "checkbox" ? (
        <>
          <InputLabel>{label}</InputLabel>
          <InputContent>
            <IconContainer>{icon}</IconContainer>
            {!mask ? (
              <InputValue
                type={type}
                placeholder={placeholder}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChangeValue?.(event.target.value);
                }}
                value={value}
                disabled={disabled}
                max={max}
              />
            ) : (
              <InputMaskCustom
                mask="cpf"
                placeholder={placeholder}
                value={value?.toString()}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChangeValue?.(event.target.value);
                }}
              />
            )}
          </InputContent>
        </>
      ) : (
        <CheckContainer>
          <InputCheck
            type={type}
            placeholder={placeholder}
            checked={checked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChangeValue?.(event.target.value);
            }}
          />
          <InputLabel>{label}</InputLabel>
        </CheckContainer>
      )}
      {error && <InputError>{helperText}</InputError>}
    </InputContainer>
  );
};

export default Input;
