import { BarDatum, BarItemProps } from "@nivo/bar";
import React from "react";
import { Graph, GraphContainer } from "./styles";

export interface GraphItem extends BarDatum {
  pos: number;
  day: number;
  value: number;
}

interface Props {
  data: GraphItem[];
  colors: string[];
}

const CustomBar = ({ bar }: BarItemProps<BarDatum>) => {
  return (
    <rect
      x={bar.x + 25 / 2 - 25 / 2 + 2}
      y={bar.y}
      width={15}
      height={bar.height}
      rx={Math.min(bar.width, bar.height) / 5}
      fill={bar.color}
    />
  );
};

const BarGraph: React.FC<Props> = ({ data, colors }) => {
  return (
    <GraphContainer>
      {data.length > 0 && (
        <Graph borderRadius={2} barComponent={CustomBar} colors={colors} data={data} />
      )}
    </GraphContainer>
  );
};

export default BarGraph;
