import styled from "styled-components";
import { ResponsiveBar } from "@nivo/bar";

export const Graph = styled(ResponsiveBar).attrs(({ theme }) => ({
  enableLabel: false,
  theme: {
    axis: {
      ticks: {
        text: {
          width: 200,
          fill: "#000000",
          fontSize: 10,
        },
        padding: 1,
      },
    },
  },
  keys: ["value"],
  indexBy: "day",
  padding: 0.1,
  valueScale: { type: "symlog" },
  margin: { top: 40, bottom: 40, left: 30, right: 30 },
  axisTop: null,
  axisRight: null,
  axisLeft: null,
  axisBottom: {
    tickSize: 0,
    tickPadding: 20,
    tickRotation: 0,
    legendPosition: "middle",
    legendOffset: 0,
  },
  labelTextColor: theme.colors.text,
  enableGridY: false,
  isInteractive: false,
  animate: true,
  motionStiffness: 90,
  motionDamping: 15,
}))``;

export const GraphContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  max-height: 210px;
  overflow: hidden;
`;
