import {
  IPost,
  IPut,
  IPutEmail,
  IPutForgotPassword,
  IPutPassword,
  IPutResponse,
  IResponse,
  IResponseParam,
  SessionContextType,
  TDefaultResponse,
  TUser,
  IUser,
  IDelete,
} from "src/types";

import usePut from "src/hooks/usePut";
import usePost from "src/hooks/usePost";
import useFetch from "src/hooks/useFetch";
import useDelete from "src/hooks/useDelete";

interface IUsers {
  data: {
    users: IUser[];
  };
}

interface IUserRequest {
  user: TUser;
}

export const PostUser = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
): IPost<TDefaultResponse<IResponseParam<IUserRequest>>, TUser> => {
  const { isLoading, refetch, isError, error, isSuccess } = usePost<
    TDefaultResponse<IResponseParam<IUserRequest>>,
    TUser
  >({
    key: "create-user",
    url: "/user",
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, isError, error, isSuccess };
};

export const FetchUser = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
): IResponse<IUsers> => {
  const { isLoading, refetch, error, data } = useFetch<IUsers>({
    key: `fetch-user-${token}`,
    url: "/user",
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, data };
};

export const PutUser = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  userId: number,
): IPut<TDefaultResponse<IPutResponse>, TUser> => {
  const { isLoading, refetch, error, isError, isSuccess } = usePut<
    TDefaultResponse<IPutResponse>,
    TUser
  >({
    key: "put-user",
    url: `/user/edit/${userId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, isError, isSuccess };
};

export const PutPassword = (
  userId: number,
): IPut<TDefaultResponse<IPutResponse>, IPutPassword> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePut<
    TDefaultResponse<IPutResponse>,
    IPutPassword
  >({
    key: "put-password",
    url: `/user/updatePassword/${userId}`,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const PutEmail = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
): IPut<TDefaultResponse<IPutResponse>, IPutEmail> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePut<
    TDefaultResponse<IPutResponse>,
    IPutEmail
  >({
    key: "put-email",
    url: "/user/updateEmail",
    headers: { "x-access-token": token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const PutForgotPassword = (): IPut<
  TDefaultResponse<IPutResponse>,
  IPutForgotPassword
> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePut<
    TDefaultResponse<IPutResponse>,
    IPutForgotPassword
  >({
    key: "forgotPassword",
    url: "/forgotPassword",
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const DeleteUser = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  userId: number,
): IDelete<TDefaultResponse> => {
  const { isLoading, refetch, error, isError, isSuccess } = useDelete<TDefaultResponse>({
    key: "deleteUser",
    url: `/user/${userId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, isError, isSuccess };
};
