import React, { useCallback, useState } from "react";
import Checkbox from "src/components/checkbox";
import Modal from "src/components/modal";
import ScoreForm from "src/components/score-form";
import ScoreTable from "src/components/score-table";
import { useSession } from "src/hooks/useSession";
import { FetchScore } from "src/services/score";
import { TScore, TScoreEng } from "src/types";
import { FormatBR } from "src/utils/DateFormat";
import {
  ContainerScores,
  FlexSpaceBetween,
  Subtitle,
  Title,
  TitleContainer,
  TableContainer,
  FiltersContainer,
  FilterItem,
  FilterLabel,
  ListIcon,
  ReportScoreContainer,
  CreateButton,
  CreateButtonContainer,
  CreateButtonIcon,
} from "./styles";

const Scores: React.FC = () => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const { refreshSession, session } = useSession();

  const [scores, setScores] = useState<TScore[]>([]);

  
  const [viewScore, setViewScore] = useState<TScore | undefined>(undefined);
  const [viewScoreEng, setViewScoreEng] = useState<TScoreEng | undefined>(undefined);

  const selectRow = useCallback(
    (scoreId: number, checked: boolean) => {
      const count = selectedRows.filter((e) => e === scoreId).length;
      if (checked && count === 0) {
        setSelectedRows((prev) => [...prev, scoreId]);
      } else if (!checked && count > 0) {
        setSelectedRows((prev) => prev.filter((e) => e !== scoreId));
      }
    },
    [selectedRows]
  );

  const { data, isLoading } = FetchScore(session.token, refreshSession);

  const selectAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedRows(scores.map((x) => x.id));
      } else {
        setSelectedRows([]);
      }
    },
    [scores]
  );

  const viewDetails = useCallback((score: TScore) => {
    setViewScore(score);
    setOpen(true);
  }, []);

  React.useEffect(() => {
    if (data && data.data && data.data.data && data.data.data.scores) {
      setScores(data.data.data.scores);
    }
  }, [data]);

  const onClose = useCallback(() => {
    setOpen(false);
    setViewScore(undefined);
    setViewScoreEng(undefined);
  }, []);

  return (
    <ContainerScores>
      <FlexSpaceBetween>
        <TitleContainer>
          <Title>Faixas de Escala</Title>
          <Subtitle>
            Atualizado {FormatBR(new Date().toISOString(), true)}
          </Subtitle>
        </TitleContainer>
        {session.user.isAdmin && (
          <ReportScoreContainer>
            <CreateButtonContainer>
              <CreateButton onClick={() => setOpen(true)}>
                Novo registro
              </CreateButton>
              <CreateButtonIcon />
            </CreateButtonContainer>
          </ReportScoreContainer>
        )}
      </FlexSpaceBetween>
      <TableContainer>
        <FiltersContainer>
          <FilterItem>
            <Checkbox onChange={selectAll} />
            <FilterLabel>{selectedRows.length} Selecionados</FilterLabel>
            <ListIcon />
          </FilterItem>
        </FiltersContainer>
        <ScoreTable
          rows={scores}
          isLoading={isLoading}
          selectRow={selectRow}
          selectedRows={selectedRows}
          viewDetails={viewDetails}
        />
      </TableContainer>
      <Modal show={open} onClose={onClose} width="1000px" height="600px">
        <ScoreForm scoreEdit={viewScore} onClose={onClose} />
      </Modal>
    </ContainerScores>
  );
};

export default Scores;
