import React from "react";
import axios from "axios";
import Input from "../input";
import TextArea from "../textarea";
import {
  Container,
  Form,
  Header,
  HeaderTitle,
  IconContainer,
  ListIcon,
  InfoRow,
  Row,
  CancelButton,
  SaveButton,
} from "./styles";
import "./style.css";
import { Score } from "src/utils/formValidation/schemas/score";
import useFormValidation from "src/utils/formValidation/useFormValidation";
import {
  PostScore,
  PatchScore,
  PostScoreEng,
  PatchScoreEng,
} from "src/services/score";
import { useSession } from "src/hooks/useSession";
import { useSnackbar } from "src/hooks/useSnackbar";
import { TScore, TScoreEng } from "src/types";
import { ScoreEdit } from "src/utils/formValidation/schemas/scoreEdit";

interface Props {
  onClose: () => void;
  scoreEdit?: TScore;
}

const ScoreForm: React.FC<Props> = ({ onClose, scoreEdit }) => {
  const { session, refreshSession, updateSession } = useSession();
  const { openSnackBar } = useSnackbar();

  const [defaultTab, setDefaultTab] = React.useState<boolean>(true);

  const [score, setScore] = React.useState<TScore>({
    id: 0,
    factor: "",
    subfactor: "",
    value: 0,
    veryHighScore: "",
    highScore: "",
    mediumScore: "",
    lowScore: "",
    veryLowScore: "",
  });

  const [scoreEng, setScoreEng] = React.useState<TScoreEng>({
    idEng: 0,
    factorEng: "",
    subfactorEng: "",
    valueEng: 0,
    veryHighScoreEng: "",
    highScoreEng: "",
    mediumScoreEng: "",
    lowScoreEng: "",
    veryLowScoreEng: "",
  });

  const get = async () => {
    if (!scoreEdit) {
      return;
    }

    const url = process.env.REACT_APP_API_URL + "/scoreConfigurationEng";
    const params = { id: scoreEdit?.id || null };
    const raw = await axios.get(url, { params });
    const data = raw.data.data[0];

    setScoreEng({
      idEng: data.id,
      factorEng: data.factor,
      subfactorEng: data.subfactor,
      valueEng: data.value,
      veryHighScoreEng: data.veryHighScore,
      highScoreEng: data.highScore,
      mediumScoreEng: data.mediumScore,
      lowScoreEng: data.lowScore,
      veryLowScoreEng: data.veryLowScore,
    });
  };

  const onChangeValue = React.useCallback(
    (key: string, value: string, eng: boolean = false) => {
      if (eng) {
        setScoreEng((prev: TScoreEng) => ({ ...prev, [key]: value }));
      } else {
        setScore((prev: TScore) => ({ ...prev, [key]: value }));
      }
    },
    []
  );

  const { refetch: postScore } = PostScore(session.token, refreshSession);
  const { refetch: postScoreEng } = PostScoreEng(session.token, refreshSession);

  const { refetch: patchScore } = PatchScore(
    session.token,
    refreshSession,
    scoreEdit?.id ?? 0
  );

  const { refetch: patchScoreEng } = PatchScoreEng(
    session.token,
    refreshSession,
    scoreEdit?.id ?? 0
  );

  const { validateError, handleErrorMessage } =
    useFormValidation<Score>("score");

  const {
    validateError: validateEditError,
    handleErrorMessage: handleErrorEditMessage,
  } = useFormValidation<ScoreEdit>("scoreEdit");

  const onSubmit = React.useCallback(async () => {
    try {
      await postScore(score);

      const adjustedScoreEng = {
        id: scoreEng.idEng,
        factor: scoreEng.factorEng,
        subfactor: scoreEng.subfactorEng,
        value: scoreEng.valueEng,
        veryHighScore: scoreEng.veryHighScoreEng,
        highScore: scoreEng.highScoreEng,
        mediumScore: scoreEng.mediumScoreEng,
        lowScore: scoreEng.lowScoreEng,
        veryLowScore: scoreEng.veryLowScoreEng,
      };

      await postScoreEng(adjustedScoreEng);

      openSnackBar("Score criado com sucesso", "success");
      onClose();
    } catch {
      openSnackBar("Erro ao criar score", "error");
    }
  }, [onClose, openSnackBar, postScore, score, validateError]);

  const onEdit = React.useCallback(async () => {
    try {
      await patchScore(score);

      const adjustedScoreEng = {
        id: scoreEng.idEng,
        factor: scoreEng.factorEng,
        subfactor: scoreEng.subfactorEng,
        value: scoreEng.valueEng,
        veryHighScore: scoreEng.veryHighScoreEng,
        highScore: scoreEng.highScoreEng,
        mediumScore: scoreEng.mediumScoreEng,
        lowScore: scoreEng.lowScoreEng,
        veryLowScore: scoreEng.veryLowScoreEng,
      };

      await patchScoreEng(adjustedScoreEng);

      if (scoreEdit?.id === session.user.id) {
        updateSession({
          refreshToken: session.refreshToken,
          token: session.token,
          user: { ...session.user, name: session.user.name },
        });
      }

      openSnackBar("Score editado com sucesso!", "success");
      onClose();
    } catch {
      openSnackBar("Erro ao editar o Score", "error");
      onClose();
    }
  }, [
    validateEditError,
    score,
    patchScore,
    scoreEdit?.id,
    session.user,
    session.refreshToken,
    session.token,
    openSnackBar,
    onClose,
    updateSession,
  ]);

  React.useEffect(() => {
    get();
  });

  React.useEffect(() => {
    get();
  }, [scoreEdit]);

  React.useEffect(() => {
    if (scoreEdit) {
      setScore({
        id: scoreEdit.id,
        factor: scoreEdit.factor,
        subfactor: scoreEdit.subfactor,
        value: scoreEdit.value,
        veryHighScore: scoreEdit.veryHighScore,
        highScore: scoreEdit.highScore,
        mediumScore: scoreEdit.mediumScore,
        lowScore: scoreEdit.lowScore,
        veryLowScore: scoreEdit.veryLowScore,
      });
    }
  }, [scoreEdit]);

  return (
    <Container>
      <Header>
        <IconContainer>
          <ListIcon size={16} />
        </IconContainer>
        <HeaderTitle>Informações da Faixa de Escala</HeaderTitle>
      </Header>
      <div className="tabs">
        <div
          className={defaultTab ? "tab-select tab" : "tab"}
          onClick={() => setDefaultTab(true)}
        >
          Portugues
        </div>
        <div
          className={!defaultTab ? "tab-select tab" : "tab"}
          onClick={() => setDefaultTab(false)}
        >
          Ingles
        </div>
      </div>

      <Form>
        <HeaderTitle>Dados Cadastrais</HeaderTitle>

        {defaultTab && (
          <div className="w-100">
            <Row>
              <Input
                onChange={(value) => {
                  onChangeValue("factor", value, false);
                }}
                label="Fator"
                value={score.factor}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("factor").error
                    : handleErrorMessage("factor").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("factor").helperText
                    : handleErrorMessage("factor").helperText
                }
                disabled
              />
              <Input
                onChange={(value) => {
                  onChangeValue("subfactor", value);
                }}
                label="Subfator"
                value={score.subfactor}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("subfactor").error
                    : handleErrorMessage("subfactor").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("subfactor").helperText
                    : handleErrorMessage("subfactor").helperText
                }
                disabled
              />
              <Input
                onChange={(value) => {
                  onChangeValue("value", value);
                }}
                label="Valor"
                value={score.value.toString()}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("value").error
                    : handleErrorMessage("value").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("value").helperText
                    : handleErrorMessage("value").helperText
                }
                disabled
              />
            </Row>
            <Row>
              <TextArea
                onChange={(value) => {
                  onChangeValue("veryHighScore", value);
                }}
                label="Altíssima intensidade"
                value={score.veryHighScore}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("veryHighScore").error
                    : handleErrorMessage("veryHighScore").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("veryHighScore").helperText
                    : handleErrorMessage("veryHighScore").helperText
                }
                width="100%"
                disabled={
                  scoreEdit &&
                  scoreEdit?.id !== session.user.id &&
                  !session.user.isAdmin
                }
                rows={5}
              />
            </Row>
            <Row>
              <TextArea
                onChange={(value) => {
                  onChangeValue("highScore", value);
                }}
                label="Alta intensidade"
                value={score.highScore}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("highScore").error
                    : handleErrorMessage("highScore").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("highScore").helperText
                    : handleErrorMessage("highScore").helperText
                }
                width="100%"
                disabled={
                  scoreEdit &&
                  scoreEdit?.id !== session.user.id &&
                  !session.user.isAdmin
                }
                rows={5}
              />
            </Row>
            <Row>
              <TextArea
                onChange={(value) => {
                  onChangeValue("mediumScore", value);
                }}
                label="Media intensidade"
                value={score.mediumScore}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("mediumScore").error
                    : handleErrorMessage("mediumScore").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("mediumScore").helperText
                    : handleErrorMessage("mediumScore").helperText
                }
                width="100%"
                disabled={
                  scoreEdit &&
                  scoreEdit?.id !== session.user.id &&
                  !session.user.isAdmin
                }
                rows={5}
              />
            </Row>
            <Row>
              <TextArea
                onChange={(value) => {
                  onChangeValue("lowScore", value);
                }}
                label="Baixa intensidade"
                value={score.lowScore}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("lowScore").error
                    : handleErrorMessage("lowScore").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("lowScore").helperText
                    : handleErrorMessage("lowScore").helperText
                }
                width="100%"
                disabled={
                  scoreEdit &&
                  scoreEdit?.id !== session.user.id &&
                  !session.user.isAdmin
                }
                rows={5}
              />
            </Row>
            <Row>
              <TextArea
                onChange={(value) => {
                  onChangeValue("veryLowScore", value);
                }}
                label="Baixíssima intensidade"
                value={score.veryLowScore}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("veryLowScore").error
                    : handleErrorMessage("veryLowScore").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("veryLowScore").helperText
                    : handleErrorMessage("veryLowScore").helperText
                }
                width="100%"
                disabled={
                  scoreEdit &&
                  scoreEdit?.id !== session.user.id &&
                  !session.user.isAdmin
                }
                rows={5}
              />
            </Row>
          </div>
        )}

        {!defaultTab && (
          <div className="w-100">
            <Row>
              <Input
                onChange={(value) => {
                  onChangeValue("factorEng", value, true);
                }}
                label="Factor"
                value={scoreEng.factorEng}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("factor").error
                    : handleErrorMessage("factor").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("factor").helperText
                    : handleErrorMessage("factor").helperText
                }
                disabled
              />
              <Input
                onChange={(value) => {
                  onChangeValue("subfactorEng", value, true);
                }}
                label="Subfactor"
                value={scoreEng.subfactorEng}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("subfactor").error
                    : handleErrorMessage("subfactor").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("subfactor").helperText
                    : handleErrorMessage("subfactor").helperText
                }
                disabled
              />
              <Input
                onChange={(value) => {
                  onChangeValue("valueEng", value, true);
                }}
                label="Value"
                value={scoreEng.valueEng.toString()}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("value").error
                    : handleErrorMessage("value").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("value").helperText
                    : handleErrorMessage("value").helperText
                }
                disabled
              />
            </Row>
            <Row>
              <TextArea
                onChange={(value) => {
                  onChangeValue("veryHighScoreEng", value, true);
                }}
                label="Very High Score"
                value={scoreEng.veryHighScoreEng}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("veryHighScore").error
                    : handleErrorMessage("veryHighScore").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("veryHighScore").helperText
                    : handleErrorMessage("veryHighScore").helperText
                }
                width="100%"
                disabled={
                  scoreEdit &&
                  scoreEdit?.id !== session.user.id &&
                  !session.user.isAdmin
                }
                rows={5}
              />
            </Row>
            <Row>
              <TextArea
                onChange={(value) => {
                  onChangeValue("highScoreEng", value, true);
                }}
                label="High Score"
                value={scoreEng.highScoreEng}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("highScore").error
                    : handleErrorMessage("highScore").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("highScore").helperText
                    : handleErrorMessage("highScore").helperText
                }
                width="100%"
                disabled={
                  scoreEdit &&
                  scoreEdit?.id !== session.user.id &&
                  !session.user.isAdmin
                }
                rows={5}
              />
            </Row>
            <Row>
              <TextArea
                onChange={(value) => {
                  onChangeValue("mediumScoreEng", value, true);
                }}
                label="Medium Score"
                value={scoreEng.mediumScoreEng}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("mediumScore").error
                    : handleErrorMessage("mediumScore").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("mediumScore").helperText
                    : handleErrorMessage("mediumScore").helperText
                }
                width="100%"
                disabled={
                  scoreEdit &&
                  scoreEdit?.id !== session.user.id &&
                  !session.user.isAdmin
                }
                rows={5}
              />
            </Row>
            <Row>
              <TextArea
                onChange={(value) => {
                  onChangeValue("lowScoreEng", value, true);
                }}
                label="Low Score"
                value={scoreEng.lowScoreEng}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("lowScore").error
                    : handleErrorMessage("lowScore").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("lowScore").helperText
                    : handleErrorMessage("lowScore").helperText
                }
                width="100%"
                disabled={
                  scoreEdit &&
                  scoreEdit?.id !== session.user.id &&
                  !session.user.isAdmin
                }
                rows={5}
              />
            </Row>
            <Row>
              <TextArea
                onChange={(value) => {
                  onChangeValue("veryLowScoreEng", value, true);
                }}
                label="Very Low Score"
                value={scoreEng.veryLowScoreEng}
                error={
                  scoreEdit
                    ? handleErrorEditMessage("veryLowScore").error
                    : handleErrorMessage("veryLowScore").error
                }
                helperText={
                  scoreEdit
                    ? handleErrorEditMessage("veryLowScore").helperText
                    : handleErrorMessage("veryLowScore").helperText
                }
                width="100%"
                disabled={
                  scoreEdit &&
                  scoreEdit?.id !== session.user.id &&
                  !session.user.isAdmin
                }
                rows={5}
              />
            </Row>
          </div>
        )}

        <InfoRow>
          <CancelButton onClick={onClose}>Cancelar</CancelButton>
          {scoreEdit &&
          scoreEdit?.id !== session.user.id &&
          !session.user.isAdmin ? (
            <SaveButton onClick={onClose}>Ok</SaveButton>
          ) : (
            <SaveButton onClick={scoreEdit ? onEdit : onSubmit}>
              Salvar
            </SaveButton>
          )}
        </InfoRow>
      </Form>
    </Container>
  );
};

export default ScoreForm;
