import React from "react";
import styled from "styled-components";

export const CheckboxStyle = styled.input.attrs({
  type: "checkbox",
})`
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 4px;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  :checked {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

interface Props {
  onChange: (checked: boolean) => void;
  checked?: boolean;
}

const Checkbox: React.FC<Props> = ({ onChange, checked }) => {
  return (
    <CheckboxStyle
      checked={checked}
      onChange={(e) => {
        onChange(e.target.checked);
      }}
    />
  );
};

export default Checkbox;
