import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { SnackProps } from "src/hooks/useSnackbar";

const SnackbarCustom: React.FC<SnackProps> = (props) => {
  const { isActive, message, type, position } = props;

  return (
    <>
      <Snackbar
        open={isActive}
        anchorOrigin={position}
        style={{ zIndex: "100000", whiteSpace: "pre-line" }}
      >
        <Alert severity={type}>{message}</Alert>
      </Snackbar>
    </>
  );
};

export default SnackbarCustom;
