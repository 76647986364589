import React from "react";
import {
  CheckboxContainer,
  CheckboxDescription,
  ErrorLabel,
  FormContent,
  FormDescription,
  FormDescriptionContainer,
  FormTitle,
} from "./styles";
import Input from "src/components/input";
import Select from "src/components/select";
import { ProfessionalInfo } from "src/utils/formValidation/schemas/professionalInfo";
import { MaxDateBirthday } from "src/utils/DateFormat";
import { Checkbox } from "@mui/material";

interface Props {
  professional: ProfessionalInfo;
  onChangeProfileInfo: (key: string, value: string | boolean) => void;
  handleErrorMessage: (
    item: keyof ProfessionalInfo,
    helperText?: string | undefined,
  ) =>
    | {
        error: boolean;
        helperText: string | undefined;
      }
    | {
        helperText: string | undefined;
        error?: undefined;
      };
}

const educationSituation = [
  { label: "Selecione", value: "" },
  { label: "Ensino Fundamental", value: "primary_school" },
  { label: "Ensino Médio", value: "secondary_school" },
  { label: "Graduação", value: "graduation" },
  { label: "Pós-Graduação", value: "post_graduation" },
];

const ProfileInfoForm: React.FC<Props> = ({
  professional,
  onChangeProfileInfo,
  handleErrorMessage,
}) => {
  const date = new Date();
  const maxDate = MaxDateBirthday(date);

  return (
    <FormContent>
      <FormTitle>Informações cadastrais</FormTitle>
      <FormDescription>
        Preencha todos os dados cadastrais para iniciar o questionário.
      </FormDescription>
      <Input
        onChange={(value) => {
          onChangeProfileInfo("name", value);
        }}
        width="100%"
        label="Nome"
        value={professional.name}
        placeholder="Insira seu nome"
        {...handleErrorMessage("name")}
      />
      <Input
        onChange={(value) => {
          onChangeProfileInfo("email", value);
        }}
        width="100%"
        label="E-mail"
        value={professional.email}
        placeholder="Insira seu e-mail"
        {...handleErrorMessage("email")}
        disabled
      />
      <Input
        onChange={(value) => {
          onChangeProfileInfo("cpf", value);
        }}
        width="100%"
        label="CPF"
        value={professional.cpf.length === 0 ? undefined : professional.cpf}
        placeholder="Insira seu CPF"
        mask="cpf"
        {...handleErrorMessage("cpf")}
      />
      <Input
        onChange={(value) => {
          onChangeProfileInfo("profession", value);
        }}
        width="100%"
        label="Profissão"
        value={professional.profession}
        placeholder="Insira sua profissão"
        {...handleErrorMessage("profession")}
      />
      <Input
        type="date"
        width="100%"
        label="Data de nascimento"
        value={professional.birthday}
        max={maxDate}
        onChange={(value) => {
          onChangeProfileInfo("birthday", value);
        }}
        {...handleErrorMessage("birthday")}
      />
      <Select
        onChange={(value) => {
          if (value) {
            onChangeProfileInfo("gender", value);
          }
        }}
        options={[
          { label: "Selecione", value: "" },
          { label: "Masculino", value: "male" },
          { label: "Feminino", value: "female" },
          { label: "Outro/Não quero responder", value: "other" },
        ]}
        width="100%"
        label="Genero"
        defaultValue={professional.gender}
        {...handleErrorMessage("gender")}
      />
      <Select
        onChange={(value) => {
          if (value) {
            onChangeProfileInfo("educationSituation", value);
          }
        }}
        options={educationSituation}
        width="100%"
        label="Escolaridade"
        defaultValue={professional.educationSituation}
        {...handleErrorMessage("educationSituation")}
      />
      <Select
        onChange={(value) => {
          if (value) {
            onChangeProfileInfo("educationLevel", value);
          }
        }}
        options={[
          { label: "Selecione", value: "" },
          { label: "Completo", value: "complete" },
          { label: "Incompleto", value: "incomplete" },
          { label: "Em andamento", value: "progress" },
        ]}
        width="100%"
        label="Situação"
        defaultValue={professional.educationLevel}
        {...handleErrorMessage("educationLevel")}
      />
      <FormDescriptionContainer>
        <FormDescription>
          Seus dados serão registrados apenas em nosso sistema.
        </FormDescription>
        <CheckboxContainer>
          <Checkbox
            onChange={(e, value) => onChangeProfileInfo("acceptedTerms", value)}
          />
          <CheckboxDescription>
            Aceito os{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={process.env.REACT_APP_API_URL?.concat("/terms-and-conditions")}
            >
              Termos e Condições
            </a>
          </CheckboxDescription>
        </CheckboxContainer>
        <ErrorLabel>{handleErrorMessage("acceptedTerms").helperText}</ErrorLabel>
        <CheckboxContainer>
          <Checkbox onChange={(e, value) => onChangeProfileInfo("dataStorage", value)} />
          <CheckboxDescription>
            Concordo com o{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={process.env.REACT_APP_API_URL?.concat("/data-storage")}
            >
              armazenameto das minhas informações
            </a>
          </CheckboxDescription>
        </CheckboxContainer>
        <ErrorLabel>{handleErrorMessage("dataStorage").helperText}</ErrorLabel>
      </FormDescriptionContainer>
    </FormContent>
  );
};

export default ProfileInfoForm;
