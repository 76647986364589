import * as Yup from "yup";

export type GroupEdit = {
  name: string;
};

const groupEdit = Yup.object().shape({
  name: Yup.string().required("O campo é obrigatório"),
});

export default groupEdit;
