import { Button, Container } from "src/styles";
import styled from "styled-components";

import logo from "src/assets/img/logo_min.svg";
import nillLogo from "src/assets/img/nill_logo.svg";

export const ContainerQuiz = styled(Container)`
  flex-direction: column;
  min-height: calc(100vh);
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  // border:1px solid red;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
`;

export interface IContent {
  isMobile: boolean;
}

export const Content = styled.div<IContent>`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
  padding: 40px 20px;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  // border:1px solid yellow;
`;

export const Header = styled.div`
  height: 90px;
  background-color: ${({ theme }) => theme.colors.text};
  width: 100%;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  // border:1px solid green;
`;


export const Footer = styled.div`
  height: 140px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  padding: 0 48px;
  display: flex;
  justify-content: center;
  @media (max-width: 670px) {
    padding:0x 12px;
  }
    // border:1px solid blue;
`;

export const EndContainer = styled.div`
  width: 100%;
  height: 100%;  
`;

export const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

export const ProgressInfo = styled.div`
  width:100%;
  height: 66px;
  border-radius: 100px;
  background-color: #e7ddff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 1000px) {
    width: 100%;
  }
  @media (max-width: 670px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.cardTitle};
  color: ${({ theme }) => theme.colors.text};
`;

export const TitleStep = styled(ModalTitle)``;



export const Logo = styled.div`
  width: 220px;
  height: 49.28px;
  margin-left: 200px;
  background-repeat: no-repeat;
  background-image: url(${logo});
  @media (max-width: 670px) {
    margin-left: 30px;
  }
`;

export const NillLogo = styled.div`
  width: 240px;
  height: 82.35px;
  background-repeat: no-repeat;
  background-image: url(${nillLogo});
  margin-bottom: 15px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 676px;
  height: 700.72px;
  padding: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  @media (max-width: 670px) {
    width: 100%;
    height: auto;
    padding: 8px 8px;
    font-size: 10px;
  }
`;

export const TitleInfo = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 22px;
  color: ${({ theme }) => theme.colors.text};
`;

export const ParagraphInfo = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 14px;
  margin-top: 25px;
`;

export const LabelInfo = styled.label`
  margin-top: 25px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primary};
`;



export const Line = styled.div`
  height: 1px;
  width: 90%;
  margin: 25px 0;
  background-color: #a288e3;
`;





export const ProgressTitle = styled.label`
  font-weight: 400;
  margin: 0px 12px 0px 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
  @media (max-width: 670px) {
    font-size: 10px;
  }
`;

export const Progress = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  border-radius: 8px;
  height: 14px;
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  @media (max-width: 670px) {
    width: 50%;
  }
`;

interface ICurrentProgress {
  progress: string;
}

export const CurrentProgress = styled.div<ICurrentProgress>`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 10px;
  border-radius: 8px;
  width: ${({ progress }) => progress};
`;

export const ProgressButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 8px 16px;
  margin: 0 8px;
  @media (max-width: 670px) {
    padding: 8px 8px;
    font-size: 10px;
  }
`;

export const PreviousButton = styled(ProgressButton)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
`;

export const ProfileInfoFormContainer = styled.div`
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  width: 628px;
  min-height: 670.72px;
  @media (max-width: 670px) {
    width: 100%;
    height: auto;
    padding: 8px 8px;
    font-size: 10px;
  }
`;

export const AlertContainer = styled.div`
  width: 300px;
  height: 200px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: space-around;
  padding: 15px;
`;

export const AlertRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const AlertTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.text};
  padding-left: 10px;
`;

export const AlertMessage = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.text};
`;

export const ProgressContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;

export const ProgressPercentage = styled.label`
  margin-right: 4px;
`;
