import React from "react";
import { IQuiz, IQuizItem } from "src/types";
import Input from "src/components/input";
import {
  ActionContainer,
  ContainerRegisterCreate,
  InfoRow,
  ActionButton,
  CloseButton,
  FormContent,
  Title,
  LinkContent,
  LinkText,
  CopyIcon,
  DateContent,
  Row,
  FormSection,
  Label,
  Subtitle,
  CheckBoxContainer,
  CheckBoxLabel,
  Line,
  CancelButton,
  SaveButton,
  FactorTitle,
  FactorLine,
  FactorValue,
  ResultContainer,
  FactorTitleBold,
  FactorContainer,
  FactorContent,
  FactorRow,
  DateTimeRow,
  HeaderActions,
  ButtonContainer,
  FinishedIcon,
  TimeLabel,
  TimeContainer,
  TimeTitle,
  SpinnerContainer,
  SpinnerTitle,
  ButtonDiv,
} from "./styles";
import Checkbox from "src/components/checkbox";
import useFormValidation from "src/utils/formValidation/useFormValidation";
import { Quiz, TypeReturn } from "src/utils/formValidation/schemas/quiz";
import { useSession } from "src/hooks/useSession";
import {
  FetchQuizById,
  FinalizeQuiz,
  GenerateQuiz,
  ResendMailLink,
  ResendMailResult,
} from "src/services/quiz";
import { useSnackbar } from "src/hooks/useSnackbar";
import { FormatBR, GetAge } from "src/utils/DateFormat";
import StatusQuizCard from "src/components/status-quiz-card";
import ModalCustom from "src/components/modal";
import Confirmation from "src/components/confirmation";
import Button from "src/components/button";
import { useTheme } from "styled-components";
import { FiSend } from "react-icons/fi";
import { FaFilePdf } from "react-icons/fa";
import { FetchFileStatus, GeneratePdf, GeneratePdfEng } from "src/services/pdf";
import Spinner from "../spinner";

interface Props {
  quiz?: IQuizItem;
  onClose: () => void;
}

function formatMinutes(min: number) {
  const hours = Math.floor(min / 60);
  const minutes = min % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}

const domain = process.env.REACT_APP_URL_WEB;

const RegisterCreate: React.FC<Props> = ({ quiz, onClose }) => {
  const { colors } = useTheme();
  const { session, refreshSession } = useSession();
  const [quizCreate, setQuizCreate] = React.useState<Quiz>({
    expirationDate: new Date().toISOString(),
    professionalName: "",
    sendLinkEmail: "",
    typeReturn: "noReturn",
    sendResultEmail: undefined,
  });

  const { openSnackBar } = useSnackbar();
  const { validateError, handleErrorMessage } = useFormValidation<Quiz>("quiz");
  const [data, setData] = React.useState<IQuiz | undefined>(undefined);
  const [id, setId] = React.useState(quiz?.id ?? 0);
  const [view, setView] = React.useState<"info" | "result">("info");
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [filename, setFilename] = React.useState("");
  const [loadingPdf, setLoadingPdf] = React.useState(false);

  const [date, setDate] = React.useState("");
  const [time, setTime] = React.useState("");

  const { refetch: fetchQuiz, isLoading } = FetchQuizById(
    session.token,
    refreshSession,
    id
  );
  const { refetch: generateQuiz } = GenerateQuiz(session.token, refreshSession);
  const { isLoading: isLoadingSendLink, refetch: resendLink } = ResendMailLink(
    session.token,
    refreshSession,
    id
  );
  const { isLoading: isLoadingSendResult, refetch: resendResult } =
    ResendMailResult(session.token, refreshSession, id);
  const { isLoading: isLoadingFinalize, refetch: finalizeQuiz } = FinalizeQuiz(
    session.token,
    refreshSession,
    id
  );
  const { refetch: generatePdf } = GeneratePdf(
    session.token,
    refreshSession,
    id
  );
  const { refetch: generatePdfEng } = GeneratePdfEng(
    session.token,
    refreshSession,
    id
  );

  const { refetch: statusFile } = FetchFileStatus(
    session.token,
    refreshSession,
    filename
  );

  const getQuiz = React.useCallback(async () => {
    if (quiz) {
      if (fetchQuiz) {
        const response = await fetchQuiz();
        const quizResponse = response.data?.data?.data;
        let typeReturn: TypeReturn;

        if (quizResponse?.sendResultEmail === quizResponse?.sendLinkEmail) {
          typeReturn = "professionalReturn";
        } else if (
          quizResponse?.sendResultEmail !== quizResponse?.sendLinkEmail &&
          quizResponse?.sendResultEmail !== null
        ) {
          typeReturn = "otherReturn";
        } else {
          typeReturn = "noReturn";
        }

        if (quizResponse) {
          setData(quizResponse);
          setQuizCreate({
            expirationDate: quizResponse.expirationDate,
            professionalName: quizResponse.professionalName,
            sendLinkEmail: quizResponse.sendLinkEmail,
            sendResultEmail: quizResponse.sendResultEmail ?? undefined,
            typeReturn,
          });
        }
      }
    }
  }, [fetchQuiz, quiz]);

  React.useEffect(() => {
    getQuiz();
  }, [getQuiz]);

  React.useEffect(() => {
    const formatedDate = `${date.split("T")[0]} ${time}`;
    setQuizCreate((prev) => ({ ...prev, expirationDate: formatedDate }));
  }, [date, time]);

  const copyLink = React.useCallback(async () => {
    openSnackBar("Link copiado com sucesso", "info");
    await navigator.clipboard.writeText(`${domain}/quiz/${data?.url}`);
  }, [data?.url, openSnackBar]);

  const onChangeValue = (key: string, value: string | Date) => {
    setQuizCreate((prev) => ({ ...prev, [key]: value }));
  };

  const onChangeView = React.useCallback((value: "info" | "result") => {
    setView(value);
  }, []);

  const onSubmit = async () => {
    const valid = await validateError(quizCreate);
    if (valid) {
      let sendResultEmail: string | undefined;

      switch (quizCreate.typeReturn) {
        case "professionalReturn":
          sendResultEmail = quizCreate.sendLinkEmail;
          break;
        case "otherReturn":
          sendResultEmail = quizCreate.sendResultEmail;
          break;
        case "noReturn":
          sendResultEmail = undefined;
          break;
        default:
          sendResultEmail = undefined;
      }

      try {
        const response = await generateQuiz({
          expirationDate: quizCreate.expirationDate,
          professionalName: quizCreate.professionalName,
          sendLinkEmail: quizCreate.sendLinkEmail,
          sendResultEmail,
        });

        setId(response.data.data.quiz.id);
        setData(response.data.data.quiz);
        openSnackBar("Questionário criado com sucesso", "success");
      } catch {
        openSnackBar("Ocorreu um erro ao criar o questionário", "error");
      }
    }
  };

  const onCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const onConfirmCompletation = React.useCallback(async () => {
    setOpenConfirmation(false);

    if (data) {
      try {
        await finalizeQuiz(data.id);
        openSnackBar("Questionário finalizado com sucesso", "success");
        getQuiz();
      } catch {
        openSnackBar("Erro ao finalizar questionário", "error");
      }
    }
  }, [data, finalizeQuiz, getQuiz, openSnackBar]);

  const onResendLink = async () => {
    try {
      await resendLink(id);
      openSnackBar("Link reenviando", "info");
    } catch {
      openSnackBar("Erro ao reenviar link", "error");
    }
  };

  const onResendResult = async () => {
    try {
      await resendResult(id);
      openSnackBar("Link reenviando", "info");
    } catch {
      openSnackBar("Erro ao reenviar link", "error");
    }
  };

  const formatTime = (date: Date) => {
    if (date.getHours() == 0 && date.getMinutes() == 0) {
      return "";
    }

    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);

    let formattedTime = hours + ":" + minutes;

    return formattedTime;
  };

  const getStatusFile = React.useCallback(async () => {
    try {
      if (!filename) {
        return;
      }

      const response = await statusFile?.();
      const file = response?.data?.data?.data.file;

      if (file !== undefined) {
        if (file.status === "loading") {
          setTimeout(async () => {
            await getStatusFile();
          }, 3000);
        } else if (file.status === "error") {
          throw new Error();
        } else {
          setLoadingPdf(false);
          setFilename("");
          window.open(
            `${process.env.REACT_APP_API_URL}/pdf/view/${filename}`,
            "_blank"
          );
        }
        return;
      }

      throw new Error();
    } catch (err) {
      console.log(err);
      setLoadingPdf(false);
      setFilename("");
      openSnackBar("Erro ao gerar o PDF", "error");
    }
  }, [filename, openSnackBar, statusFile]);

  React.useEffect(() => {
    if (filename.length > 0) {
      setTimeout(() => getStatusFile(), 500);
    }
  }, [filename, getStatusFile]);

  const onOpenPDF = React.useCallback(async () => {
    try {
      setLoadingPdf(true);
      const response = await generatePdf?.();
      const filenameResponse = response?.data?.data?.data.filename;

      if (filenameResponse) {
        setFilename(filenameResponse);
        getStatusFile();
        return;
      }

      throw new Error();
    } catch (err) {
      setLoadingPdf(false);
      setFilename("");
      openSnackBar("Gerando PDF", "success");
    }
  }, [generatePdf, openSnackBar]);

  const onOpenPdfEng = React.useCallback(async () => {
    try {
      setLoadingPdf(true);
      await generatePdfEng?.();
      // const filenameResponse = response?.data?.data?.data.filename;

      // if (filenameResponse) {
      //   setFilename(filenameResponse);
      //   getStatusFile();
      //   return;
      // }
      await getQuiz();
      setLoadingPdf(false);
      openSnackBar("Enviado para Fila de Processamento", "success");
      return;
    } catch (err) {
      setLoadingPdf(false);
      setFilename("");

      openSnackBar("Gerando PDF em Inglês", "success");
    }
  }, [openSnackBar]);

  return (
    <ContainerRegisterCreate>
      <InfoRow>
        <HeaderActions>
          {data && <StatusQuizCard status={data.status} />}
          <ButtonContainer>
            {data && data.status === "pending" && (
              <Button
                bgColor={colors.blue}
                bgHover={colors.blueLight}
                label="Finalizar"
                icon={<FinishedIcon size={15} />}
                onClick={() => setOpenConfirmation(true)}
              />
            )}

            {data && data.status === "errorNotSentLink" && (
              <Button
                bgColor={colors.textFooter}
                bgHover="#FCBA06"
                label="Reenviar"
                icon={<FiSend size={15} />}
                onClick={onResendLink}
                isLoading={isLoadingSendLink}
              />
            )}

            {data && data.status === "errorNotSentResult" && (
              <Button
                bgColor={colors.textFooter}
                bgHover="#FCBA06"
                label="Reenviar"
                icon={<FiSend size={15} />}
                onClick={onResendResult}
                isLoading={isLoadingSendResult}
              />
            )}
          </ButtonContainer>
        </HeaderActions>
        <HeaderActions>
          {data &&
            data?.questionAnswers &&
            data?.questionAnswers !== undefined &&
            data.questionAnswers.length > 0 && (
              <>
                <ButtonDiv>
                  <Button
                    bgColor={colors.primary}
                    label={
                      data.linkEng
                        ? "Download PDF em Inglês"
                        : "Gerar PDF em Inglês"
                    }
                    onClick={
                      data.linkEng
                        ? () => window.open(data.linkEng, "_blank")
                        : onOpenPdfEng
                    }
                    icon={<FaFilePdf size={15} />}
                  />
                </ButtonDiv>
                <Button
                  bgColor={colors.primary}
                  label={data.link ? "Download PDF" : "Gerar PDF"}
                  icon={<FaFilePdf size={15} />}
                  onClick={onOpenPDF}
                />
              </>
            )}
          <CloseButton onClick={onClose}>X</CloseButton>
        </HeaderActions>
      </InfoRow>
      <ActionContainer>
        <ActionButton
          isActive={view === "info"}
          onClick={() => onChangeView("info")}
        >
          Informação do profissional
        </ActionButton>
        <ActionButton
          isActive={view === "result"}
          onClick={() => onChangeView("result")}
          disabled={
            !data ||
            !data.questionAnswers ||
            data?.questionAnswers === undefined ||
            data.questionAnswers.length === 0
          }
        >
          Dados consolidados
        </ActionButton>
      </ActionContainer>
      {view === "info" && (
        <FormContent>
          <FormSection>
            <Title>Link do Formulário</Title>
            <Line />
            <Row>
              <LinkContent>
                {data?.url && (
                  <>
                    <LinkText>{`${domain}/quiz/${data.url}`}</LinkText>
                    <CopyIcon onClick={copyLink} />
                  </>
                )}
              </LinkContent>
              <DateContent>
                {data ? (
                  <DateTimeRow>
                    <Input
                      type="date"
                      onChange={(value) => {
                        setDate(value);
                      }}
                      value={new Date(
                        data.expirationDate.replace("Z", "")
                      ).toLocaleDateString("af-ZA")}
                      {...handleErrorMessage("expirationDate")}
                    />
                    <Input
                      type="time"
                      onChange={(value) => {
                        setTime(value);
                      }}
                      value={formatTime(new Date(data.expirationDate))}
                    />
                  </DateTimeRow>
                ) : (
                  <DateTimeRow>
                    <Input
                      type="date"
                      onChange={(value) => {
                        setDate(value);
                      }}
                      {...handleErrorMessage("expirationDate")}
                    />
                    <Input
                      type="time"
                      onChange={(value) => {
                        setTime(value);
                      }}
                    />
                  </DateTimeRow>
                )}
              </DateContent>
            </Row>
          </FormSection>
          <FormSection>
            <Title>Dados Cadastrais</Title>
            <Subtitle>Informações de cadastro do profissional</Subtitle>
            <Line />
            <Row>
              <Input
                placeholder="Nome"
                width="100%"
                label="Nome do profissional"
                onChange={(value) => {
                  onChangeValue("professionalName", value);
                }}
                value={data?.professionalName}
                {...handleErrorMessage("professionalName")}
              />
            </Row>
            <Row>
              <Input
                placeholder="E-mail"
                width="100%"
                label="E-mail"
                onChange={(value) => {
                  onChangeValue("sendLinkEmail", value);
                }}
                value={data?.sendLinkEmail}
                {...handleErrorMessage("sendLinkEmail")}
              />
            </Row>
          </FormSection>
          <FormSection>
            <Label>Informações preenchidas após o envio do formulário</Label>
            <Row>
              <Input
                width="33%"
                label="CPF"
                value={data?.professionalInfo?.cpf}
                disabled={quiz === undefined}
              />
              <Input
                width="33%"
                label="Idade"
                value={
                  data?.professionalInfo?.birthday
                    ? GetAge(data.professionalInfo.birthday.replace("Z", ""))
                    : undefined
                }
                disabled={quiz === undefined}
              />
              <Input
                width="33%"
                label="Data de Nascimento"
                value={
                  data?.professionalInfo?.birthday
                    ? FormatBR(data.professionalInfo.birthday.replace("Z", ""))
                    : undefined
                }
                disabled={quiz === undefined}
              />
            </Row>
          </FormSection>
          <FormSection>
            <Title>Informações de envio de resultado</Title>
            <Subtitle>Selecione o método de envio do resultado</Subtitle>
            <Line />
            <Row>
              <CheckBoxContainer>
                <Checkbox
                  onChange={() => {
                    onChangeValue("sendResultEmail", "");
                    onChangeValue("typeReturn", "noReturn");
                  }}
                  checked={quizCreate.typeReturn === "noReturn"}
                />
                <CheckBoxLabel>
                  Manter resultado apenas no sistema
                </CheckBoxLabel>
              </CheckBoxContainer>
              <CheckBoxContainer>
                <Checkbox
                  onChange={() => {
                    onChangeValue("sendResultEmail", "");
                    onChangeValue("typeReturn", "professionalReturn");
                  }}
                  checked={quizCreate.typeReturn === "professionalReturn"}
                />
                <CheckBoxLabel>
                  Enviar resultado para o profissional
                </CheckBoxLabel>
              </CheckBoxContainer>
              <CheckBoxContainer>
                <Checkbox
                  onChange={() => {
                    onChangeValue("sendResultEmail", "");
                    onChangeValue("typeReturn", "otherReturn");
                  }}
                  checked={quizCreate.typeReturn === "otherReturn"}
                />
                <CheckBoxLabel>
                  Enviar resultado para e-mail alternativo
                </CheckBoxLabel>
              </CheckBoxContainer>
            </Row>
            <Row>
              <Input
                placeholder="E-mail Alternativo"
                width="100%"
                label="E-mail Alternativo"
                onChange={(value) => {
                  onChangeValue("sendResultEmail", value);
                }}
                value={quizCreate.sendResultEmail}
                {...handleErrorMessage("sendResultEmail")}
                disabled={quizCreate.typeReturn !== "otherReturn"}
              />
            </Row>
          </FormSection>
          <InfoRow>
            <CancelButton onClick={onClose}>Cancelar</CancelButton>
            <SaveButton onClick={onSubmit} disabled={data !== undefined}>
              Salvar
            </SaveButton>
          </InfoRow>
        </FormContent>
      )}

      {view === "result" && !isLoading && data?.questionAnswers && (
        <FormContent>
          <ResultContainer>
            {data && data.time && data.time > 0 ? (
              <TimeContainer>
                <TimeLabel>Tempo total</TimeLabel>
                <TimeTitle>{formatMinutes(data.time)}</TimeTitle>
              </TimeContainer>
            ) : (
              <TimeContainer>
                <TimeLabel>Tempo total</TimeLabel>
                <TimeTitle>00:01</TimeTitle>
              </TimeContainer>
            )}
            {data.questionAnswers.map((x) => (
              <Row key={x.factor}>
                <FactorTitle
                  style={{
                    width: "20%",
                  }}
                >
                  {x.factor}
                </FactorTitle>
                <FactorLine
                  style={{
                    width: "70%",
                  }}
                >
                  <FactorValue width={`${x.percentage}%`} />
                </FactorLine>
                <FactorTitleBold
                  style={{
                    width: "10%",
                    paddingLeft: "20px",
                  }}
                >
                  {x.percentage.toFixed(2)} %
                </FactorTitleBold>
              </Row>
            ))}
            <FactorContainer>
              {data.questionAnswers.map((gp) => (
                <>
                  <FactorTitleBold>{gp.factor}</FactorTitleBold>
                  <FactorRow>
                    {gp.subfactor.map((sub) => (
                      <FactorContent>
                        <FactorTitle>{sub.name}</FactorTitle>
                        <FactorTitle>{sub.percentage} %</FactorTitle>
                      </FactorContent>
                    ))}
                  </FactorRow>
                </>
              ))}
            </FactorContainer>
          </ResultContainer>
        </FormContent>
      )}
      <ModalCustom
        show={openConfirmation}
        onClose={onCloseConfirmation}
        width="400px"
        height="200px"
      >
        <Confirmation
          onCancel={onCloseConfirmation}
          title="Confirmar ação?"
          message="Deseja confirmar a finalização do questionário?"
          onConfirm={onConfirmCompletation}
          isLoading={isLoadingFinalize}
        />
      </ModalCustom>
      <ModalCustom show={loadingPdf} width="400px" height="200px" zIndex={900}>
        <SpinnerContainer>
          <SpinnerTitle>Gerando relatório</SpinnerTitle>
          <Spinner color={colors.primary} />
        </SpinnerContainer>
      </ModalCustom>
    </ContainerRegisterCreate>
  );
};

export default RegisterCreate;
