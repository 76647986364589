import React from "react";
import Spinner from "../spinner";
import { ButtonContainer, ButtonIcon } from "./styles";

interface Props {
  onClick: () => void;
  label: string;
  icon?: JSX.Element;
  disabled?: boolean;
  isActive?: boolean;
  bgColor?: string;
  bgHover?: string;
  isLoading?: boolean;
}

const Button: React.FC<Props> = ({
  onClick,
  label,
  icon,
  disabled = false,
  isActive = false,
  bgColor,
  bgHover,
  isLoading = false,
}) => {
  return (
    <ButtonContainer
      onClick={onClick}
      disabled={disabled || isLoading}
      isActive={isActive}
      bgColor={bgColor}
      bgHover={bgHover}
    >
      {label}
      <ButtonIcon containIcon={icon !== undefined || isLoading === true}>
        {isLoading ? <Spinner size="10px" /> : <>{icon}</>}
      </ButtonIcon>
    </ButtonContainer>
  );
};

export default Button;
