import React from "react";
import styled from "styled-components";

interface Props {
  color?: string;
  size?: string;
}

const Spinner: React.FC<Props> = ({ color = "#ffffff", size = "15px" }) => (
  <StyledSpinner viewBox="0 0 50 50" size={size} color={color}>
    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
  </StyledSpinner>
);

interface Spinner {
  size: string;
  color: string;
}

const StyledSpinner = styled.svg<Spinner>`
  animation: rotate 2s linear infinite;
  margin: 0;
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  & .path {
    stroke: ${({ color }) => color};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default Spinner;
