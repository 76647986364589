import useFetch from "src/hooks/useFetch";
import { IFiles, IResponse, SessionContextType } from "src/types";

interface IStatusResponse {
  data: {
    file: IFiles;
  };
}

interface IGenerateResponse {
  data: {
    filename: string;
  };
}

export const FetchFileStatus = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  filename: string,
): IResponse<IStatusResponse> => {
  const { isLoading, refetch, error, data } = useFetch<IStatusResponse>({
    key: `file-status-${filename}`,
    url: `/pdf/status/${filename}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
    enabled: false,
  });

  return { isLoading, refetch, error, data };
};

export const GeneratePdf = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  quizId: number,
): IResponse<IGenerateResponse> => {
  const { isLoading, refetch, error, data } = useFetch<IGenerateResponse>({
    key: "generate-pdf",
    url: `/pdf/${quizId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
    enabled: false,
  });

  return { isLoading, refetch, error, data };
};

export const GenerateGroupPdf = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  quizId: number,
): IResponse<IGenerateResponse> => {
  const { isLoading, refetch, error, data } = useFetch<IGenerateResponse>({
    key: "generate-pdf-group",
    url: `/pdf/group/${quizId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
    enabled: false,
  });

  return { isLoading, refetch, error, data };
};

export const GeneratePdfEng = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  quizId: number,
): IResponse<IGenerateResponse> => {
  const { isLoading, refetch, error, data } = useFetch<IGenerateResponse>({
    key: "generate-pdf-eng",
    url: `/pdf/${quizId}/eng`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
    enabled: false,
  });

  return { isLoading, refetch, error, data };
};
