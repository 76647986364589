import * as Yup from "yup";

export type Score = {
  id: number;
  factor: string;
  subfactor: string;
  value: number;

  veryHighScore: string;
  highScore: string;
  mediumScore: string;
  lowScore: string;
  veryLowScore: string;
};

const score = Yup.object().shape({
  factor: Yup.string(),
  subfactor: Yup.string(),
  value: Yup.number(),

  veryHighScore: Yup.string().required("O campo é obrigatório"),
  highScore: Yup.string().required("O campo é obrigatório"),
  mediumScore: Yup.string().required("O campo é obrigatório"),
  lowScore: Yup.string().required("O campo é obrigatório"),
  veryLowScore: Yup.string().required("O campo é obrigatório"),
});

export type ScoreEng = {
  id: number;
  factor: string;
  subfactor: string;
  value: number;

  veryHighScore: string;
  highScore: string;
  mediumScore: string;
  lowScore: string;
  veryLowScore: string;
};

const scoreEng = Yup.object().shape({
  factor: Yup.string(),
  subfactor: Yup.string(),
  value: Yup.number(),

  veryHighScore: Yup.string().required("O campo é obrigatório"),
  highScore: Yup.string().required("O campo é obrigatório"),
  mediumScore: Yup.string().required("O campo é obrigatório"),
  lowScore: Yup.string().required("O campo é obrigatório"),
  veryLowScore: Yup.string().required("O campo é obrigatório"),
});

export default score; scoreEng;
