import { Skeleton, Box } from "@mui/material";
import React from "react";
import { IScore, TScore } from "src/types";
import Checkbox from "src/components/checkbox";
import {
  ActionsContainer,
  ArrowNext,
  ArrowPrevious,
  CheckboxContainer,
  ClientContainer,
  ClientName,
  DeleteButton,
  EditButton,
  Label,
  LabelButton,
  PaginationAction,
  PaginationContainer,
  Row,
  RowCheck,
  TableContainer,
  TableContent,
  TrashIcon,
  EditIcon,
} from "./styles";
import { useSession } from "src/hooks/useSession";
import { useSnackbar } from "src/hooks/useSnackbar";
import { DeleteScore } from "src/services/score";
import ModalCustom from "src/components/modal";
import Confirmation from "src/components/confirmation";
import { FaExclamationTriangle } from "react-icons/fa";

export interface IRowItem {
  id: number;
  factor: string;
  subfactor: string;
  value: number;

  veryHighScore: string;
  highScore: string;
  mediumScore: string;
  lowScore: string;
  veryLowScore: string;
}

interface Props {
  rows?: TScore[];
  isLoading: boolean;
  perPage?: number;
  totalPages?: number;
  totalRows?: number;
  page?: number;
  nextPage?: () => void;
  previousPage?: () => void;
  selectedRows: number[];
  selectRow: (groupId: number, checked: boolean) => void;
  viewDetails: (quiz: IRowItem) => void;
}

const ScoreTable: React.FC<Props> = ({
  isLoading,
  rows,
  perPage = 10,
  totalPages,
  page = 1,
  totalRows = rows ? rows.length : 0,
  nextPage,
  previousPage,
  selectedRows,
  selectRow,
  viewDetails,
}) => {
  const { session, refreshSession } = useSession();
  const { openSnackBar } = useSnackbar();
  const [pagination, setPagination] = React.useState(() => {
    const defaultTotalPages =
      rows && perPage ? Math.ceil(rows.length / perPage) : 1;

    return {
      totalRows,
      totalPages: totalPages ?? defaultTotalPages,
      page,
      perPage,
    };
  });

  const [scoreId, setScoreId] = React.useState(0);
  const [data, setData] = React.useState<TScore[]>([]);

  const { refetch: deleteScore } = DeleteScore(
    session.token,
    refreshSession,
    scoreId
  );

  const next = () => {
    if (nextPage) {
      nextPage();
      return;
    }

    setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const previous = () => {
    if (previousPage) {
      previousPage();
      return;
    }

    setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
  };

  const onConfirmDelete = React.useCallback(async () => {
    try {
      await deleteScore();
      openSnackBar("Score deletado com sucesso.", "success");
      setScoreId(0);
    } catch {
      openSnackBar("Erro ao deletar o score", "error");
    }
  }, [deleteScore, openSnackBar]);

  const onDeleteScore = React.useCallback((score: IScore) => {
    setScoreId(score.id);
  }, []);

  const formatString = (value: string) => {
    if (!value) return "";

    if (value.length <= 30) {
      return value;
    } else {
      return value.slice(0, 30) + "...";
    }
  };

  React.useEffect(() => {
    const total =
      rows && totalPages
        ? totalPages
        : Math.ceil((rows?.length ?? 0) / perPage);
    const totalR = totalRows ?? rows?.length ?? 0;

    setPagination((prev) => ({
      ...prev,
      totalPages: total,
      totalRows: totalR,
    }));
  }, [perPage, rows?.length, totalPages, totalRows]);

  React.useEffect(() => {
    let offset = pagination.page - 1;

    if (offset !== 0) {
      offset *= pagination.perPage;
    }

    setData(
      rows && pagination
        ? rows.slice(
            (pagination.page - 1) * pagination.perPage,
            pagination.page * pagination.perPage
          )
        : []
    );
  }, [pagination.page, pagination.perPage, rows]);

  return (
    <>
      <ModalCustom
        show={scoreId > 0}
        onClose={() => setScoreId(0)}
        width="400px"
        height="200px"
      >
        <Confirmation
          onConfirm={onConfirmDelete}
          onCancel={() => setScoreId(0)}
          message="Deseja confirmar a exclusão?"
          title="Atenção"
          icon={<FaExclamationTriangle size={30} />}
        />
      </ModalCustom>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
        </Box>
      ) : (
        <TableContainer>
          <TableContent>
            {data.map((row) => (
              <Row key={row.id}>
                <RowCheck>
                  <CheckboxContainer>
                    <Checkbox
                      checked={selectedRows.includes(row.id)}
                      onChange={(checked) => {
                        selectRow(row.id, checked);
                      }}
                    />
                  </CheckboxContainer>
                </RowCheck>
                <Row onClick={() => viewDetails(row)}>
                  <ClientContainer>
                    <ClientName>{row.factor}</ClientName>
                  </ClientContainer>
                  <ClientContainer>
                    <ClientName>{row.subfactor}</ClientName>
                  </ClientContainer>
                  <ClientContainer>
                    <ClientName>{row.value}</ClientName>
                  </ClientContainer>
                </Row>
                <ActionsContainer>
                  <DeleteButton
                    onClick={() => {
                      setScoreId(row.id);
                      onDeleteScore(row);
                    }}
                  >
                    <TrashIcon />
                  </DeleteButton>
                </ActionsContainer>
              </Row>
            ))}
          </TableContent>
          <PaginationContainer>
            <Label>Total de páginas: {pagination.totalPages}</Label>
            <PaginationAction
              onClick={previous}
              disabled={pagination.page === 1}
            >
              <ArrowPrevious />
              <LabelButton>Anterior</LabelButton>
            </PaginationAction>
            <PaginationAction
              onClick={next}
              disabled={pagination.page === pagination.totalPages}
            >
              <LabelButton>Próximo</LabelButton>
              <ArrowNext />
            </PaginationAction>
            <Label>
              Total de registros: {data.length} de {pagination.totalRows}
            </Label>
          </PaginationContainer>
        </TableContainer>
      )}
    </>
  );
};

export default ScoreTable;
