import React from "react";
import { StatusQuiz } from "src/types";
import {
  CardStatus,
  CardStatusTitle,
  ListIcon,
  ErrorIcon,
  ClockIcon,
  OkIcon,
  ExpiredIcon,
  DisableIcon,
} from "./styles";
import { useTheme } from "styled-components";

interface Props {
  status: StatusQuiz;
}

const StatusQuizCard: React.FC<Props> = ({ status }) => {
  const { colors } = useTheme();

  switch (status) {
    case "active":
      return (
        <CardStatus backgroundColor={colors.textLight}>
          <ListIcon size={11} color={colors.white} />
          <CardStatusTitle color={colors.white}>Ativo</CardStatusTitle>
        </CardStatus>
      );
    case "errorNotSentLink":
      return (
        <CardStatus backgroundColor={colors.mainBackground}>
          <ListIcon size={11} color={colors.textError} />
          <CardStatusTitle color={colors.textError}>Ativo</CardStatusTitle>
        </CardStatus>
      );
    case "errorNotSentResult":
      return (
        <CardStatus backgroundColor={colors.textLight}>
          <ErrorIcon size={11} color={colors.white} />
          <CardStatusTitle color={colors.white}>Resultado</CardStatusTitle>
        </CardStatus>
      );
    case "pending":
      return (
        <CardStatus backgroundColor={colors.textLight}>
          <ClockIcon size={11} color={colors.white} />
          <CardStatusTitle color={colors.white}>Pendente</CardStatusTitle>
        </CardStatus>
      );
    case "finished":
      return (
        <CardStatus backgroundColor={colors.blue}>
          <OkIcon size={11} color={colors.white} />
          <CardStatusTitle color={colors.white}>Concluído</CardStatusTitle>
        </CardStatus>
      );
    case "finishedEng":
      return (
        <CardStatus backgroundColor={colors.blue}>
          <OkIcon size={11} color={colors.white} />
          <CardStatusTitle color={colors.white}>Concluído</CardStatusTitle>
        </CardStatus>
      );
    case "expired":
      return (
        <CardStatus backgroundColor={colors.textFooter}>
          <ExpiredIcon size={11} color={colors.white} />
          <CardStatusTitle color={colors.white}>Expirado</CardStatusTitle>
        </CardStatus>
      );
    case "disable":
      return (
        <CardStatus backgroundColor={colors.textSecondary}>
          <DisableIcon size={11} color={colors.white} />
          <CardStatusTitle color={colors.white}>Desativado</CardStatusTitle>
        </CardStatus>
      );
    case "generating":
      return (
        <CardStatus backgroundColor='#ffcc00'>
          <ClockIcon size={11} color={colors.white} />
          <CardStatusTitle color={colors.white}>Gerando</CardStatusTitle>
        </CardStatus>
      );
    case "generatingEng":
      return (
        <CardStatus backgroundColor='#ffcc00'>
          <ClockIcon size={11} color={colors.white} />
          <CardStatusTitle color={colors.white}>Gerando</CardStatusTitle>
        </CardStatus>
      );
    default:
      return null;
  }
};

export default StatusQuizCard;
