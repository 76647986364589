import { Skeleton, Box } from "@mui/material";
import React from "react";
import { IUser } from "src/types";
import Checkbox from "src/components/checkbox";
import {
  ActionsContainer,
  ArrowNext,
  ArrowPrevious,
  CheckboxContainer,
  ClientContainer,
  ClientMail,
  ClientName,
  DeleteButton,
  Label,
  LabelButton,
  PaginationAction,
  PaginationContainer,
  Row,
  RowCheck,
  TableContainer,
  TableContent,
  TrashIcon,
} from "./styles";
import { useSession } from "src/hooks/useSession";
import { useSnackbar } from "src/hooks/useSnackbar";
import { DeleteUser } from "src/services/users";
import ModalCustom from "src/components/modal";
import Confirmation from "src/components/confirmation";
import { FaExclamationTriangle } from "react-icons/fa";

export interface IRowItem {
  id: number;
  name: string;
  email: string;
  isAdmin: boolean;
}

interface Props {
  rows: IUser[];
  isLoading: boolean;
  perPage?: number;
  totalPages?: number;
  totalRows?: number;
  page?: number;
  nextPage?: () => void;
  previousPage?: () => void;
  selectedRows: number[];
  selectRow: (userId: number, checked: boolean) => void;
  viewDetails: (quiz: IRowItem) => void;
}

const UserTable: React.FC<Props> = ({
  isLoading,
  rows,
  perPage = 10,
  totalPages,
  page = 1,
  totalRows = rows.length,
  nextPage,
  previousPage,
  selectedRows,
  selectRow,
  viewDetails,
}) => {
  const { session, refreshSession } = useSession();
  const { openSnackBar } = useSnackbar();
  const [pagination, setPagination] = React.useState({
    totalRows,
    totalPages:
      totalPages ?? Math.abs(rows.length / perPage) < 1
        ? 1
        : Math.abs(rows.length / perPage),
    page,
    perPage,
  });
  const [userId, setUserId] = React.useState(0);
  const [data, setData] = React.useState<IUser[]>([]);

  const { refetch: deleteUser } = DeleteUser(session.token, refreshSession, userId);

  const next = () => {
    if (nextPage) {
      nextPage();
      return;
    }

    setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const previous = () => {
    if (previousPage) {
      previousPage();
      return;
    }

    setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
  };

  const onConfirmDelete = React.useCallback(async () => {
    try {
      await deleteUser();
      openSnackBar("Usuário deletado com sucesso.", "success");
      setUserId(0);
    } catch {
      openSnackBar("Erro ao deletar o usuário", "error");
    }
  }, [deleteUser, openSnackBar]);

  const onDeleteUser = React.useCallback(
    (user: IUser) => {
      if (user.id === session.user.id) {
        openSnackBar("Não pode deletar o usuário logado", "error");
        return;
      }

      setUserId(user.id);
    },
    [openSnackBar, session.user.id],
  );

  React.useEffect(() => {
    const total = totalPages ?? Math.ceil(rows.length / perPage);
    const totalR = totalRows ?? rows.length;

    setPagination((prev) => ({ ...prev, totalPages: total, totalRows: totalR }));
  }, [perPage, rows.length, totalPages, totalRows]);

  React.useEffect(() => {
    let offset = pagination.page - 1;

    if (offset !== 0) {
      offset *= pagination.perPage;
    }

    setData(rows.slice(pagination.page - 1, pagination.page - 1 + pagination.perPage));
  }, [pagination.page, pagination.perPage, rows]);

  return (
    <>
      <ModalCustom
        show={userId > 0}
        onClose={() => setUserId(0)}
        width="400px"
        height="200px"
      >
        <Confirmation
          onConfirm={onConfirmDelete}
          onCancel={() => setUserId(0)}
          message="Deseja confirmar a exclusão?"
          title="Atenção"
          icon={<FaExclamationTriangle size={30} />}
        />
      </ModalCustom>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
          <Skeleton height="49px" />
        </Box>
      ) : (
        <TableContainer>
          <TableContent>
            {data.map((row) => (
              <Row key={row.email}>
                <RowCheck>
                  <CheckboxContainer>
                    <Checkbox
                      checked={selectedRows.includes(row.id)}
                      onChange={(checked) => {
                        selectRow(row.id, checked);
                      }}
                    />
                  </CheckboxContainer>
                </RowCheck>
                <Row onClick={() => viewDetails(row)}>
                  <ClientContainer>
                    <ClientName>{row.name}</ClientName>
                    <ClientMail>{row.email}</ClientMail>
                  </ClientContainer>
                </Row>
                {session.user.isAdmin && (
                  <ActionsContainer>
                    <DeleteButton
                      onClick={() => {
                        setUserId(row.id);
                        onDeleteUser(row);
                      }}
                    >
                      <TrashIcon />
                    </DeleteButton>
                  </ActionsContainer>
                )}
              </Row>
            ))}
          </TableContent>
          <PaginationContainer>
            <Label>Total de páginas: {pagination.totalPages}</Label>
            <PaginationAction onClick={previous} disabled={pagination.page === 1}>
              <ArrowPrevious />
              <LabelButton>Anterior</LabelButton>
            </PaginationAction>
            <PaginationAction
              onClick={next}
              disabled={pagination.page === pagination.totalPages}
            >
              <LabelButton>Próximo</LabelButton>
              <ArrowNext />
            </PaginationAction>
            <Label>
              Total de registros: {data.length} de {pagination.totalRows}
            </Label>
          </PaginationContainer>
        </TableContainer>
      )}
    </>
  );
};

export default UserTable;
