import styled from "styled-components";

interface ISelectContainer {
  width?: string;
}

export const SelectContainer = styled.div<ISelectContainer>`
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  width: ${({ width }) => width ?? "auto"};
  margin: 10px 0;
`;

export const SelectValue = styled.select`
  margin: 0;
  width: 100%;
  border: none;
  border-radius: 8px;
  outline: none;
  padding: 6px 30px;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid ${({ theme }) => theme.colors.cardBackground};

  :focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  :hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.textLight};
    font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  }
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const SelectOption = styled.option`
  padding: 0 30px;
`;

export const SelectContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const SelectLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  padding-left: 5px;
  padding: 2px;
`;

export const SelectError = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
  color: ${({ theme }) => theme.colors.textError};
`;

export const SelectIcon = styled.label`
  position: absolute;
  right: 6px;
  top: 20px;
`;
