import React from "react";
import Input from "../input";
import {
  Container,
  Form,
  Header,
  HeaderTitle,
  IconContainer,
  Label,
  UserIcon,
  InfoRow,
  CancelButton,
  SaveButton,
  SpinnerTitle,
  SpinnerContainer,
} from "./styles";
import { FaFilePdf } from "react-icons/fa";
import { useTheme } from "styled-components";
import ModalCustom from "src/components/modal";
import Spinner from "../spinner";
import Button from "src/components/button";
import { Group } from "src/utils/formValidation/schemas/group";
import useFormValidation from "src/utils/formValidation/useFormValidation";

import { PostGroup, PutGroup } from "src/services/groups";
import { useSession } from "src/hooks/useSession";
import { useSnackbar } from "src/hooks/useSnackbar";
import { IGroup } from "src/types";
import { GroupEdit } from "src/utils/formValidation/schemas/groupEdit";
import {
  FetchFileStatus,
  GenerateGroupPdf,
} from "src/services/pdf";
interface Props {
  onClose: () => void;
  groupEdit?: IGroup;
}

const GroupForm: React.FC<Props> = ({ onClose, groupEdit }) => {
  const { colors } = useTheme();
  const { session, refreshSession, updateSession } = useSession();
  const { openSnackBar } = useSnackbar();
  const [isLoading, setLoadingPdf] = React.useState(false);
  const [id, setId] = React.useState(groupEdit?.id ?? 0);
  const [filename, setFilename] = React.useState("");
  const [group, setGroup] = React.useState<Group>({
    name: "",
  });

  const { validateError, handleErrorMessage } =
    useFormValidation<Group>("group");

  const {
    validateError: validateEditError,
    handleErrorMessage: handleErrorEditMessage,
  } = useFormValidation<GroupEdit>("groupEdit");

  const onChangeValue = React.useCallback((key: string, value: string) => {
    setGroup((prev) => ({ ...prev, [key]: value }));
  }, []);

  const { refetch: postGroup } = PostGroup(session.token, refreshSession);

  const { refetch: putGroup } = PutGroup(
    session.token,
    refreshSession,
    groupEdit?.id ?? 0
  );

  const { refetch: generateGroupPdf } = GenerateGroupPdf(
    session.token,
    refreshSession,
    id
  );

  const { refetch: statusFile } = FetchFileStatus(
    session.token,
    refreshSession,
    filename
  );

  const onSubmit = React.useCallback(async () => {
    const isValid = await validateError(group);

    if (isValid) {
      try {
        await postGroup(group);
        openSnackBar("Grupo criado com sucesso", "success");
        onClose();
      } catch {
        openSnackBar("Erro ao criar grupo", "error");
      }
    }
  }, [onClose, openSnackBar, postGroup, group, validateError]);

  const onEdit = React.useCallback(async () => {
    const isValid = await validateEditError({
      name: group.name,
    });

    if (isValid) {
      try {
        await putGroup({
          name: group.name,
        });

        if (groupEdit?.id === session.user.id) {
          updateSession({
            refreshToken: session.refreshToken,
            token: session.token,
            user: { ...session.user, name: group.name },
          });
        }

        openSnackBar("Grupo editado com sucesso!", "success");
        onClose();
      } catch {
        openSnackBar("Erro ao editar o grupo", "error");
        onClose();
      }
    }
  }, [
    validateEditError,
    group,
    putGroup,
    groupEdit?.id,
    session.user,
    session.refreshToken,
    session.token,
    openSnackBar,
    onClose,
    updateSession,
  ]);

  React.useEffect(() => {
    if (groupEdit) {
      setGroup({
        name: groupEdit.name,
      });
    }
  }, [groupEdit]);

  const onOpenPDF = React.useCallback(async () => {
    try {
      setLoadingPdf(true);
      const response = await generateGroupPdf?.();

      const filenameResponse = response?.data?.data?.data.filename;

      if (filenameResponse) {
        
        window.open(
          `${process.env.REACT_APP_API_URL}/pdf/view/${filenameResponse}`,
          "_blank"
        );
        
        setLoadingPdf(false);
        setFilename("");
        openSnackBar("PDF do Grupo", "success");
        onClose();
        return;
      }

      setLoadingPdf(false);
      setFilename("");
      openSnackBar("Gerando PDF do Grupo", "success");
      onClose();      
    } catch (err) {
      setLoadingPdf(false);
      setFilename("");
      openSnackBar("Erro ao gerar o PDF", "error");
    }
  }, [onClose, openSnackBar, generateGroupPdf, updateSession]);

  const getStatusFile = React.useCallback(async () => {
    try {
      if (!filename) {
        return;
      }

      const response = await statusFile?.();
      const file = response?.data?.data?.data.file;

      if (file !== undefined) {
        if (file.status === "loading") {
          setTimeout(async () => {
            await getStatusFile();
          }, 3000);
        } else if (file.status === "error") {
          throw new Error();
        } else {
          setLoadingPdf(false);
          setFilename("");
          window.open(
            `${process.env.REACT_APP_API_URL}/pdf/view/${filename}`,
            "_blank"
          );
        }
        return;
      }

      throw new Error();
    } catch (err) {
      console.log(err);
      setLoadingPdf(false);
      setFilename("");
      openSnackBar("Erro ao gerar o PDF", "error");
    }
  }, [filename, openSnackBar, statusFile]);

  return (
    <Container>
      <Header>
        <HeaderTitle>
          <IconContainer>
            <UserIcon size={16} />
          </IconContainer>
          Informações do grupo
        </HeaderTitle>
        {groupEdit && (
          <Button
            bgColor={colors.primary}
            label={groupEdit.status == 'finished'? "Download PDF":"Gerar PDF"}
            icon={<FaFilePdf size={15} />}
            onClick={onOpenPDF}
          />
        )}
      </Header>
      <Form>
        <HeaderTitle>Dados Cadastrais</HeaderTitle>
        <Input
          onChange={(value) => {
            onChangeValue("name", value);
          }}
          placeholder="Nome"
          label="Nome"
          value={group.name}
          error={
            groupEdit
              ? handleErrorEditMessage("name").error
              : handleErrorMessage("name").error
          }
          helperText={
            groupEdit
              ? handleErrorEditMessage("name").helperText
              : handleErrorMessage("name").helperText
          }
          width="100%"
          disabled={
            groupEdit &&
            groupEdit?.id !== session.user.id &&
            !session.user.isAdmin
          }
        />
        <InfoRow>
          <CancelButton onClick={onClose}>Cancelar</CancelButton>
          {groupEdit &&
          groupEdit?.id !== session.user.id &&
          !session.user.isAdmin ? (
            <SaveButton onClick={onClose}>Ok</SaveButton>
          ) : (
            <SaveButton onClick={groupEdit ? onEdit : onSubmit}>
              Salvar
            </SaveButton>
          )}
        </InfoRow>
      </Form>
      <ModalCustom show={isLoading} width="400px" height="200px" zIndex={900}>
        <SpinnerContainer>
          <SpinnerTitle>Gerando relatório</SpinnerTitle>
          <Spinner color={colors.primary} />
        </SpinnerContainer>
      </ModalCustom>
    </Container>
  );
};

export default GroupForm;
