import React from "react";
import Input from "../input";
import {
  Container,
  Form,
  Header,
  HeaderTitle,
  IconContainer,
  Label,
  UserIcon,
  InfoRow,
  CancelButton,
  SaveButton,
} from "./styles";
import { User } from "src/utils/formValidation/schemas/user";
import useFormValidation from "src/utils/formValidation/useFormValidation";

import { PostUser, PutUser } from "src/services/users";
import { useSession } from "src/hooks/useSession";
import { useSnackbar } from "src/hooks/useSnackbar";
import { IUser } from "src/types";
import { UserEdit } from "src/utils/formValidation/schemas/userEdit";

interface Props {
  onClose: () => void;
  userEdit?: IUser;
}

const UserForm: React.FC<Props> = ({ onClose, userEdit }) => {
  const { session, refreshSession, updateSession } = useSession();
  const { openSnackBar } = useSnackbar();
  const [user, setUser] = React.useState<User>({
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
    isAdmin: false,
  });
  const { validateError, handleErrorMessage } = useFormValidation<User>("user");

  const { validateError: validateEditError, handleErrorMessage: handleErrorEditMessage } =
    useFormValidation<UserEdit>("userEdit");

  const onChangeValue = React.useCallback((key: string, value: string | boolean) => {
    setUser((prev) => ({ ...prev, [key]: value }));
  }, []);

  const { refetch: postUser } = PostUser(session.token, refreshSession);
  const { refetch: putUser } = PutUser(session.token, refreshSession, userEdit?.id ?? 0);

  const onSubmit = React.useCallback(async () => {
    const isValid = await validateError(user);

    if (isValid) {
      try {
        await postUser(user);
        openSnackBar("Usuário criado com sucesso", "success");
        onClose();
      } catch {
        openSnackBar("Erro ao criar usuário", "error");
      }
    }
  }, [onClose, openSnackBar, postUser, user, validateError]);

  const onEdit = React.useCallback(async () => {
    const isValid = await validateEditError({
      email: user.email,
      name: user.name,
      password: user.password,
      confirmPassword: user.confirmPassword,
    });

    if (isValid) {
      try {
        await putUser({
          email: user.email,
          name: user.name,
          password: user.password,
          isAdmin: user.isAdmin
        });

        if (userEdit?.id === session.user.id) {
          updateSession({
            refreshToken: session.refreshToken,
            token: session.token,
            user: { ...session.user, email: user.email, name: user.name },
          });
        }

        openSnackBar("Usuário editado com sucesso!", "success");
        onClose();
      } catch {
        openSnackBar("Erro ao editar o usuário", "error");
        onClose();
      }
    }
  }, [
    validateEditError,
    user,
    putUser,
    userEdit?.id,
    session.user,
    session.refreshToken,
    session.token,
    openSnackBar,
    onClose,
    updateSession,
  ]);

  React.useEffect(() => {
    if (userEdit) {
      setUser({
        email: userEdit.email,
        name: userEdit.name,
        password: "",
        confirmPassword: "",
        isAdmin: userEdit.isAdmin
      });
    }
  }, [userEdit]);

  return (
    <Container>
      <Header>
        <IconContainer>
          <UserIcon size={16} />
        </IconContainer>
        <HeaderTitle>Informações do usuário</HeaderTitle>
      </Header>
      <Form>
        <HeaderTitle>Dados Cadastrais</HeaderTitle>
        <Label>Informações de cadastro do profissional</Label>
        <Input
          onChange={(value) => {
            onChangeValue("name", value);
          }}
          placeholder="Nome"
          label="Nome"
          value={user.name}
          error={
            userEdit
              ? handleErrorEditMessage("name").error
              : handleErrorMessage("name").error
          }
          helperText={
            userEdit
              ? handleErrorEditMessage("name").helperText
              : handleErrorMessage("name").helperText
          }
          width="100%"
          disabled={userEdit && userEdit?.id !== session.user.id && !session.user.isAdmin}
        />
        <Input
          onChange={(value) => {
            onChangeValue("email", value);
          }}
          placeholder="E-mail"
          value={user.email}
          error={
            userEdit
              ? handleErrorEditMessage("email").error
              : handleErrorMessage("email").error
          }
          helperText={
            userEdit
              ? handleErrorEditMessage("email").helperText
              : handleErrorMessage("email").helperText
          }
          width="100%"
          disabled={userEdit && userEdit?.id !== session.user.id && !session.user.isAdmin}
        />
        <Input
          onChange={(value) => {
            onChangeValue("password", value);
          }}
          placeholder="Senha"
          label="Senha"
          type="password"
          value={user.password}
          error={
            userEdit
              ? handleErrorEditMessage("password").error
              : handleErrorMessage("password").error
          }
          helperText={
            userEdit
              ? handleErrorEditMessage("password").helperText
              : handleErrorMessage("password").helperText
          }
          width="100%"
          disabled={userEdit && userEdit?.id !== session.user.id && !session.user.isAdmin}
        />
        <Input
          onChange={(value) => {
            onChangeValue("confirmPassword", value);
          }}
          placeholder="Confirmar senha"
          label="Confirmar senha"
          type="password"
          value={user.confirmPassword}
          error={
            userEdit
              ? handleErrorEditMessage("confirmPassword").error
              : handleErrorMessage("confirmPassword").error
          }
          helperText={
            userEdit
              ? handleErrorEditMessage("confirmPassword").helperText
              : handleErrorMessage("confirmPassword").helperText
          }
          width="100%"
          disabled={userEdit && userEdit?.id !== session.user.id && !session.user.isAdmin}
        />
        <Input 
          type="checkbox" 
          checked={user.isAdmin}
          width="100%" 
          label="Administrador"
          onChange={() => {
            onChangeValue("isAdmin", !user.isAdmin);
          }}
          error={
            userEdit
              ? handleErrorEditMessage("isAdmin").error
              : handleErrorMessage("isAdmin").error
          }
          helperText={
            userEdit
              ? handleErrorEditMessage("isAdmin").helperText
              : handleErrorMessage("isAdmin").helperText
          }
          disabled={userEdit && userEdit?.id !== session.user.id && !session.user.isAdmin}
        />
        <InfoRow>
          <CancelButton onClick={onClose}>Cancelar</CancelButton>
          {userEdit && userEdit?.id !== session.user.id && !session.user.isAdmin ? (
            <SaveButton onClick={onClose}>Ok</SaveButton>
          ) : (
            <SaveButton onClick={userEdit ? onEdit : onSubmit}>Salvar</SaveButton>
          )}
        </InfoRow>
      </Form>
    </Container>
  );
};

export default UserForm;
