import React from "react";
import Select from "../select";
import {
  Container,
  Form,
  Header,
  HeaderTitle,
  IconContainer,
  UserIcon,
  InfoRow,
  CancelButton,
  SaveButton,
} from "./styles";
import { ISelectOption } from "../select";
import { PostUserGroup, FetchGroup } from "src/services/groups";
import { UserGroup } from "src/utils/formValidation/schemas/userGroup";
import { useSession } from "src/hooks/useSession";
import { useSnackbar } from "src/hooks/useSnackbar";
import useFormValidation from "src/utils/formValidation/useFormValidation";

interface Props {
  onClose: () => void;
  selectedRows: any[];
}

const AgroupForm: React.FC<Props> = ({ onClose, selectedRows }) => {
  const { session, refreshSession, updateSession } = useSession();
  const { openSnackBar } = useSnackbar();
  const [selectedValue, setSelectedValue] = React.useState("1")
  const [userGroup, setUserGroup] = React.useState<UserGroup>({
    id_group: selectedValue,
    id_quiz: selectedRows,
  });

  const initialOptions: ISelectOption[] = [
    { value: "1", label: "Opção 1" },
    { value: "2", label: "Opção 2" },
  ];

  const [groups, setGroups] = React.useState(initialOptions);
  
  const { validateError, handleErrorMessage } = useFormValidation<UserGroup>("userGroup");

  const { refetch: postUserGroup } = PostUserGroup(session.token, refreshSession);

  const { data, isLoading } = FetchGroup(session.token, refreshSession);

  const onSubmit = React.useCallback(async () => {
    const isValid = await validateError(userGroup);

    if (true) {
      try {
        await postUserGroup(userGroup);
        openSnackBar("Registros agrupados com sucesso", "success");
        onClose();
      } catch {
        openSnackBar("Erro ao agrupar registros", "error");
      }
    }
  }, [onClose, openSnackBar, postUserGroup, userGroup, validateError]);

  React.useEffect(() => {
    if (data?.data?.data?.groups) {
      let newArray = data?.data?.data?.groups.map(item => {
        return {
          label: item.name,
          value: item.id.toString()
        };
      });

      let newArray2: ISelectOption[] = newArray

      setGroups(newArray2)
    }
  }, [data]);

  const onChangeGroups = (value: any) => {
    setUserGroup({
      ...userGroup,
      id_group: value
    })
    setSelectedValue(value)
  };

  return (
    <Container>
      <Header>
        <IconContainer>
          <UserIcon size={16} />
        </IconContainer>
        <HeaderTitle>Agrupar Registros</HeaderTitle>
      </Header>
      <Form>
        <Select 
          width="100%"
          label="Grupo"
          options={groups}
          defaultValue={selectedValue}
          onChange={onChangeGroups}
        />
        <InfoRow>
          <CancelButton onClick={onClose}>Cancelar</CancelButton>
          {!session.user.isAdmin ? (
            <SaveButton onClick={onClose}>Ok</SaveButton>
          ) : (
            <SaveButton onClick={onSubmit}>Salvar</SaveButton>
          )}
        </InfoRow>
      </Form>
    </Container>
  );
};

export default AgroupForm;
