import React from "react";
import { useTheme } from "styled-components";
import Checkbox from "src/components/checkbox";
import axios from 'axios';
import ModalCustom from "src/components/modal";
import Spinner from "src/components/spinner";
import { useSnackbar } from "src/hooks/useSnackbar";
import Modal from "src/components/modal";
import GroupForm from "src/components/group-form";
import GroupTable from "src/components/group-table";
import { useSession } from "src/hooks/useSession";
import { FetchGroup } from "src/services/groups";
import { IGroup } from "src/types";
import { FormatBR } from "src/utils/DateFormat";
import {
  ContainerRegisters,
  CreateButton,
  CreateButtonContainer,
  CreateButtonIcon,
  FilterItem,
  FilterLabel,
  FiltersContainer,
  FlexSpaceBetween,
  Subtitle,
  TableContainer,
  Title,
  TitleContainer,
  ReportGroupContainer,
  ReportGroupIcon,
  SpinnerContainer,
  SpinnerTitle,
  ListIcon,
} from "./styles";

const Group: React.FC = () => {
  const { colors } = useTheme();
  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
  const [open, setOpen] = React.useState(false);
  const { openSnackBar } = useSnackbar();
  const { refreshSession, session } = useSession();
  const [groups, setGroups] = React.useState<IGroup[]>([]);
  const [viewGroup, setViewGroup] = React.useState<IGroup | undefined>(undefined);
  const [selectedGroup, setSelectedGroup] = React.useState<number>();
  
  const selectRow = (groupId: number, checked: boolean) => {
    setSelectedGroup(groupId);
    const count = selectedRows.filter((e) => e === groupId).length;
    if (checked && count === 0) {
      setSelectedRows((prev) => [...prev, groupId]);
    } else if (!checked && count > 0) {
      setSelectedRows((prev) => prev.filter((e) => e !== groupId));
    }
  };

  const { data, isLoading, refetch: fetchGroups,} = FetchGroup(session.token, refreshSession);
  
  const [loadingPdf, setLoadingPdf] = React.useState(false);

  const selectAll = (checked: boolean) => {
    if (checked) {
      setSelectedRows(groups.map((x) => x.id));
    } else {
      setSelectedRows([]);
    }
  };

  const viewDetails = (group: IGroup) => {
    setViewGroup(group);
    setOpen(true);
  };

  const getGroups = React.useCallback(async () => {
    if (fetchGroups) {
      const dataResponse = await fetchGroups();
      const groupsResponse = dataResponse.data?.data?.data.groups;

      if (groupsResponse) {
        setGroups(groupsResponse);
      }
    }
  }, [fetchGroups]);

  React.useEffect(() => {
    if (data?.data?.data?.groups) {
      setGroups(data.data.data.groups);
    }
  }, [data]);

  const onClose = () => {
    setOpen(false);
    getGroups();
    setViewGroup(undefined);
  };

  return (
    <ContainerRegisters>
      <FlexSpaceBetween>
        <TitleContainer>
          <Title>Grupos</Title>
          <Subtitle>Atualizado {FormatBR(new Date().toISOString(), true)}</Subtitle>
        </TitleContainer>
        {session.user.isAdmin && (
          <ReportGroupContainer>
            {/* <CreateButtonContainer>
              <CreateButton onClick={onOpenPDF}>Relatório por grupo</CreateButton>
              <ReportGroupIcon />
            </CreateButtonContainer> */}
            <CreateButtonContainer>
              <CreateButton onClick={() => setOpen(true)}>Novo registro</CreateButton>
              <CreateButtonIcon />
            </CreateButtonContainer>
          </ReportGroupContainer>
        )}
      </FlexSpaceBetween>
      <TableContainer>
        <FiltersContainer>
          <FilterItem>
            <Checkbox onChange={selectAll} />
            <FilterLabel>{selectedRows.length} Selecionados</FilterLabel>
            <ListIcon />
          </FilterItem>
        </FiltersContainer>
        <GroupTable
          rows={groups}
          isLoading={isLoading}
          selectRow={selectRow}
          selectedRows={selectedRows}
          viewDetails={viewDetails}
        />
      </TableContainer>
      <Modal show={open} onClose={onClose} width="800px" height="430px">
        <GroupForm groupEdit={viewGroup} onClose={onClose} />
      </Modal>
      <ModalCustom show={loadingPdf} width="400px" height="200px" zIndex={900}>
        <SpinnerContainer>
          <SpinnerTitle>Gerando relatório</SpinnerTitle>
          <Spinner color={colors.primary} />
        </SpinnerContainer>
      </ModalCustom>
    </ContainerRegisters>
  );
};

export default Group;
