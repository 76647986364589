import { DefaultTheme } from "styled-components";

export const lightTheme: DefaultTheme = {
  colors: {
    primaryAlpha: "rgba(96, 42, 132, 0.5)",
    primary: "#602a84",
    secondary: "#000f08",
    text: "#000f08",
    textSecondary: "#84828F",
    textLight: "#a288e3",
    textError: "#E50601",
    white: "#fff",
    background: "#f3eff",
    cardBackground: "#f3eeff",
    mainBackground: "#F6F7FA",
    border: "#e8e1f8",
    approve: "#04b30a",
    disabled: "#C4C4C4",
    textFooter: "#EC9F00",
    userCardBackground: "#1A1A1A",
    blue: "#20196F",
    blueLight: "#423EAF",
  },
  fontSizes: {
    extraSmall: "10px",
    small: "12px",
    medium: "16px",
    large: "24px",
    cardTitle: "20px",
    cardBody: "90px",
    profileTitle: "50px",
    ultraLarge: "48px",
  },
  fontWeights: {
    light: "300",
    medium: "400",
    normal: "500",
    bold: "700",
    extraBold: "bold",
  },
  spacings: {
    verySmall: "3px",
    small: "6px",
    medium: "12px",
    large: "24px",
  },
  cornerRadius: {
    small: "4px",
    medium: "8px",
    large: "16px",
  },
  iconSizes: {
    iconActiveSize: 26,
    iconUnactiveSize: 22,
  },
  screensWidth: {
    mobile: "576px",
  },
  barTheme: {
    background: "#ffffff",
    textColor: "#A8A8A8",
    fontSize: 13,
    axis: {
      domain: {
        line: {
          stroke: "#777777",
          strokeWidth: 0,
        },
      },
      ticks: {
        line: {
          stroke: "#777777",
          strokeWidth: 0,
        },
      },
    },
    grid: {
      line: {
        stroke: "#dddddd",
        strokeWidth: 0,
      },
    },
  },
};

export const darkTheme = lightTheme;

export default lightTheme;
