import * as Yup from "yup";

export type Group = {
  name: string;
};

const group = Yup.object().shape({
  name: Yup.string().required("O campo é obrigatório"),
});

export default group;
