import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { Button, Column } from "src/styles";
import styled from "styled-components";

export const TableContainer = styled(Column)`
  width: 100%;
  height: 100%;
`;

export const TableContent = styled(Column)`
  justify-content: flex-start;
  height: 90%;
  width: 100%;
`;

export const StatusContainer = styled.div`
  order: 1;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 49px;
  position: relative;
  padding: 0 8px;
  :hover {
    background-color: rgba(243, 238, 255, 0.6);
    cursor: pointer;
  }
`;

export const RowCheck = styled.div`
  display: flex;
  width: auto;
  height: 49px;
  position: relative;
  padding: 0 8px;
  align-items: center;
`;

export const CheckboxContainer = styled.div`
  margin: 0px 8px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding-top: 10px;
  margin-top: 10px;
`;

export const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 3px;
`;

export const PaginationAction = styled.button`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  :hover {
    background-color: ${({ theme }) => theme.colors.mainBackground};
  }
  :disabled {
    color: ${({ theme }) => theme.colors.disabled};
    cursor: default;
  }
`;

export const ArrowNext = styled(BsFillArrowRightCircleFill)``;

export const ArrowPrevious = styled(BsFillArrowLeftCircleFill)``;

export const LabelButton = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin: 0 3px;
`;

export const ClientContainer = styled(Column)`
  align-items: flex-start;
  padding: 0px 8px;
  gap: 4px;
  min-width: 395px;
  margin-left: 8px;
  order: 3;
`;

export const ClientName = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: 15px;
`;

export const ClientMail = styled.label`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.userCardBackground};
  font-size: ${({ theme }) => theme.fontSizes.extraSmall};
`;

export const ActionsContainer = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  align-items: center;
`;

export const EditButton = styled(Button)`
  width: 27px;
  height: 24px;
  padding: 6px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
  margin: 0 5px;
`;

export const DeleteButton = styled(EditButton)`
  color: ${({ theme }) => theme.colors.textSecondary};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 10;
  :hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.textError};
  }
`;

export const EditIcon = styled(FaEdit)`
  color: ${({ theme }) => theme.colors.white};
`;

export const TrashIcon = styled(FaTrashAlt)``;
