import {
  IPost,
  IPut,
  IPutResponse,
  IResponse,
  IResponseParam,
  SessionContextType,
  TDefaultResponse,
  TGroup,
  TUserGroup,
  IGroup,
  IDelete,
} from "src/types";

import usePut from "src/hooks/usePut";
import usePost from "src/hooks/usePost";
import useFetch from "src/hooks/useFetch";
import useDelete from "src/hooks/useDelete";

interface IGroups {
  data: {
    groups: IGroup[];
  };
}

interface IGroupRequest {
  group: TGroup;
}

interface IUserGroupRequest {
  groupId: number;
  userId: number[];
}

export const PostGroup = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
): IPost<TDefaultResponse<IResponseParam<IGroupRequest>>, TGroup> => {
  const { isLoading, refetch, isError, error, isSuccess } = usePost<
    TDefaultResponse<IResponseParam<IGroupRequest>>,
    TGroup
  >({
    key: "create-group",
    url: "/group",
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, isError, error, isSuccess };
};

export const PostUserGroup = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
): IPost<TDefaultResponse<IResponseParam<IUserGroupRequest>>, TUserGroup> => {
  const { isLoading, refetch, isError, error, isSuccess } = usePost<
    TDefaultResponse<IResponseParam<IUserGroupRequest>>,
    TUserGroup
  >({
    key: "create-userGroup",
    url: "/quizGroup",
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, isError, error, isSuccess };
}

export const FetchGroup = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
): IResponse<IGroups> => {
  const { isLoading, refetch, error, data } = useFetch<IGroups>({
    key: `fetch-group-${token}`,
    url: "/group",
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, data };
};

export const PutGroup = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  groupId: number,
): IPut<TDefaultResponse<IPutResponse>, TGroup> => {
  const { isLoading, refetch, error, isError, isSuccess } = usePut<
    TDefaultResponse<IPutResponse>,
    TGroup
  >({
    key: "put-group",
    url: `/group/edit/${groupId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, isError, isSuccess };
};

export const DeleteGroup = (
  token: string,
  refreshSession: SessionContextType["refreshSession"],
  groupId: number,
): IDelete<TDefaultResponse> => {
  const { isLoading, refetch, error, isError, isSuccess } = useDelete<TDefaultResponse>({
    key: "deleteGroup",
    url: `/group/${groupId}`,
    headers: { Authorization: `Bearer ${token}` },
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, isError, isSuccess };
};
