import styled from "styled-components";

export const QuestionText = styled.h2`
  font-weight: 400;
  font-size: 18px;
  color: #000f08;
`;

export const AnswerContainer = styled.ul``;

export const AnswerContent = styled.li`
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const AnswerOption = styled.input.attrs({
  type: "radio",
})`
  height: 20px;
  width: 20px;
  @media (max-width: 641px) {
    height: 25px;
    width: 25px;
  }
`;

export const AnswerText = styled.p`
  padding-left: 5px;
  font-weight: 400;
  font-size: 16px;
  color: #84828f;
`;

export const Question = styled.div`
  width: 100%;
`;

export const QuestionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
`;

export const IdLabel = styled.label`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 18px;
  padding-right: 16px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Line = styled.div`
  height: 1px;
  width: 80%;
  background-color: ${({ theme }) => theme.colors.primary};
  margin: 20px 0;
`;
