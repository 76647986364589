import styled from "styled-components";

interface IButtonContainer {
  disabled: boolean;
  isActive: boolean;
  bgColor?: string;
  bgHover?: string;
}

export const ButtonContainer = styled.button<IButtonContainer>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 27px;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.small};
  border-radius: 4px;
  border: none;
  padding: 6px 12px;
  background-color: ${({ theme, isActive, bgColor }) =>
    isActive ? theme.colors.primary : bgColor || theme.colors.text};
  :hover {
    background-color: ${({ theme, bgHover }) => bgHover || theme.colors.textLight};
  }
  :disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    cursor: default;
  }
  position: relative;
`;

interface IButtonIcon {
  containIcon: boolean;
}

export const ButtonIcon = styled.div<IButtonIcon>`
  height: 100%;
  padding-left: ${({ containIcon }) => (containIcon ? "6px" : "0px")};
`;
