import * as Yup from "yup";

export type UserGroup = {
  id_group: any;
  id_quiz: number[];
};

const userGroup = Yup.object().shape({
    id_group: Yup.number().required("O campo é obrigatório"),
    id_quiz: Yup.array().required("O campo é obrigatório"),
});

export default userGroup;
